import React from 'react';
import {Button, Form, Dropdown} from 'react-bootstrap';
import {selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';
import {errorMessage} from '../../Utils/ToastMessages';
import {
  formatNumberWithTwoDecimals,
  formatMinutesTime,
  checkSubscriptionType,
  getNameOfDuration,
  getNameFromDurationValue,
  formatPaymentDescription,
} from '../../Utils/commonUtils';
import {useState, useEffect, useRef} from 'react';
import {monthDays} from '../../Utils/commonUtils';

const InvoiceProduct = ({
  item,
  ItemIndex,
  setSelectedProduct,
  handleDataChange,
  selectedProduct,
  setDeleteCatalog,
  setShowComponent,
  showOutOfStockError,
  outOfStockItems
}) => {
  const [productData, setProductData] = useState({
    name: '',
    subscripton: '0.00',
    subtotal: '0.00',
    tax: '0.00',
    discount: '0.00',
    shipping: '0.00',
    total: '0.00',
    payment_Schedule: '',
  });

  const [total, setTotal] = useState('');

  const [tax, setTax] = useState(item.taxes_fee ? item.taxes_fee : '0.00');
  const [discount, setDiscount] = useState(
    item.discount ? item.discount : '0.00',
  );
  const [shipping, setShipping] = useState(
    item.shipping_handling ? item.shipping_handling : '0.00',
  );

  const [lateFee, setLateFee] = useState(
    item.late_fee ? item.late_fee : '0.00',
  );

  const [gracePeriod, setGracePeriod] = useState(
    item.grace_period ? item.grace_period : '',
  );

  const [itemCost, setItemCost] = useState(item.cost ? item.cost : '');

  const [levels, setLevels] = useState([]);

  const [durations, setDuartions] = useState([]);
  const [levelType, setLevelType] = useState('');
  const [durationType, setDurationType] = useState('');
  const [durationItem, setDurationItem] = useState({});
  const [levelItem, setLevelItem] = useState({});
  const [moreOptions, setMoreOptions] = useState(false);

  const [width, setWidth] = useState(34);
  const [shippingWidth, setShippingW] = useState(34);
  const [discountWidth, setDiscountW] = useState(34);
  const [lateFeeWidth, setLateFeeW] = useState(34);
  const taxRef = useRef(null);
  const discountRef = useRef(null);
  const shippingRef = useRef(null);
  const lateFeeRef = useRef(null);
  const [initialRender, setInitialRender] = useState(false);
  useEffect(() => {
    if (!initialRender) {
      if (taxRef.current) {
        setWidth(taxRef.current.offsetWidth + 2);
      }
      if (discountRef.current) {
        setDiscountW(discountRef.current.offsetWidth + 2);
      }
      if (shippingRef.current) {
        setShippingW(shippingRef.current.offsetWidth + 2);
      }
      if (lateFeeRef.current) {
        setLateFeeW(lateFeeRef.current.offsetWidth + 2);
      }
    }
  }, [tax, discount, shipping, lateFee, moreOptions]);

  useEffect(() => {
    if (item.catalog_type === 'membership') {
      console.log(
        item.membership_levels,
        'item.membership_levelsitem.membership_levels',
      );
      const filterLevel =
        item.membership_levels.length > 0 &&
        item.membership_levels.filter(item => item.selected === true);
      if (filterLevel.length > 0) {
        console.log(filterLevel, '=====>filterLevelfilterLevel');
        const selectedLevel = filterLevel.length > 0 ? filterLevel[0] : {};
        setLevelItem(selectedLevel);
        setLevels(
          item.membership_levels && item.membership_levels.length > 0
            ? item.membership_levels
            : [],
        );
        setLevelType(selectedLevel.id);
      } else {
        setLevelItem(
          item.membership_levels && item.membership_levels.length > 0
            ? item.membership_levels[0]
            : [],
        );
        setLevels(
          item.membership_levels && item.membership_levels.length > 0
            ? item.membership_levels
            : [],
        );
        setLevelType(
          item.membership_levels && item.membership_levels.length > 0
            ? item.membership_levels[0].id
            : '',
        );
      }
    }
  }, []);

  useEffect(() => {
    if (item.catalog_type === 'membership' && levelType !== '') {
      const selectedLevel = levels.filter(item => item.id === levelType);
      console.log(selectedLevel, 'selectedLevelselectedLevel');
      setDuartions(
        item.membership_levels.length > 0 &&
          selectedLevel[0].frequencies.length > 0
          ? selectedLevel[0].frequencies
          : [],
      );

      if (selectedLevel.length > 0 && selectedLevel[0].selected) {
        // setDurationType(item?.level?.frequency?.id)
        const filterFrequency =
          selectedLevel[0].frequencies.length > 0 &&
          selectedLevel[0].frequencies.filter(item => item.selected === true);
        setDurationType(filterFrequency.length > 0 && filterFrequency[0].id);
        handleAmount(filterFrequency[0]);
      } else {
        setDurationType(
          item.membership_levels.length > 0 &&
            selectedLevel[0].frequencies.length > 0
            ? selectedLevel[0].frequencies[0].id
            : '',
        );
        handleAmount(selectedLevel[0].frequencies[0]);
      }
    }
  }, [levelType]);

  const handleInputChange = e => {
    const {name, value} = e.target;

    if (parseFloat(value) > 1000000000000000) {
      return;
    }

    const regex = /^(?!0(?!\.))\d*(\.\d{0,2})?$/;
    // const regex = /^[1-9]\d*(\.\d{0,2})?$|^0\.\d{0,2}$/;
    // const regex = /^(?!0\d)\d*(\.\d{0,2})?$/;
    if (value === '' || regex.test(value) || value === '0') {
      if (name === 'discount') {
        const discountValue = parseFloat(value);
        const subtotalValue = parseFloat(item.subtotal);
        const taxValue = parseFloat(tax);
        const shippingValue = parseFloat(shipping);
        const productCost = parseFloat(itemCost);
        const lateFeeValue = parseFloat(lateFee);
        if (
          discountValue >=
          productCost + taxValue + shippingValue + lateFeeValue
        ) {
          errorMessage('Discount cannot exceed total.');
          return;
        }
      }

      const currentValue = parseFloat(value ? value : 0);
      const discountValue = parseFloat(discount);
      const taxValue = parseFloat(tax);
      const shippingValue = parseFloat(shipping);
      const productCost = parseFloat(itemCost);
      const lateFeeValue = parseFloat(lateFee);

      if (name === 'tax') {
        const total =
          currentValue +
          productCost +
          shippingValue +
          lateFeeValue -
          discountValue;
        if (total <= 0) {
          errorMessage('Invalid Amount.');
          return;
        }

        setTax(value);
      } else if (name === 'discount') {
        setDiscount(value);
      } else if (name === 'shipping') {
        const total =
          currentValue + productCost + taxValue + lateFeeValue - discountValue;

        if (total <= 0) {
          errorMessage('Invalid Amount');
          return;
        }
        setShipping(value);
      } else if (name === 'lateFee') {
        const total =
          currentValue + productCost + taxValue + shippingValue - discountValue;
        if (total <= 0) {
          errorMessage('Invalid Amount');
          return;
        }
        setLateFee(value);
      }

      // console.log(newData[index],'newData[index]newData[index]')

      // setSelectedProduct()
      // setInVoiceData({...item, [name]: value === '' ? '' : value});
    }
  };

  const handleBlur = event => {
    const {name, value} = event.target;
    let parsedValue = parseFloat(value.trim());
    let formattedValue = isNaN(parsedValue) ? '0.00' : parsedValue.toFixed(2);

    if (name === 'tax') {
      setTax(formattedValue);
    } else if (name === 'discount') {
      setDiscount(formattedValue);
    } else if (name === 'shipping') {
      setShipping(formattedValue);
    } else if (name === 'lateFee') {
      setLateFee(formattedValue);
    }
    // setInVoiceData({...invoiceData, [name]: formattedValue});
  };

  useEffect(() => {
    const updatedLevel = {
      ...levelItem,
      frequency: durationItem,
    };

    const {frequencies, ...levelWithoutFrequencies} = updatedLevel;
    const {id, catalog_membership_id, frequency} = levelWithoutFrequencies;

    const updatedProducts = selectedProduct.map((item, index) => {
      if (index === ItemIndex) {
        return {
          ...item,
          shipping_handling: shipping,
          discount: discount,
          taxes_fee: tax,
          late_fee: lateFee,
          grace_period: gracePeriod,
          total: total.toString(),
          cost: itemCost,
          ...(item.catalog_type === 'membership' && {
            level: {
              id,
              catalog_membership_id,
              frequency: {
                id: frequency.id,
                duration: frequency.duration,
                payment_frequency: frequency.payment_frequency,
                payment_amount: frequency.payment_amount,
                renewal: frequency.renewal,
              },
            },
          }),
        };
      }
      return item;
    });

    console.log(total, 'totaltotaltotaltotal');
    setSelectedProduct(updatedProducts);
  }, [
    tax,
    discount,
    shipping,
    lateFee,
    gracePeriod,
    total,
    itemCost,
    durationType,
    levelType,
  ]);

  useEffect(() => {
    setTotal(
      Number(itemCost) +
        Number(lateFee) +
        Number(tax) +
        Number(shipping) -
        Number(discount),
    );
  }, [tax, discount, shipping, lateFee, itemCost]);

  const handleAmount = item => {
    setDurationItem(item ? item : {});
    if (item.payment_amount && item.payment_amount !== '') {
      setItemCost(item.payment_amount);
    } else {
      setItemCost('0.00');
    }
  };

  useEffect(() => {
   
  }, [outOfStockItems])
  
  // console.log(levels, 'levelslevels');
  // console.log(durations, 'durationsdurations');
  // console.log(levelType, 'levelType');
  // console.log(durationType, 'durationType');
  // console.log(levelItem,'levelItemlevelItem')

  console.log(selectedProduct, 'selectedProductselectedProduct');

  console.log(outOfStockItems, 'outOfStockItemsoutOfStockItems');

  let dropdownWidth = {
    width: '181.55px',
  };
  console.log(itemCost, 'itemCostitemCostitemCost');
  return (
    <div className="invoice_catalog_item">
      <div
        key={item.id + ItemIndex}
        className="d-flex justify-content-between"
        style={{marginBottom: '20px'}}>
        <h1 className="text-break">{item.title}</h1>

        <span
          className=" as-link invoice_delete"
          onClick={() => {
            // removeProduct(item, index);
            setDeleteCatalog({
              item: item,
              index: ItemIndex,
            });
            setShowComponent('deleteCatalog');
          }}>
          <i className="fa fa-trash-can" />
        </span>
      </div>

      <div className="template-flex">
        {/* {item.catalog_type === 'service' && ( */}
        <span className="template-text">
          {item.duration ? formatMinutesTime(item.duration) : ''}
        </span>
        {/* )} */}

        {item.catalog_type === 'product' && (
          <div
            className={`d-flex gap-2 ${
              item.catalog_type === 'product' ? 'flex-1' : ''
            }`}>
               {showOutOfStockError(item, ItemIndex)?.not_allowed?.includes(ItemIndex) && outOfStockItems.length > 0 && (
                          <span className='out_of_stock'>Out of Stock</span>
                        )}
            {/* <span className='out_of_stock'>Out of Stock</span> */}
          </div>
        )}

        {item.catalog_type === 'membership' && (
          <div
            className={`d-flex gap-2 ${
              item.catalog_type === 'membership' ? 'flex-1' : ''
            }`}>
            {item.catalog_type === 'membership' && levels.length > 0 && (
              <div className="mb-3" style={dropdownWidth}>
                <Form.Label controlid="validationCustom01">Levels </Form.Label>
                <Dropdown
                  className="select-wrapper"
                  onSelect={(eventKey, event) => {
                    var item = JSON.parse(eventKey);
                    setLevelItem(item);
                    setLevelType(item.id);
                  }}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    <div className="text-container">
                      {levelType
                        ? levels.find(item => item.id === levelType).level_name
                        : 'Select Role'}
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {levels.map((item, index) => (
                      <Dropdown.Item
                        key={`st-${index}`}
                        eventKey={JSON.stringify(item)}
                        active={item.id === levelType}>
                        {item.level_name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            {item.catalog_type === 'membership' &&
              levels.length > 0 &&
              durations.length > 0 && (
                <div className="mb-3" style={dropdownWidth}>
                  <Form.Label controlid="validationCustom01">
                    Durations{' '}
                  </Form.Label>
                  <Dropdown
                    className="select-wrapper"
                    onSelect={(eventKey, event) => {
                      var item = JSON.parse(eventKey);
                      handleAmount(item);
                      setDurationType(item.id);
                    }}
                    drop="down">
                    <Dropdown.Toggle variant="primary">
                      <div className="text-container">
                        {formatPaymentDescription(
                          durationType
                            ? durations.find(item => item.id === durationType)
                            : 'Select Role',
                        )}
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {durations.map((item, index) => (
                        <Dropdown.Item
                          key={`st-${index}`}
                          eventKey={JSON.stringify(item)}
                          // eventKey={item.id.toString()}
                          active={item.id === durationType}>
                          {formatPaymentDescription(item)}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
          </div>
        )}

        <div
          className={`d-flex gap-1 ${
            itemCost.length > 4 ? 'flex-column' : ''
          }`}>
          <span
            className={`template-text ${
              itemCost.length > 4 ? 'text-end' : ''
            }`}>
            {item.catalog_type === 'service' ||
            item.catalog_type === 'membership'
              ? '(Subscription)'
              : '(Product)'}{' '}
          </span>
          <span
            className={`${itemCost.length > 4 ? 'service_subscription' : ''}`}>
            ${formatNumberWithTwoDecimals(itemCost)}
          </span>
        </div>
      </div>

      {!moreOptions && (
        <div
          className="more-option template-text as-link fit_content mb-3"
          onClick={() => {
            setMoreOptions(!moreOptions);
          }}>
          More Options
        </div>
      )}

      {moreOptions && (
        <>
          {/* <div className="template-flex">
                                <span>Subtotal</span>

                                <span>
                                  $
                                  {formatNumberWithTwoDecimals(
                                    invoiceData.subtotal,
                                  )}
                                </span>
                              </div> */}

          <div className="template-flex">
            <span>Taxes and Fees</span>

            <div className="d-flex ">
              <span className="invoice_cost f_600">$</span>
              <span id="invoice_cost_span" ref={taxRef}>
                {tax}
              </span>

              <Form.Control
                name="tax"
                type="number"
                className="invoice_input_field template-text f_600"
                min="0.00"
                step="0.01"
                style={{width}}
                // style={{ width: `${cost.length}ch` }}
                value={tax}
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
            </div>
          </div>

          <div className="template-flex">
            <span>Discount</span>

            <div className="d-flex ">
              <span className="invoice_cost f_600">$</span>
              <span id="invoice_cost_span" ref={discountRef}>
                {discount}
              </span>

              <Form.Control
                name="discount"
                type="number"
                className="invoice_input_field template-text f_600"
                min="0.00"
                step="0.01"
                style={{width: discountWidth}}
                // style={{ width: `${cost.length}ch` }}
                value={discount}
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
            </div>
          </div>

          {(item.catalog_type === 'service' ||
            item.catalog_type === 'membership') && (
            <div className="template-flex">
              <span>Late Fee</span>

              <div className="d-flex ">
                <span className="invoice_cost f_600">$</span>
                <span id="invoice_cost_span" ref={lateFeeRef}>
                  {lateFee}
                </span>

                <Form.Control
                  name="lateFee"
                  type="number"
                  className="invoice_input_field template-text f_600"
                  min="0.00"
                  step="0.01"
                  style={{width: lateFeeWidth}}
                  // style={{ width: `${cost.length}ch` }}
                  value={lateFee}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          )}

          {item.catalog_type === 'product' && (
            <div className="template-flex">
              <span>Shipping & handling</span>

              <div className="d-flex ">
                <span className="invoice_cost f_600">$</span>
                <span id="invoice_cost_span" ref={shippingRef}>
                  {shipping}
                </span>

                <Form.Control
                  name="shipping"
                  type="number"
                  className="invoice_input_field template-text f_600"
                  min="0.00"
                  step="0.01"
                  style={{width: shippingWidth}}
                  // style={{ width: `${cost.length}ch` }}
                  value={shipping}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          )}

          {(item.catalog_type === 'service' ||
            item.catalog_type === 'membership') &&
            item.grace_period !== '' && (
              <div className="mb-3">
                <Form.Label controlid="validationCustom01">
                  Grace Period{' '}
                </Form.Label>
                <Dropdown
                  className="select-wrapper"
                  onSelect={(eventKey, event) => {
                    setGracePeriod(eventKey);
                  }}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    {monthDays
                      ? monthDays.find(item => item === gracePeriod)
                      : 'Select Role'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {monthDays.map((item, index) => (
                      <Dropdown.Item
                        key={`st-${index}`}
                        eventKey={item}
                        active={item === gracePeriod}>
                        {item}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

          {/* {item.catalog_type === 'membership' && levels.length > 0 && (
            <div className="mb-3">
              <Form.Label controlid="validationCustom01">Levels </Form.Label>
              <Dropdown
                className="select-wrapper"
                onSelect={(eventKey, event) => {
                  var item = JSON.parse(eventKey);
                  setLevelItem(item)
                  setLevelType(item.id);
                  
                }}
                drop="down">
                <Dropdown.Toggle variant="primary">
                  {levelType
                    ? levels.find(item => item.id === levelType).level_name
                    : 'Select Role'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {levels.map((item, index) => (
                    <Dropdown.Item
                      key={`st-${index}`}
                      eventKey={JSON.stringify(item)}
                      active={item.id === levelType}>
                      {item.level_name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )} */}

          {/* {item.catalog_type === 'membership' &&
            levels.length > 0 &&
            durations.length > 0 && (
              <div className="mb-3">
                <Form.Label controlid="validationCustom01">
                  Durations{' '}
                </Form.Label>
                <Dropdown
                  className="select-wrapper"
                  onSelect={(eventKey, event) => {
                    var item = JSON.parse(eventKey);
                    handleAmount(item)
                    setDurationType(item.id);
                    
                   
                  }}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    {formatPaymentDescription(
                      durationType
                        ? durations.find(item => item.id === durationType)
                            
                        : 'Select Role',
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {durations.map((item, index) => (
                      <Dropdown.Item
                        key={`st-${index}`}
                        eventKey={JSON.stringify(item)}
                        // eventKey={item.id.toString()}
                        active={item.id === durationType}>
                        {formatPaymentDescription(item)}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )} */}

          {/* {item.catalog_type === 'membership' && item.membership_levels.length > 0 &&
            (
              <div className="mb-3">
                <Form.Label controlid="validationCustom01">
                  Levels{' '}
                </Form.Label>
                <Dropdown
                  className="select-wrapper"
                  onSelect={(eventKey, event) => {
                    setLevelType(eventKey);
                  }}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    {monthDays
                      ? item.membership_levels.find(item => item === levelType)
                      : 'Select Role'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {item.membership_levels.map((item, index) => (
                      <Dropdown.Item
                        key={`st-${index}`}
                        eventKey={item.id}
                        active={item === levelType}>
                        {item}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )} */}

          <div className="template-flex-total">
            <span className="template-text">Subtotal</span>

            <span>${formatNumberWithTwoDecimals(total)}</span>
          </div>
        </>
      )}

      {moreOptions && (
        <div
          className="more-option template-text as-link mb-3"
          onClick={() => {
            setMoreOptions(!moreOptions);
          }}>
          Less Options
        </div>
      )}
    </div>
  );
};

export default InvoiceProduct;
