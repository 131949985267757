import React, {useState, useEffect, useRef} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';

import TextareaAutosize from 'react-textarea-autosize';

import {ref, onValue, update} from 'firebase/database';
import {db, storage, storageRef} from '../../Firebase/firebase';
import {uploadBytes, getDownloadURL} from 'firebase/storage';
import commonApi from '../../Services/CommonService';
import Scrollbars from 'react-custom-scrollbars';
import LoaderModal from '../Modals/LoaderModal';
import SnapshotList from '../Snapshot/SnapshotList';
import SnapshotDetail from '../Snapshot/SnapshotDetail';
import {DEFAULT_IMAGE, S3_EMOJI_DEV} from '../../Utils/env';
import ListUsers from './ListUsers';
import ProfileComponent from '../ProfilePage/ProfileComponent';
import EmojiModal from '../SessionDetails/EmojiModal';
import {emojisArr} from '../../Constants/IonicEmojis';
import {errorMessage} from '../../Utils/ToastMessages';
import SmartChatPoll from './SmartChatPoll';
import {RenderHTMLBlock} from '../../Utils/Helpers';
import {
  arrayColumnMerge,
  chatroomInfo,
  chatroomInfoOppo,
  collectingOppoUsers,
  collectingUsers,
  filteringUsers,
  getRepliesEmojisAndSnapshots,
} from '../../Utils/commonUtils';
import SlidingPanel from '../SlidingPanel';
import CustomModal from '../CustomModal';
import ReplyBox from './ReplyBox';
import UsersReactionModal from '../Modals/UsersReactionModal';
import {fixName} from '../../Utils/commonUtils';
import {getItem, setItem} from '../../Utils/LocalStorage';
import {generateName} from '../../Utils/commonUtils';
import {getChatCount} from '../../Utils/commonUtils';
import {AddShareableIcon} from '../../Constants/Images';
import MessagesShareAbles from '../ShareAbles/MessagesShareAbles';
import OpportunityDetail from '../OpportunitiesList/OpportunityDetail';
import SmartChatPage from './SmartChatPage';
import OpportunityMessageList from '../OpportunitiesList/OpportunityMessageList';
import AppointmentList from '../AppointmentPage/AppointmentList';
import UserAppointments from '../AppointmentPage/UserAppointments';
import CreateSession from '../SessionDetails/CreateSession';
import SessionDetail from '../SessionDetails/SessionDetail';
import PurchaseTicket from '../Referral/PurchaseTicket';
import EventDetail from '../Referral/EventDetail';

const SmartReplyList = ({
  chatx,
  onClose,
  allChatUsers,
  messagePath,
  folderName,
  chatId,
  xfbFolder,
  chatRoomName,
  item,
  chatDetails,
  withHeadingSpace,
  chatEnableStatus,
  snapShotImagesLast3,
  workSpaceItem,
}) => {
  // ---------------------

  const [userData, setUserData] = useState({
    id: chatx.sent_by,
    name: '',
    profile_img: DEFAULT_IMAGE,
  });
  const [showComponent, setShowComponent] = useState('');
  // const [chat, setChat] = useState(chatx);

  // ---------------------

  // const [userData, setUserData] = useState({});

  const [replyIndex, setReplyIndex] = useState(0);
  const [otherMsg, setOtherMsg] = useState('');
  // const [msgChildKey, setMsgChildKey] = useState('');
  // const [msgParentKey, setMsgParentKey] = useState('');
  const [emojiToMsg, setEmojiToMsg] = useState({});

  const [showEmojis, setShowEmojis] = useState(false);
  const [emojiList, setEmojiList] = useState([]);

  const [showParticipants, setShowParticipants] = useState(false);
  const [pollParticipants, setPollParticipants] = useState([]);

  const [imageSrc, setImageSrc] = useState(null);
  const [webcamStream, setWebcamStream] = useState(null);

  const [showLoader, setshowLoader] = useState(false);
  const [addSnapshot, setAddSnapshot] = useState(false);
  const [showUserProfile, setShowUserProfile] = useState('');
  const [snapshotIds, setSnapshotIds] = useState([]);
  const [snapshotImages, setSnapshotImages] = useState([]);
  const [showSnapshotDetail, setShowSnapshotDetail] = useState(false);
  const [snapshotData, setSnapshotData] = useState({});
  const [showEmojiModal, setshowEmojiModal] = useState(false);
  const [showReplyEmojiModal, setShowReplyEmojiModal] = useState(false);
  const [imgOptions, toggleImgOptions] = useState(false);
  const [allChat, setallChat] = useState(chatx);
  // const [chatId, setchatId] = useState(props.chatRoomId);
  // const [isNewChatroom, setNewChatroom] = useState(props.chatRoomName);

  const [selectedImage, setSelectedImage] = useState(null);
  // const [showUsers, setShowUsers] = useState(false);

  // const [oppoCall, setOppoCall] = useState(false);
  // const [oppoGroupChat, setOppoGroupChat] = useState(false);
  const [modalEndChat, setModalEndChat] = useState(false);
  const [writtenMsg, setWrittenMsg] = useState('');
  // const [isReply, setIsReply] = useState(false);
  const [showPrivate, setPrivateUser] = useState(false);
  const [messageBodyNew, setMessageBodyNew] = useState({});

  const [popupImages, setPopupImages] = useState([]);
  const [show, setShow] = useState(false);
  const [cameraOptions, toggleCameraOptions] = useState(false);
  const [replyOptions, toggleReplyOptions] = useState(false);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);
  const messagesEndRef = useRef(null);
  const [snapshotMultiIds, setSnapshotMultiIds] = useState('');

  const [snapshotSingleId, setSnapshotSingleId] = useState('');

  const [messageBody, setMessageBody] = useState({
    message: '',
    emoji: '',
    accomplishment_data: '',
    appointment_data: '',
    group_chat_data: '',
    event_data: '',
    session_data: '',
    opportunity_data: '',
    network_data: '',
    picture_url: '',
    document_url: '',
    video_url: '',
    type: 'message',
    isPrivate: false,
    uniqueKey: '',
    privateChatData: {},
    sent_by: localStorage.getItem('user'),
    send_date: Date.now(),
  });

  // open messgaes from messages

  const [chatDetailsFromMessages, setChatDetailsFromMessages] = useState([]);
  const [chatDetailItemFromMessages, setChatDetailItemFromMessages] = useState(
    [],
  );
  const [allChatUsersFromMessages, setAllChatUsersFromMessages] = useState([]);
  const [chatRoomNameFromMessages, setChatRoomNameFromMessages] = useState('');
  const [chatRoomIdFromMessages, setChatRoomIdFromMessages] = useState('');
  const [chatPropTypeFromMessages, setChatPropTypeFromMessages] = useState('');

  const emojisAndSnapshots = getRepliesEmojisAndSnapshots(
    chatx,
    localStorage.getItem('id'),
  );

  // const [showComponent, setShowComponent] = useState('');
  const [dataForComponent, setDataForComponent] = useState('');

  // const [xchatStatusIn, setxChatStatusIn] = useState(["accepted", "open", "join"]);
  // const [folderName, setxFbFolder] = useState((props.type.toLowerCase().indexOf("oppo") !== -1) ? "opportunities" : "chats");
  // const [xchatDetail, setxChatDetail] = useState(props.chatDetails);
  // const [xchatRoomStatus, setxChatRoomStatus] = useState();
  // // const [xotherUser, setxOtherUser] = useState((props.chatDetails.creator_id == localStorage.getItem("user")) ? "user_detail" : props.chatDetails.hasOwnProperty('creator_details') ? 'creator_details' : 'creator_detail');
  // const [xchatRoomName, setxChatRoomName] = useState(props.chatRoomName);
  // const [xchatType, setxChatType] = useState(props.type);
  // // const [chatRoomName, setChatRoomName] = useState((props.chatRoomName) ? props.chatRoomName : ("chatroom_" + (Math.random() + 1).toString(36).substring(3)));

  const inputRef = useRef(null);
  const webcamRef = useRef(null);
  const scrollRef = useRef(null);
  const allowedImagestypes = ['image/jpeg', 'image/png', 'image/jpg'];

  // const chatStatusIn = ["accepted", "open", "join", "pending", "started"];
  // const fbFolder = (props.type === 'new-opportunity') ? "opportunities" : ((props.chatDetails.length > 0 && !(props.chatDetails.hasOwnProperty("chat_type"))) || props.chatDetails.chat_type === "opportunity") ? "opportunities" : "chats";
  // // const creatorDetail = props.chatDetails.hasOwnProperty('creator_details') ? 'creator_details' : 'creator_detail';
  // const chatRoomStatus = (props.chatDetails.chat_type === "opportunity") ? props.oppoDetails.other_persons_opportunity_chat_room[0].status : props.chatDetails.hasOwnProperty('chat_room_status') ? props.chatDetails.chat_room_status : props.chatDetails.status;
  // // // // // const chatRoomStatus = props.chatDetails.hasOwnProperty('chat_room_status') ? props.chatDetails.chat_room_status : props.chatDetails.hasOwnProperty('status') ? props.chatDetails.status : "open";
  // const otherUser = (props.chatDetails.creator_id == localStorage.getItem("user")) ? "user_detail" : props.chatDetails.hasOwnProperty('creator_details') ? 'creator_details' : 'creator_detail';
  // const chatRoomName = (props.chatRoomName) ? props.chatRoomName : ("chatroom_" + (Math.random() + 1).toString(36).substring(3));
  // // // // const opportunityId = props.oppoDetails.hasOwnProperty("opportunity_id") ? props.oppoDetails.opportunity_id : props.oppoDetails.id;
  // // const oppoCreatorDetail = props.oppoDetails.hasOwnProperty('creator_details') ? 'creator_details' : 'creator_detail';
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  const startWebCam = () => {
    // request access to the user's webcam
    navigator.mediaDevices.getUserMedia({video: true}).then(stream => {
      // save the webcam stream to state
      setWebcamStream(stream);
      // navigator.attachMediaStream(webcamRef.current, stream);
      setTimeout(() => {
        webcamRef.current.srcObject = stream;
      }, 1000);
    });
  };

  const handleOpenChatRoom = chatDetail => {
    // alert()
    // if (tabType === 'chats') {
    // alert('hehe');
    setChatDetailsFromMessages(chatDetail);
    setAllChatUsersFromMessages(collectingUsers(chatDetail));
    setChatRoomNameFromMessages(chatDetail.chat_room_name);
    setChatRoomIdFromMessages(chatDetail.chat_id);

    if (chatDetail.chat_type === 'opportunity') {
      if (chatDetail.creator_id === localStorage.getItem('id')) {
        setChatPropTypeFromMessages('mine-opportunity');
      } else if (chatDetail.team_member_ids) {
        const myArray = chatDetail.team_member_ids.split(',');
        if (myArray.includes(localStorage.getItem('id'))) {
          setChatPropTypeFromMessages('mine-opportunity');
        } else {
          setChatPropTypeFromMessages('other-opportunity');
        }
      } else {
        setChatPropTypeFromMessages('other-opportunity');
      }
    } else {
      if (chatDetail.chat_room_type === 'group') {
        setChatPropTypeFromMessages('personal-group');
      } else {
        setChatPropTypeFromMessages('personal-single');
      }
    }

    const chatItem = chatDetail;
    chatItem.id = chatDetail.type_id;
    setChatDetailItemFromMessages(chatItem);

    setShowComponent('chat-component');
    // }
    //  else if (tabType === 'subInvites') {
    //   if (chatDetail.chat_type === 'opportunity') {
    //     manageOppoChatInvitation(
    //       chatDetail.id,
    //       chatDetail.opportunity_id,
    //       'accepted',
    //     );
    //   } else {
    //     manageChatInvitation(
    //       chatDetail.id,
    //       chatDetail.invitation_type,
    //       'accepted',
    //     );
    //   }

    //   setChatDetails(chatDetail);
    //   setAllChatUsers(collectingUsers(chatDetail));
    //   setChatRoomName(chatDetail.chat_room_name);
    //   setChatRoomId(chatDetail.chat_id);

    //   // const chatItem = chatDetail;
    //   // chatItem.id = chatDetail.type_id;
    //   // setChatDetailItem(chatItem);

    //   if (chatDetail.chat_type === 'opportunity') {
    //     setChatPropType('other-opportunity');
    //   } else {
    //     if (chatDetail.chat_room_type === 'group') {
    //       setChatPropType('personal-group');
    //     } else {
    //       setChatPropType('personal-single');
    //     }
    //   }

    //   setTimeout(() => {
    //     setSecondComponent('chat-component');
    //   }, 100);
    // }
  };

  const changeTicketImg = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    // let fileExtension = file.name.split('.').pop().toLowerCase();

    // If no image selected, return
    // || fileExtension === "gif"
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImagestypes.includes(file.type)) {
      makeFile(file);
      toggleImgOptions(false)
      // setSelectedImage(file);

      e.target.value = null;

    
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null; // reset the file input
    }
    
  };

  const captureImage = () => {
    // create a canvas element
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // set the canvas size to the size of the video
    canvas.width = webcamStream.getTracks()[0].getSettings().width;
    canvas.height = webcamStream.getTracks()[0].getSettings().height;

    // draw the video on the canvas
    context.drawImage(webcamRef.current, 0, 0);

    // convert the canvas to an image
    const img = new Image();
    img.src = canvas.toDataURL();
    setImageSrc(img.src);

    canvas.toBlob(blob => {
      makeFile(blob);
    });
  };
  const handleScroll = values => {
    const {scrollTop} = values.target;
    setItem('scrollTopReply', scrollTop);
  };

  const handleChatBoxSignal = (
    signalType,
    signalData,
    childKey,
    parentKey,
    snapShotId,
  ) => {
    if (signalType === 'update-poll') {
      // setMsgChildKey(childKey);
      // setMsgParentKey(parentKey);
      // setPollToMsg(signalData);
      handlePoll(signalData);
      // setEmojiToMsg(signalData);
      // setshowEmojiModal(true);
    } else if (signalType === 'add-reply') {
      setMessageBody({...messageBody, reply: signalData});
      // setIsReply(true);
    } else if (signalType === 'show-snapshot-detail') {
      getSpecificAccomplishment(signalData, snapShotId);
    } else if (signalType === 'add-emoji') {
      // setMsgChildKey(childKey);
      // setMsgParentKey(parentKey);
      setEmojiToMsg(signalData);
      setshowEmojiModal(true);
    } else if (signalType === 'show-emojis') {
      setEmojiList(signalData);
      setShowEmojis(true);
    } else if (signalType === 'mention-user') {
      handleMentionUsers(signalData);
    } else if (signalType === 'add-reply-emoji') {
      setShowReplyEmojiModal(true);
    } else if (signalType === 'show-participants') {
      const chatData = signalData.message.options;
      const participantIds = arrayColumnMerge(chatData, 'userIds');
      setPollParticipants(filteringUsers(allChatUsers, participantIds));
      setShowParticipants(true);
    } else if (signalType === 'show-video') {
      const tempImgs = [{id: 0, video: signalData}];
      setShow(true);
      setPopupImages(tempImgs);
    } else if (signalType === 'show-image') {
      const tempImgs = [{id: 0, image: signalData}];
      setShow(true);
      setPopupImages(tempImgs);
    }
  };

  // const handleSelectReply = (key, item, cKey, pKey) => {

  //   if (key === 'update-poll') {
  //     // handleChatBoxSignal('update-poll', item, cKey, pKey);
  //   } else if (key === 'reply') {
  //     // delete item.document_url;
  //     // delete item.key;
  //     // delete item.parentKey;
  //     // delete item.reply;
  //     // handleChatBoxSignal('add-reply', item, cKey, pKey);
  //     // handleChatBoxSignal('add-replies', item, cKey, pKey);
  //   } else if (key === 'emoji') {
  //     handleChatBoxSignal('add-reply-emoji', item, cKey, pKey);
  //     // handleChatBoxSignal('add-emoji', item, cKey, pKey);
  //   }
  // };

  const showLightbox = (imagez, current) => {
    //

    const tempImgs = [{id: 0, image: URL.createObjectURL(selectedImage)}];

    // for (let i = 0; i < imagez.length; i++) {
    //     tempImgs.push({
    //         id: i,
    //         image: imagez[i].images[0],
    //     });
    // }

    setShow(true);
    setPopupImages(tempImgs);
    // setCurrent(current);
  };

  const handleSelect = e => {
    if (e === '2') {
      inputRef.current.click();
    } else if (e === '3') {
      setOtherMsg('Snapshot');
      setAddSnapshot(true);
    } else if (e === '1') {
      startWebCam();
    }
  };

  const makeFile = data => {
    const type = data.type.toString();
    if (type.search('image') === 0) {
      if (data) {
        setSelectedImage(data);
        setOtherMsg('Image');
      }
    }
    if (type.search('video') === 0) {
      if (data) {
        setSelectedImage(data);
        setVideoPreviewUrl(URL.createObjectURL(data));
        setOtherMsg('Video');
      }
    }
    if (
      type.search('document') === 0 ||
      type.search('pdf') === 0 ||
      type.search('application') === 0
    ) {
      if (data) {
        setSelectedImage(data);
        setOtherMsg('Document');
      }
    }
  };

  const clearData = () => {
    setSelectedImage(null);
    setVideoPreviewUrl(null);
    setMessageBody({
      message: '',
      emoji: '',
      accomplishment_data: '',
      appointment_data: '',
      group_chat_data: '',
      event_data: '',
      session_data: '',
      opportunity_data: '',
      network_data: '',
      picture_url: '',
      document_url: '',
      video_url: '',
      type: 'message',
      isPrivate: false,
      privateChatData: {},
      sent_by: localStorage.getItem('user'),
      send_date: Date.now(),
      uniqueKey: '',
      // one:'2',
    });
    setSnapshotIds([]);
    setSnapshotImages([]);
    setPrivateUser(false);
  };

  const uploadFile = () => {
    var type;
    if (selectedImage) {
      type = selectedImage.type.toString();
    }
    if (type.search('video') === 0) {
      if (selectedImage) {
        const fileName =
          'video_' +
          Math.floor(Date.now() / 1000).toString() +
          selectedImage.name;
        const fileRef = storageRef(storage, 'videos/' + fileName);
        uploadBytes(fileRef, selectedImage).then(res => {
          getDownloadURL(res.ref).then(downloadURL => {
            setMessageBody({
              ...messageBody,
              type: 'video',
              video_url: downloadURL,
            });
            setMessageBodyNew({
              ...messageBody,
              type: 'video',
              video_url: downloadURL,
            });
          });
        });
      }
    }
    if (type.search('image') === 0) {
      if (selectedImage) {
        const fileName =
          'image_' + Math.floor(Date.now() / 1000).toString() + '.png';
        const fileRef = storageRef(storage, 'images/' + fileName);
        uploadBytes(fileRef, selectedImage).then(res => {
          getDownloadURL(res.ref).then(downloadURL => {
            setMessageBody({
              ...messageBody,
              type: 'image',
              picture_url: downloadURL,
            });
            setMessageBodyNew({
              ...messageBody,
              type: 'image',
              picture_url: downloadURL,
            });
          });
        });
      }
    }
    if (
      type.search('document') === 0 ||
      type.search('pdf') === 0 ||
      type.search('application') === 0
    ) {
      if (selectedImage) {
        const fileName = 'document_' + Math.floor(Date.now() / 1000).toString();
        const fileRef = storageRef(storage, 'documents/' + fileName);

        uploadBytes(fileRef, selectedImage).then(res => {
          getDownloadURL(res.ref).then(downloadURL => {
            setMessageBody({
              ...messageBody,
              type: 'document',
              document_url: downloadURL,
            });
            setMessageBodyNew({
              ...messageBody,
              type: 'document',
              document_url: downloadURL,
            });
          });
        });
      }
    }
    setSelectedImage(null);
  };

  const sendSnapshotFromModel = item => {
    let images = [
      {
        id: item.id,
        images: [item.images[0]],
      },
    ];
    // snapshotIds.map((item, index) => {
    //   images.push({
    //     id: item,
    //     images: [snapshotImages[index]],
    //   });
    // });
    setMessageBody({
      ...messageBody,
      type: 'snapshot',
      picture_url: images,
    });
    setMessageBodyNew({
      ...messageBody,
      type: 'snapshot',
      picture_url: images,
    });
  };
  const sendSnapshot = () => {
    let images = [];
    snapshotIds.forEach((item, index) => {
      images.push({
        id: item,
        images: [snapshotImages[index]],
      });
    });
    setMessageBody({
      ...messageBody,
      type: 'snapshot',
      picture_url: images,
    });
    setMessageBodyNew({
      ...messageBody,
      type: 'snapshot',
      picture_url: images,
    });
  };

  const saveMessage = () => {
    if (selectedImage) {
      setshowLoader(true);
      uploadFile();
      setWrittenMsg('');
      // setIsReply(false);
    } else if (snapshotIds.length > 0) {
      sendSnapshot();
      setWrittenMsg('');
      // setIsReply(false);
    } else if (writtenMsg.trim().length !== 0) {
      sendMessage();
      setWrittenMsg('');
      // setIsReply(false);
    } else {
      //
      setWrittenMsg('');
      setMessageBody({...messageBody, message: ''});
      errorMessage("Can't send empty messsage.");
    }
  };

  const sendEmoji = emojiMessage => {
    // let msgBody = emojiToMsg;
    // msgBody.emoji = "ja raha ha";
    const databaseRef = ref(db, messagePath);
    update(databaseRef, emojiMessage)
      .then(() => {
        // openChatroom();
        // setMsgChildKey('');
        // setMsgParentKey('');
        setEmojiToMsg({});
        setshowEmojiModal(false);
        setShowReplyEmojiModal(false);
        // setshowLoader(false);
      })
      .then(() => {
        // clearData();
      });
  };

  const handleAddReplyEmoji = icon => {
    const myArray = icon.split('/').slice(-2);
    const skinTone = myArray[0];
    const emojiName = myArray[1].split('.', 1).toString();
    const found = emojisArr.find(objs => objs.name === emojiName);
    const data = {
      emojiID: found.id,
      emojiName: emojiName,
      send_date: Date.now(),
      sent_by: localStorage.getItem('id'),
      skinTone: skinTone,
      type: 'emoji',
      uniqueKey: generateName(20),
    };

    let replies = allChat.replies;
    for (let x in replies) {
      // count += array[x][column].length;
      // count = count.concat(array[x][column]);

      if (x === replyIndex) {
        if (
          replies[x].hasOwnProperty('emojis') &&
          replies[x].emojis.length > 0
        ) {
          let prevEmojis = replies[x].emojis;
          prevEmojis.push(data);
          replies[x].emojis = prevEmojis;
          sendEmoji({replies: replies});
        } else {
          replies[x].emojis = [data];
          sendEmoji({replies: replies});
        }
        break;
      }
    }
  };

  const handleReplyMarkAsBest = () => {
    let payload = {
      best_ans: allChat.type,
      best_ans_user: allChat.sent_by,
      question_id: item.id,
    };
    if (allChat) {
      if (allChat.type === 'message') {
        payload.best_ans = allChat.message;
      }

      commonApi.mark_as_best(payload);
    }

    // remove old mark
    if (localStorage.getItem('isCorrectPath')) {
      let alterData = {isCorrect: false};
      let isCorrectMessage = localStorage.getItem('isCorrectMessage');

      if (isCorrectMessage) {
        isCorrectMessage = JSON.parse(isCorrectMessage);

        if (isCorrectMessage.replies) {
          let replies = isCorrectMessage.replies;
          for (let x in replies) {
            // if (replies[x].isCorrect) {
            replies[x].isCorrect = false;
            // }
          }
          alterData.replies = replies;
        }
      }

      const databaseRefOld = ref(
        db,
        'chatrooms/' +
          xfbFolder +
          '/' +
          chatRoomName +
          '/' +
          localStorage.getItem('isCorrectPath'),
      );
      update(databaseRefOld, alterData);
      // update(databaseRefOld, {isCorrect: false});
    }

    // add new mark
    let allChatX = allChat;
    let repliez = allChat.replies;
    for (let x in repliez) {
      if (x === replyIndex) {
        repliez[x].isCorrect = true;
      } else {
        repliez[x].isCorrect = false;
      }
      sendEmoji({isCorrect: true, replies: repliez});
    }
    let xyz = messagePath.split('/').slice(-2);
    localStorage.setItem('isCorrectPath', xyz[0] + '/' + xyz[1]);
    allChatX.replies = repliez;
    localStorage.setItem('isCorrectMessage', JSON.stringify(allChatX));
  };

  const handleAddEmoji = icon => {
    const myArray = icon.split('/').slice(-2);
    const skinTone = myArray[0];
    const emojiName = myArray[1].split('.', 1).toString();
    const found = emojisArr.find(objs =>
      objs.name ? objs.name === emojiName : objs.emojiName === emojiName,
    );
    const data = {
      emojiID: found.id,
      emojiName: emojiName,
      send_date: Date.now(),
      sent_by: localStorage.getItem('id'),
      skinTone: skinTone,
      type: 'emoji',
    };

    if (emojiToMsg.hasOwnProperty('emojis') && emojiToMsg.emojis.length > 0) {
      let prevEmojis = emojiToMsg.emojis;
      prevEmojis.push(data);
      sendEmoji({emojis: prevEmojis});
    } else {
      sendEmoji({emojis: [data]});
    }
  };

  const sendMessagesEmoji = icon => {
    const myArray = icon.split('/').slice(-2);
    const skinTone = myArray[0];
    const emojiName = myArray[1].split('.', 1).toString();
    const found = emojisArr.find(objs =>
      objs.name ? objs.name === emojiName : objs.emojiName === emojiName,
    );
    const data = {
      emojiID: found.id,
      emojiName: emojiName,
      // send_date: new Date().getTime(),

      skinTone: skinTone,
    };

    setMessageBody({
      ...messageBody,
      type: 'emoji',
      emoji: data,
    });
    setMessageBodyNew({
      ...messageBody,
      type: 'emoji',
      emoji: data,
    });
    setshowEmojiModal(false);
  };

  const ShowEmojModel = () => {
    setshowEmojiModal('add-emoji');
  };

  // const getOpportunityData = () => {
  //     var data = {
  // user_id: props.userData.id,
  //         type: "mine",
  //     };
  //     commonApi
  //         .get_user_available_opportunities(data)
  //         .then((res) => {
  //             if (res) {
  //
  //                 res.user_available_opportunity.map((item) => {
  //                     if (item.id === opportunityId) {
  //                         if (item.chat_detail.hasOwnProperty("chat_room_name")) {
  //                             setChatroomName(item.chat_detail.chat_room_name);
  //                             setchatId(item.chat_detail.id);
  //                             if (item.chat_detail.status === "close") {
  //                                 setChatroomStatus(false);
  //                             }
  //                         } else {
  //                             setNewChatroom(true);
  //                             setChatroomName("chatroom_" + (Math.random() + 1).toString(36).substring(7));
  //                             setshowLoader(false);
  //                         }
  //                     }
  //                 });
  //             }
  //         })
  //         .catch((err) => {
  //             console.log("Err", err);
  //             return { type: "error", message: err.message };
  //         });
  // };

  // const createOpportunityChatroom = () => {
  //     var data = {
  //         chat_room_name: chatRoomName,
  //         // creator_id: props.oppoDetails.created_by,
  //         opportunity_id: opportunityId,
  //         user_id: "",
  //     };
  //     commonApi
  //         .create_opportunity_chat_room(data)
  //         .then((res) => {
  //             if (res) {
  //                 setxChatDetail(res.chat_detail);
  //                 setchatId(res.opportunity_chat_room_id);
  //                 setNewChatroom(res.opportunity_chat_room_name);
  //             }
  //         })
  //         .catch((err) => {
  //             // console.log("Err", err);
  //             return { type: "error", message: err.message };
  //         });
  // };

  // const createChatroom = () => {
  //     var data = {
  //         chat_room_name: chatRoomName,
  //         chat_room_type: "single",
  //         type: "personal",
  //         // // // user_id: (props.userData.hasOwnProperty("user_id")) ? props.userData.User_id : props.userData.id
  //     };
  //     commonApi
  //         .create_chat_room(data)
  //         .then((res) => {
  //             if (res) {
  //                 setxChatDetail(res.chat_detail);
  //                 setchatId(res.opportunity_chat_room_id);
  //                 setNewChatroom(res.opportunity_chat_room_name);
  //             }
  //         })
  //         .catch((err) => {
  //             // console.log("Err", err);
  //             return { type: "error", message: err.message };
  //         });
  // };

  // const leavePolitely = () => {
  //     var data = {
  //         chat_room_id: chatId,
  //         opportunity_id: opportunityId,
  //     };
  //     commonApi
  //         .politely_leave_opportunity(data)
  //         .then((res) => {
  //             if (res.status === 200) {
  //                 // setChatroomStatus(false);
  //                 // props.onClose();
  //             }
  //         })
  //         .catch((err) => {
  //             console.log("Err", err);
  //             return { type: "error", message: err.message };
  //         });
  // };

  // const manageOpportunityStatus = (status) => {
  //     var data = {
  //       chat_id : chatId,
  //       opportunity_id : opportunityId,
  //       status : status,
  //       user_id : xchatDetail.user_id
  //     };
  //     commonApi
  //         .manage_opportunity_status(data)
  //         .then((res) => {
  //             if (res.status === 200) {
  //                 if (res.success) {
  //                     successMessage(res.message);
  //                 } else {
  //                     errorMessage(res.message);
  //                 }
  //                 // setChatroomStatus(false);
  //             }
  //         })
  //         .catch((err) => {
  //             console.log("Err", err);
  //             return { type: "error", message: err.message };
  //         });
  //   };

  //   const changeOpportunityStatus = () => {
  //     var data = {
  //       chat_room_id: chatId,
  //       opportunity_id: opportunityId,
  //       user_id: xchatDetail.user_id
  //     };
  //     commonApi
  //         .change_opportunity_status(data)
  //         .then((res) => {
  //             if (res.status === 200) {
  //                 if (res.success) {
  //                     successMessage(res.message);
  //                 } else {
  //                     errorMessage(res.message);
  //                 }
  //                 // setChatroomStatus(false);
  //             }
  //         })
  //         .catch((err) => {
  //             console.log("Err", err);
  //             return { type: "error", message: err.message };
  //         });
  //   };

  const getUserData = id => {
    var data = {user_id: id};
    commonApi
      .user_info(data)
      .then(res => {
        if (res) {
          setUserData(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getSpecificAccomplishment = (data, idOfSnapshot) => {
    let id = data.map(item => item.id);
    var payload = {
      accomplishment_ids: id,
    };
    commonApi
      .get_specific_accomplishment(payload)
      .then(res => {
        // if (res.status === 200) {
        //   getUserData(res.accomplishment_detail[0].created_by);
        //   setSnapshotData(res.accomplishment_detail[0]);
        //   setShowSnapshotDetail(true);
        if (res.success) {
          getUserData(res.accomplishment_detail[0].created_by);

          if (id.length > 1) {
            // alert('id')
            setSnapshotSingleId(idOfSnapshot);
            setSnapshotMultiIds(id.toString(','));
            setShowSnapshotDetail(true);
          } else {
            // alert('else')
            setSnapshotData(res.accomplishment_detail[0]);
            setShowSnapshotDetail(true);
          }

          // setSnapshotData(res.accomplishment_detail[0]);
          // setShowSnapshotDetail(true);
        } else {
          errorMessage(res.message);
        }
        // }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const saveOppotunityLastMessage = (chat, message) => {
    var data = {opportunity_chat_room_id: chatId, last_message: message};
    commonApi
      .add_opportunity_last_message(data)
      .then(res => {
        if (res) {
          clearData();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const saveLastMessage = (chat, message) => {
    var data = {chat_room_id: chat, last_message: message};
    commonApi
      .save_last_message(data)
      .then(res => {
        if (res) {
          clearData();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const sendAnyThink = (type, data) => {
    // snapshotIds.map((item, index) => {
    //   images.push({
    //     id: item,
    //     images: [snapshotImages[index]],
    //   });
    // });

    if (type === 'snapShot') {
      setMessageBody({
        ...messageBody,
        type: data.data_type,
        accomplishment_data: data,
      });
      setMessageBodyNew({
        ...messageBody,
        type: data.data_type,
        accomplishment_data: data,
      });
    } else if (type === 'profile') {
      setMessageBody({
        ...messageBody,
        type: data.data_type,
        network_data: data,
      });
      setMessageBodyNew({
        ...messageBody,
        type: data.data_type,
        network_data: data,
      });
    } else if (type === 'event') {
      setMessageBody({
        ...messageBody,
        type: data.data_type,
        event_data: data,
      });
      setMessageBodyNew({
        ...messageBody,
        type: data.data_type,
        event_data: data,
      });
    } else if (type === 'conference') {
      setMessageBody({
        ...messageBody,
        type: data.data_type,
        session_data: data,
      });
      setMessageBodyNew({
        ...messageBody,
        type: data.data_type,
        session_data: data,
      });
    } else if (type === 'opportunity') {
      setMessageBody({
        ...messageBody,
        type: data.data_type,
        opportunity_data: data,
      });
      setMessageBodyNew({
        ...messageBody,
        type: data.data_type,
        opportunity_data: data,
      });
    } else if (type === 'appointment') {
      setMessageBody({
        ...messageBody,
        type: data.data_type,
        appointment_data: data,
      });
      setMessageBodyNew({
        ...messageBody,
        type: data.data_type,
        appointment_data: data,
      });
    } else if (type === 'message') {
      setMessageBody({
        ...messageBody,
        type: data.data_type,
        group_chat_data: data,
      });
      setMessageBodyNew({
        ...messageBody,
        type: data.data_type,
        group_chat_data: data,
      });
    }
  };
  const sendMessage = () => {
    if (
      messageBody.message !== '' ||
      messageBody.picture_url !== '' ||
      messageBody.document_url !== '' ||
      messageBody.video_url !== '' ||
      messageBody.emoji !== '' ||
      messageBody.appointment_data !== '' ||
      messageBody.group_chat_data !== '' ||
      messageBody.event_data !== '' ||
      messageBody.session_data !== '' ||
      messageBody.opportunity_data !== '' ||
      messageBody.network_data !== '' ||
      messageBody.accomplishment_data !== ''
    ) {
      // if (isNewChatroom) {
      // } else {
      //     // createChatroom();
      //     if (xchatType === 'new-opportunity') {
      //         createOpportunityChatroom();
      //     } else if (xchatType === 'new-personal-single') {
      //         createChatroom();
      //     }
      // }

      let prevReplies = allChat.replies;
      let newBody = messageBody;
      newBody.send_date = new Date().getTime();
      // newBody.uniqueKey = generateName(20);
      prevReplies.push(newBody);

      const databaseRef = ref(db, messagePath);
      update(databaseRef, {replies: prevReplies})
        .then(() => {
          const lastMsg = messageBody.message ? messageBody.message : otherMsg;
          // openChatroom();
          if (messageBody.isPrivate || allChat.isPrivate) {
            clearData();
          } else {
            if (folderName === 'chats') {
              saveLastMessage(chatId, lastMsg);
            } else {
              saveOppotunityLastMessage(chatId, lastMsg);
            }
          }
          setshowLoader(false);
          scrollToBottom();
        })
        .then(() => {
          // clearData();
        });
    }
  };

  const closeChatRoom = () => {
    var payLoad = {
      chat_room_id: chatId,
    };
    commonApi
      .close_chat_room(payLoad)
      .then(res => {
        if (res.status === 200) {
          //   props.onClose();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const openChatroom = () => {
    const database = ref(db, messagePath);
    onValue(database, snapshot => {
      if (snapshot.val() && Object.entries(snapshot.val()).length > 0) {
        setallChat(snapshot.val());
      }
    });
  };

  //   const onReplyCrossClick = () => {
  //     if (messageToReply && messageToReply.key != "") {
  //       messageToReply = {
  //         key: "",
  //         date: "",
  //         name: "",
  //         emojiId: "",
  //         mesasge: {
  //           message: "",
  //           picture_url: [],
  //           video_url: "",
  //           type: "",
  //           sent_by: "",
  //           send_date: "",
  //           isPrivate: false,
  //           privateChatData: {
  //             sent_to: "",
  //             first_name: "",
  //             last_name: "",
  //             profile_img: "",
  //           },
  //         },
  //       };
  //     }
  //   };

  const handleRemoveFile = () => {
    setOtherMsg(null);
    setSelectedImage(null);
    setVideoPreviewUrl(null);
    inputRef.current.value = null;
  };

  const handlePrivate = () => {
    setMessageBody({...messageBody, isPrivate: true});
    setPrivateUser(false);
  };

  const handleMentionUsers = item => {
    const privateUserObj = {
      sent_to: item.id,
      first_name: item.first_name,
      last_name: item.last_name,
      profile_img: item.profile_img,
    };
    setMessageBody({...messageBody, privateChatData: privateUserObj});
    // setWrittenMsg('@' + item.first_name + ' ' + item.last_name);
    setWrittenMsg('@' + fixName(item.first_name, item.last_name));
    setPrivateUser(true);
  };

  const userDetails = () => {
    const found = allChatUsers.find(objs => objs.id === allChat.sent_by);
    if (typeof found === 'object') {
      setUserData({
        id: found.id,
        // name: found.first_name + ' ' + found.last_name,
        name: fixName(found.first_name, found.last_name),
        profile_img: found.profile_img ? found.profile_img : DEFAULT_IMAGE,
      });
    }
  };

  const handlePoll = msg => {
    const newOpt = localStorage.getItem('optionNo');
    const localUser = localStorage.getItem('id').toString();
    const chating = msg.message.options;

    const data = {
      dateAndTime: msg.message.dateAndTime,
      options: msg.message.options,
      pollId: msg.message.pollId,
      question: msg.message.question,
      status: msg.message.status,
    };

    for (let x in chating) {
      if (x === newOpt) {
        if (chating[x].hasOwnProperty('userIds')) {
          if (chating[x]['userIds'].includes(localUser)) {
            chating[x]['userIds'] = chating[x]['userIds'].filter(
              item => item !== localUser,
            );
          } else {
            chating[x]['userIds'].push(localUser);
          }
        } else {
          chating[x]['userIds'] = [localUser];
        }
      } else {
        if (chating[x].hasOwnProperty('userIds')) {
          chating[x]['userIds'] = chating[x]['userIds'].filter(
            item => item !== localUser,
          );
        }
      }
    }

    data.options = chating;

    const databaseRef = ref(db, messagePath);
    update(databaseRef, {message: data})
      .then(() => {
        //
      })
      .then(() => {
        // clearData();
      });

    // if (emojiToMsg.hasOwnProperty("emojis") && emojiToMsg.emojis.length > 0) {
    //     let prevEmojis = emojiToMsg.emojis;
    //     prevEmojis.push(data);
    //     sendEmoji({emojis: prevEmojis});
    // } else {
    // sendEmoji({message: data});
    // }

    // dateAndTime: true,
    // options: "[{\"option1\":{\"option\":\"2022-12-20T13:00:04.812-05:00\",\"userIds\":[]},\"option2\":{\"option\":\"2022-12-21T07:59:04.812-05:00\",\"userIds\":[]},\"option3\":{\"option\":\"2022-12-22T12:30:04.812-05:00\",\"userIds\":[\"71\"]},\"option4\":{\"option\":\"2022-12-20T17:45:04.812-05:00\",\"userIds\":[\"545\"]},\"option5\":{\"option\":\"\",\"userIds\":[]}}]",
    // pollId: 33,
    // question: "Di Ci no I fr see Dr hi hi hi hi Dr Dr Dr F ghh",
    // status: "open"
  };

  // const allMembers = () => {
  //     let memberNames = '';

  //     // props.allChatUsers.slice(0,2).map((item,index) => {
  //         memberNames += item.first_name+' '+item.last_name;
  //         memberNames += (index < 1) ? ', ' : ' ';
  //     })

  //     return memberNames;
  // }

  // const popover = (
  //   <Popover id="popover-basic">
  //     {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
  //     <Popover.Body>
  //       And here's some <strong>amazing</strong> content. It's very engaging.
  //       right?
  //     </Popover.Body>
  //   </Popover>
  // );

  useEffect(() => {
    userDetails();
    // //
    //
    //
    //
    // if (chatRoomName && isNewChatroom) {
    openChatroom();
    // }
    setTimeout(() => {
      scrollToBottom();
    }, 100);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setItem('scrollPositionReply', '');
  }, [chatx]);

  // useEffect(()=>{
  //     // if (props.chatRoomName) {
  //         // setChatRoomName(props.chatRoomName)
  //     } else {
  //         setChatRoomName(("chatroom_" + (Math.random() + 1).toString(36).substring(3)));
  //     }
  //     // setNewChatroom(props.chatRoomName);
  // // },[props.chatRoomName]);

  // useEffect(()=>{
  //     // setchatId(props.chatRoomId);
  // // },[props.chatRoomId]);

  // useEffect(()=>{
  //     // setxChatType(props.type);
  //     // setxFbFolder((props.type.toLowerCase().indexOf("oppo") !== -1) ? "opportunities" : "chats");
  // // },[props.type]);

  // useEffect(()=>{
  //     // setxChatDetail(props.chatDetails);
  //     // // setxOtherUser((props.chatDetails.creator_id == localStorage.getItem("user")) ? "user_detail" : props.chatDetails.hasOwnProperty('creator_details') ? 'creator_details' : 'creator_detail');
  // // },[props.chatDetails]);

  useEffect(() => {
    // alert('|||||')
    sendMessage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageBodyNew]);

  useEffect(() => {
    if (
      messageBody.isPrivate &&
      (writtenMsg.length === 0 ||
        (writtenMsg.length > 0 && writtenMsg[0] !== '@'))
    ) {
      setMessageBody({
        ...messageBody,
        isPrivate: true,
        privateChatData: {},
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writtenMsg]);

  useEffect(() => {
    if (
      addSnapshot ||
      // showUsers ||
      showParticipants ||
      showSnapshotDetail ||
      showUserProfile ||
      showEmojiModal ||
      // oppoGroupChat ||
      // oppoCall ||
      showReplyEmojiModal
    ) {
      setItem('scrollPositionReply', getItem('scrollTopReply'));
    } else {
      const storedScrollPosition = getItem('scrollPositionReply');
      if (storedScrollPosition && scrollRef.current) {
        scrollRef.current.view.scrollTop = storedScrollPosition;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addSnapshot,
    // showUsers,
    showParticipants,
    showSnapshotDetail,
    showUserProfile,
    showEmojiModal,
    // oppoGroupChat,
    // oppoCall,
    showReplyEmojiModal,
  ]);
  const ShowSelectedComponent = (data, type) => {
    // alert('chala');

    setDataForComponent(data);
    setShowComponent(type);
  };

  const newDecision = () => {
    if (dataForComponent.login_user && dataForComponent.login_user.status) {
      return dataForComponent.login_user.status;
    } else if (dataForComponent.chat_detail.hasOwnProperty('status')) {
      return dataForComponent.chat_detail.status;
    } else {
      return 'pending';
    }
  };

  return (
    <>
      {/* Default view of chat */}
      {
      // !addSnapshot &&
        // !showUsers &&
        // !showParticipants &&
        // !showUserProfile &&
        // !showEmojiModal &&
        // !oppoGroupChat &&
        // !oppoCall &&
        // !showReplyEmojiModal &&
         (
          <SlidingPanel
            hidePanel={() => {
              onClose();
              // messageRead(chatId, xfbFolder);
            }}>
            <div className="messages-wrapper">
              <div
                className={`sidebar-heading-wrapper fix-width-forms mt-0 mb-0 
                  ${
                  withHeadingSpace ? 'with-panel' : ''
                }
                `
              }
                >
                <div className="headings m-0">
                  <h2 className="m-0">{userData.name}</h2>
                </div>
                <div className="with-user-img">
                  <div className="user-profile-img as-link">
                    <img
                      onClick={() => {
                        setShowUserProfile(userData.id);
                      }}
                      src={userData.profile_img}
                      alt=""
                    />
                  </div>
                  {/* <span className="close as-link" onClick={() => onClose()}>
                    <i className="fas fa-times" />
                  </span> */}
                </div>
              </div>

              {showLoader && <LoaderModal />}

              <div className="reply-box-wrapper">
                <Scrollbars
                  ref={scrollRef}
                  onScroll={handleScroll}
                  className="scrollBar">
                  <div className="events-wrapper replies-user-wrapper fix-width-forms">
                    <div className="events-img-slider">
                      {allChat.type === 'poll' && (
                        <SmartChatPoll
                          chat={allChat}
                          handlePoll={type => {
                            handleChatBoxSignal(type, allChat, '', '');
                            // handlePoll();
                            // handleSelectReply("update-poll", allChat, chat[0], item[0]);
                          }}
                          chatStatusOfOpportunity={chatEnableStatus}
                        />
                      )}

                      {allChat.type !== 'poll' && (
                        <RenderHTMLBlock
                          container="p"
                          content={allChat.message}
                        />
                      )}
                      {/* <span className="msg-date">{getTimeFormatForChat(allChat.send_date)}</span> */}

                      {allChat.type === 'image' && (
                        <div className="msg-img">
                          <img src={allChat.picture_url} alt="" />
                        </div>
                      )}

                      {allChat.type === 'snapshot' && (
                        <div className="d-flex align-items-center chatSnapshots">
                          {Object.entries(allChat.picture_url).length &&
                            allChat.picture_url
                              .slice(0, 4)
                              .map((item, index) => {
                                return (
                                  <div
                                    onClick={() => {
                                      if (index === 3) {
                                        handleChatBoxSignal(
                                          'show-snapshot-detail',
                                          allChat.picture_url,
                                        );
                                      } else {
                                        handleChatBoxSignal(
                                          'show-snapshot-detail',
                                          allChat.picture_url,
                                          '',
                                          '',
                                          item.id,
                                        );
                                      }
                                      // getSpecificAccomplishment(item.id);
                                    }}
                                    key={index}
                                    className="msg-img as-link snapShotsImg">
                                    <img src={item.images[0]} alt="" />
                                    {allChat.picture_url.length > 4 &&
                                      index === 3 && (
                                        <span
                                          className="overlay"
                                          onClick={() => {
                                            handleChatBoxSignal(
                                              'show-snapshot-detail',
                                              allChat.picture_url,
                                            );
                                            // getSpecificAccomplishment(item.id);
                                          }}>
                                          {`+${allChat.picture_url.length - 4}`}
                                        </span>
                                      )}
                                  </div>
                                );
                              })}
                        </div>
                      )}

                      {allChat.document_url && (
                        <span className="action-icon file-icon">
                          <a
                            href={allChat.document_url}
                            target="_blank"
                            style={{color: 'white'}}
                            download
                            rel="noreferrer">
                            <i className="fas fa-file-lines" />
                          </a>
                        </span>
                      )}

                      {allChat.video_url && (
                        <div className="msg-img">
                          <video
                            src={allChat.video_url}
                            playsInline
                            autoPlay
                            controls
                          />
                        </div>
                      )}
                    </div>

                    <div className="msg-reactions">
                      <span
                        className="replies-count as-link"
                        onClick={() => {
                          handleChatBoxSignal('add-emoji', allChat, '', '');
                        }}>
                        <i className="fa-regular fa-face-smile"></i>
                      </span>

                      <div className="message-other-users">
                        {allChat.hasOwnProperty('emojis') &&
                          allChat.emojis.length > 0 && (
                            <div
                              className="users-imgs as-link"
                              onClick={() => {
                                handleChatBoxSignal(
                                  'show-emojis',
                                  allChat.emojis,
                                );
                              }}>
                              {allChat.emojis
                                .slice(-3)
                                // .reverse()
                                .map((emojiObj, index) => (
                                  <img
                                    src={
                                      S3_EMOJI_DEV +
                                      emojiObj.skinTone +
                                      '/' +
                                      emojiObj.emojiName +
                                      '.svg'
                                    }
                                    key={`${emojiObj.emojiName}-${index}`}
                                    alt=""
                                  />
                                ))}
                              <span className="reaction-count">
                                {allChat.emojis.length > 3
                                  ? `${allChat.emojis.length - 3}+`
                                  : ''}
                              </span>
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="events-headings">
                      {/* <h2>Snapshot Title</h2> */}
                      <div className="d-flex align-items-center justify-content-between">
                        {allChat.replies && (
                          <span className="category-name">
                            {getChatCount(
                              allChat.replies,
                              localStorage.getItem('id'),
                            )}
                            {getChatCount(
                              allChat.replies,
                              localStorage.getItem('id'),
                            ) === 1
                              ? ' Reply'
                              : ' Replies'}
                          </span>
                        )}
                        {/* <span className="category-name">
                              <i className="fa-solid fa-ellipsis"></i>
                            </span> */}
                      </div>
                    </div>

                    {Object.entries(allChat.replies).length > 0 &&
                      allChat.replies.map((msg, i) => {
                        const found = allChatUsers.find(
                          objs => parseInt(objs.id) === parseInt(msg.sent_by),
                        );
                        return (
                          // sir Awais Work
                          // (allChat.sent_by == localStorage.getItem('user') ||
                          // !msg.isPrivate ||
                          // (msg.isPrivate &&
                          //   msg.sent_by == localStorage.getItem('user'))) &&
                          ((msg.isPrivate &&
                            msg.privateChatData.sent_to ===
                              localStorage.getItem('user')) ||
                            msg.sent_by === localStorage.getItem('user') ||
                            !msg.isPrivate) && (
                            <ReplyBox
                              key={`noice-${i}`}
                              msg={msg}
                              i={i}
                              found={found}
                              setShowUserProfile={setShowUserProfile}
                              handleChatBoxSignal={handleChatBoxSignal}
                              allChat={allChat}
                              setReplyIndex={setReplyIndex}
                              toggleReplyOptions={toggleReplyOptions}
                              ShowSelectedComponent={ShowSelectedComponent}
                              handleOpenChatRoom={handleOpenChatRoom}
                            />
                          )
                        );
                      })}
                    <div ref={messagesEndRef}></div>
                  </div>
                </Scrollbars>
              </div>

              {/* Send Message */}

              {
                /*chatStatusIn.includes(chatRoomStatus)*/ true && (
                  <div className="message-form fix-width-forms" style={{paddingBottom:"15px"}}>
                    <input
                      style={{display: 'none'}}
                      ref={inputRef}
                      type="file"
                      name="myImage"
                      onChange={event => {
                        const file = event.target.files[0];
                        if (file && file.size > 10485760) {
                          errorMessage('File size should not exceed 10MB');
                          event.target.value = null;
                        } else {
                          makeFile(file);
                          event.target.value = null;
                        }
                      }}
                    />
                    {otherMsg === 'Image' && selectedImage ? (
                      <span className="msg-img">
                        <img
                          src={URL.createObjectURL(selectedImage)}
                          alt=""
                          onClick={() => {
                            showLightbox();
                          }}
                          style={{width: '50px'}}
                        />
                        <i
                          className="fas fa-times"
                          onClick={handleRemoveFile}
                        />
                      </span>
                    ) : otherMsg === 'Video' && videoPreviewUrl ? (
                      <span className="msg-img">
                        <video style={{width: '50px'}} src={videoPreviewUrl} />
                        <i
                          className="fas fa-times"
                          onClick={handleRemoveFile}
                        />
                      </span>
                    ) : (
                      <span
                        onClick={() => toggleCameraOptions(!cameraOptions)}
                        className="form-icon text-yellow">
                        <i className="fas fa-ellipsis-vertical" />
                      </span>
                      // <DropdownButton
                      //   // className="form-icon"
                      //   // align="up"
                      //   title={<i className="fas fa-ellipsis-v" />}
                      //   id="dropdown-menu-align-end"
                      //   onSelect={handleSelect}>
                      //   <Dropdown.Item as={'span'} eventKey="1">
                      //     Camera
                      //   </Dropdown.Item>
                      //   <Dropdown.Item as={'span'}   eventKey="2">
                      //     Media
                      //   </Dropdown.Item>
                      //   <Dropdown.Item as={'span'} eventKey="3">
                      //     Snapshot
                      //   </Dropdown.Item>
                      // </DropdownButton>
                    )}
                    <Form.Group className="form-input">
                      <TextareaAutosize
                        autoFocus
                        maxRows={4}
                        minRows={1}
                        className="form-control"
                        name="message"
                        value={writtenMsg}
                        onKeyDown={e => {
                          if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            saveMessage();
                          }
                        }}
                        onChange={e => {
                          setMessageBody({
                            ...messageBody,
                            message: e.target.value,
                          });
                          setWrittenMsg(e.target.value);
                        }}
                        placeholder="Type your message here"
                      />
                      {/* <Form.Control
                        autoComplete="off"
                        as="textarea"
                        rows={1}
                        name="message"
                        value={writtenMsg}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            saveMessage();
                          }
                        }}
                        onChange={e => {
                          setMessageBody({
                            ...messageBody,
                            ['message']: e.target.value,
                          });
                          setWrittenMsg(e.target.value);
                        }}
                        placeholder="Type your message here"
                      /> */}
                    </Form.Group>
                    <span
                      onClick={() => {
                        saveMessage();
                      }}
                      className="form-icon msg_send_btn send-btn">
                      <i className="icon-plane" />
                    </span>
                  </div>
                )
              }
            </div>
          </SlidingPanel>
        )}

      {/* When user add snapshot show snapshot list view */}
      {addSnapshot && (
        <SnapshotList
          show={{sendButton: true}}
          userData={JSON.parse(localStorage.getItem('allUserData'))}
          snapshotIds={snapshotIds}
          setSnapshotIds={setSnapshotIds}
          setSnapshotImages={setSnapshotImages}
          onClose={() => {
            setAddSnapshot(false);
          }}
          onSend={() => {
            sendSnapshot();
          }}
        />
      )}

      {/* When user user open snapshot in chat snapshot detail view */}
      {showSnapshotDetail && snapshotMultiIds.length > 0 && (
        <SnapshotDetail
          // withHeadingSpace={
          //   props.withHeadingSpace || props.windowWidth <= 1295 ? true : false
          // }
          showCurentClickSnapshotId={snapshotSingleId}
          snapshotId={snapshotData}
          multipleSnapShotId={snapshotMultiIds}
          userData={userData}
          showFromMessages={true}
          onClose={() => {
            setSnapshotMultiIds([]);
            setSnapshotData({});
            setShowSnapshotDetail(false);
          }}
          // showRefer={true}
        />
      )}
      {showSnapshotDetail &&
        snapshotData &&
        snapshotData.hasOwnProperty('images') &&
        userData.hasOwnProperty('first_name') && (
          <SnapshotDetail
            // withHeadingSpace={
            //   props.withHeadingSpace || props.windowWidth <= 1295 ? true : false
            // }
            snapshotId={snapshotData}
            multipleSnapShotId={false}
            userData={userData}
            showFromMessages={true}
            onClose={() => {
              setSnapshotMultiIds([]);
              setSnapshotData({});
              setShowSnapshotDetail(false);
            }}
            // showRefer={true}
          />
        )}

      {/* Show group users in modal */}
      {/* {showUsers && <ListUsers allUsers={props.allChatUsers} onClose={() => {setShowUsers(false)}} type={true} />} */}

      {/* Show poll participants in modal */}
      {showParticipants && (
        <ListUsers
          allUsers={pollParticipants}
          onClose={() => {
            setShowParticipants(false);
          }}
          type={false}
        />
      )}

      {/* Show user profile */}
      {showUserProfile && (
        <ProfileComponent
          userId={showUserProfile}
          show={false}
          closeModal={() => {
            setShowUserProfile('');
          }}
          onClose={() => {
            setShowUserProfile('');
          }}
          userData={[]}
          moderator={false}
          VoxeetSDK={[]}
          conferenceInfo={[]}
          jdSpecialM={[]}
          jdSpecialA={[]}
        />
      )}

      {/* {oppoCall && <OpportunityCall />} */}

      {/* {oppoGroupChat &&
        <CreateGroup groupChat={xchatDetail} onClose={() => { setOppoGroupChat(false); }} chatGroupType={"opportunity"} />
    } */}

      {showEmojiModal && (
        <EmojiModal
          onClose={() => setshowEmojiModal(false)}
          sendEmoji={icon => {
            handleAddEmoji(icon);
          }}
        />
      )}
      {showEmojiModal === 'add-emoji' && (
        <EmojiModal
          onClose={() => setshowEmojiModal(false)}
          sendEmoji={icon => {
            sendMessagesEmoji(icon);
          }}
        />
      )}

      {showReplyEmojiModal && (
        <EmojiModal
          onClose={() => setShowReplyEmojiModal(false)}
          sendEmoji={icon => {
            handleAddReplyEmoji(icon);
          }}
        />
      )}

      {/* Custom Modal open when someone clicks on camera icon */}
      {cameraOptions && (
        <CustomModal
          position="bottom"
          onClose={() => toggleCameraOptions(!cameraOptions)}>
          <div className="camera-options-list w-100 chatActionSheet">
            <ul>
              <li className="as-link emojies-list">
                {emojisAndSnapshots &&
                  emojisAndSnapshots.emojis &&
                  emojisAndSnapshots.emojis.map((element, index) => (
                    <span className="add-emoji as-link" key={index}>
                      <img
                        onClick={() => {
                          sendMessagesEmoji(
                            S3_EMOJI_DEV +
                              element.skinTone +
                              '/' +
                              element.emojiName,
                          );
                          toggleCameraOptions(!cameraOptions);
                        }}
                        src={
                          S3_EMOJI_DEV +
                          element.skinTone +
                          '/' +
                          element.emojiName +
                          '.svg'
                        }
                        className=""
                        alt=""
                      />
                    </span>
                  ))}
                <span
                  className="add-emoji as-link"
                  onClick={() => {
                    toggleCameraOptions(!cameraOptions);
                    ShowEmojModel();
                  }}>
                  {/* <i className="fas fa-smile" />{' '} */}
                  <img
                    src={require('../../Assets/images/like-emojie.png')}
                    className=""
                    alt=""
                  />
                </span>
              </li>
              <li
                className="as-link snapshot-list"
                onClick={() => {
                  toggleCameraOptions(false);
                  handleSelect('3');
                }}>
                <i className="icon-snapshot" />{' '}
                {labels.SHARE_SNAPSHOTS[selected_lang]}
              </li>
              <li className="snapshot-list-data">
                {emojisAndSnapshots &&
                emojisAndSnapshots.snapshots &&
                emojisAndSnapshots.snapshots.length > 0
                  ? emojisAndSnapshots &&
                    emojisAndSnapshots.snapshots &&
                    emojisAndSnapshots.snapshots.map((element, index) => (
                      <div className="snapshots as-link" key={index}>
                        <img
                          src={element.images[0]}
                          alt="snaphots"
                          onClick={() => {
                            toggleCameraOptions(!cameraOptions);
                            sendSnapshotFromModel(element);
                          }}
                        />
                      </div>
                    ))
                  : snapShotImagesLast3.length > 0 &&
                    snapShotImagesLast3.slice(0, 3).map((element, index) => (
                      <div className="snapshots as-link" key={index}>
                        <img
                          src={element.images[0]}
                          alt="snaphots"
                          onClick={() => {
                            toggleCameraOptions(!cameraOptions);
                            sendSnapshotFromModel(element);
                          }}
                        />
                      </div>
                    ))}
                {/* <div className="snapshots as-link">
                  <img
                    src={require('../../Assets/images/about-bg-img.png')}
                    alt="snaphots"
                  />
                </div>
                <div className="snapshots as-link">
                  <img
                    src={require('../../Assets/images/about-bg-img.png')}
                    alt="snaphots"
                  />
                </div>
                <div className="snapshots as-link">
                  <img
                    src={require('../../Assets/images/about-bg-img.png')}
                    alt="snaphots"
                  />
                </div> */}
                <div
                  className="snapshots as-link search"
                  onClick={() => {
                    toggleCameraOptions(false);
                    handleSelect('3');
                  }}>
                  <i class="fa-solid fa-magnifying-glass"></i>
                </div>
              </li>

              <li
                className="as-link shareables-list share-camera-list-holder"
                onClick={() => {
                  toggleCameraOptions(!cameraOptions);
                  setShowComponent('shareAbles');
                }}>
                <img src={AddShareableIcon} className="icon-camera" alt="" />{' '}
                {labels.ADD_SHAREABLES[selected_lang]}
              </li>
              <li
                className="as-link image-list"
                onClick={() => {
                  toggleCameraOptions(false);
                  // handleSelect('1');
                  toggleImgOptions(!imgOptions)
                }}>
                <i className="icon-camera" /> {labels.ADD_IMAGE[selected_lang]}
              </li>
              <li
                className="as-link video-list"
                onClick={() => {
                  toggleCameraOptions(false);
                  handleSelect('2');
                }}>
                <i class="fa-solid fa-video" />{' '}
                {labels.ADD_VIDEO[selected_lang]}
              </li>
            </ul>
          </div>
          {/* <div className="camera-options-list w-100 chat-camera-sheet">
            <ul>
              <li
                className="as-link"
                onClick={() => {
                  toggleCameraOptions(false);
                  handleSelect('1');
                }}>
                <i className="icon-camera" />
                <p className="p-reg mb-0"> Camera</p>
              </li>
              <li
                className="as-link"
                onClick={() => {
                  toggleCameraOptions(false);
                  handleSelect('2');
                }}>
                <i className="icon-gallery-img" />
                <p className="p-reg mb-0">Media</p>
              </li>
              <li
                className="as-link"
                onClick={() => {
                  toggleCameraOptions(false);
                  handleSelect('3');
                }}>
                <i className="icon-snapshot" />
                <p className="p-reg mb-0">Snapshot</p>
              </li>
            </ul>
          </div> */}
        </CustomModal>
      )}

{imgOptions && (
        <CustomModal
          position="bottom"
          onClose={() => toggleImgOptions(!imgOptions)}>
          <div className="camera-options-list w-100 chatActionSheet">
            <ul>
             
              
              

              
              <li
                className="as-link image-list"
                onClick={() => {
                  toggleImgOptions(false)
                  startWebCam();
                }}>
            <i className="icon-camera" />   Use Camera
              </li>
              <li
                className="as-link image-list image_input"
                onClick={() => {
                  // toggleCameraOptions(false);
                  // handleSelect('1');
                }}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={e => changeTicketImg(e)}
                />
                 <i className="fas fa-image" /> <span>Browse</span>
              </li>
            </ul>
          </div>
        </CustomModal>
      )}

      {replyOptions && (
        <CustomModal
          position="bottom"
          onClose={() => toggleReplyOptions(!replyOptions)}>
          <div className="camera-options-list w-100">
            <ul>
              {
                // marksBest &&
                chatDetails &&
                  chatDetails.type === 'question' &&
                  chatDetails.creator_id === localStorage.getItem('user') &&
                  allChat &&
                  // Object.entries(allChat).length > 0 &&
                  allChat.type !== 'poll' &&
                  !allChat.isPrivate &&
                  allChat.replies.findIndex(
                    obj => obj.isCorrect || obj.isPrivate,
                  ) !== replyIndex && (
                    <li
                      className="as-link"
                      onClick={() => {
                        toggleReplyOptions(false);
                        handleReplyMarkAsBest();
                      }}
                      // onClick={() => {
                      //   // handleReplyOptions(replyOptionObj);
                      //   handleReplyOptions(
                      //     'best-answer',
                      //     replyOptionObj.signalData,
                      //     replyOptionObj.childKey,
                      //     replyOptionObj.parentKey,
                      //   );
                      // }}
                    >
                      <i className="fa-solid fa-circle-check" />
                      <p className="p-reg mb-0">Mark as best answer</p>
                    </li>
                  )
              }
              <li
                className="as-link"
                onClick={() => {
                  toggleReplyOptions(false);
                  handleChatBoxSignal('add-reply-emoji', allChat, '', '');
                }}>
                <i className="fas fa-smile" />
                <p className="p-reg mb-0">Emoji</p>
              </li>
            </ul>
          </div>
        </CustomModal>
      )}

      {/* Modal to show all users with reaction on a message */}
      {showEmojis && (
        <UsersReactionModal
          emojiList={emojiList}
          allUsers={allChatUsers}
          setUserId={id => {
            setShowUserProfile(id);
          }}
          onClose={() => {
            setShowEmojis(false);
          }}
        />
      )}

      <Modal
        show={modalEndChat}
        onHide={() => {
          setModalEndChat(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>End Chat</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to end the chat ?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              closeChatRoom();
              setModalEndChat(false);
            }}>
            Yes
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setModalEndChat(false);
            }}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showPrivate}
        onHide={() => {
          setPrivateUser(false);
        }}>
        <Modal.Body>
          {
            <div>
              <img
                src={
                  messageBody.privateChatData.profile_img
                    ? messageBody.privateChatData.profile_img
                    : DEFAULT_IMAGE
                }
                alt=""
                style={{width: '50px', borderRadius: '30px'}}
              />
              <>
                {messageBody.privateChatData.first_name +
                  ' ' +
                  messageBody.privateChatData.last_name}
              </>
              <span style={{float: 'right'}}>
                <Button
                  onClick={() => {
                    handlePrivate();
                  }}>
                  Make Private
                </Button>
              </span>
            </div>
          }
        </Modal.Body>
      </Modal>

      {webcamStream &&
        webcamStream.getTracks() &&
        webcamStream.getTracks().length > 0 && (
          <CustomModal
            size="medium"
            onClose={() => {
              webcamStream.getTracks().forEach(track => track.stop());
              setWebcamStream(null);
              setImageSrc(null);
            }}>
            <div className="capture-with-camera-wrapper">
              <div className="capture-with-camera-imgs">
                <div className="video">
                  <video
                    ref={webcamRef}
                    autoPlay={true}
                    stream={webcamStream}
                  />
                </div>
                {imageSrc && (
                  <div className="video-img">
                    <img src={imageSrc} alt="captured" />
                  </div>
                )}
              </div>
              <div className='text-center' style={{
                marginTop:'10px'
              }}>
              <button className="btn btn-dark mt-3" onClick={captureImage}>
                {imageSrc ? 'Retake Photo' : 'Take Photo'}
              </button>
              </div>
            </div>
          </CustomModal>
        )}

      {showComponent === 'shareAbles' && (
        <MessagesShareAbles
          onClose={() => {
            setShowComponent('');
          }}
          sendAnyThink={sendAnyThink}
          workSpaceItem={workSpaceItem}
          setShowComponent={setShowComponent}

          // workspaceFilter={workSpaceIds.toString(',')}
        />
      )}
      {showComponent === 'accomplishment' && (
        <SnapshotDetail
          snapshotId={dataForComponent}
          userData={dataForComponent}
          onClose={e => {
            // setShowDetail(false);

            setShowComponent('');
          }}
          showRefer={false}
        />
      )}

      {show && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShow(false);
          }}>
          <div className="chat-img-preview">
            {popupImages[0].image && <img src={popupImages[0].image} alt="" />}
            {popupImages[0].video && (
              <video
                className="reply-modal-video"
                src={popupImages[0].video}
                style={{width: '476px'}}
                playsInline
                autoPlay
                controls
              />
            )}
            {/* <img src={popupImages[0].image} alt="" /> */}
          </div>
        </CustomModal>
      )}
      {showComponent === 'event' &&
        (dataForComponent.creator_id === localStorage.getItem('id') ||
          dataForComponent.moderator_ids.includes(
            localStorage.getItem('id'),
          )) && (
          <EventDetail
            onClose={() => {
              setShowComponent('');
            }}
            type={'Messages'}
            // getApiSessionList={getApiSessionList}
            // getApiSessionList(sessionType, "", !typeIs, pageNo);
            // sessionType={sessionType}
            // typeIs={typeIs}
            item={dataForComponent}
          />
        )}

      {showComponent === 'event' &&
        dataForComponent.creator_id !== localStorage.getItem('id') &&
        !dataForComponent.moderator_ids.includes(
          localStorage.getItem('id'),
        ) && (
          <PurchaseTicket
            onClose={() => {
              setShowComponent('');
            }}
            item={dataForComponent}
            type={'Messages'}
            // getApiSessionList={getApiSessionList}
            // sessionType={sessionType}
            // typeIs={typeIs}
            // item={userData}
          />
        )}

      {showComponent === 'session' &&
        dataForComponent.creator_id !== localStorage.getItem('id') && (
          // <SlidingPanel
          //   hidePanel={() => {
          //     setShowComponent('');
          //   }}>
          //   <Scrollbars>
          <SessionDetail
            item={dataForComponent}
            onClose={() => setShowComponent('')}
          />
          //   </Scrollbars>
          // </SlidingPanel>
        )}
      {showComponent === 'session' &&
        dataForComponent.creator_id === localStorage.getItem('id') && (
          // <SlidingPanel
          //   hidePanel={() => {
          //     setShowComponent('');
          //   }}>
          //   <div className="content-sidebar">
          <CreateSession
            onClose={() => {
              setShowComponent('');
            }}
            editSession={dataForComponent}
            type={true}
            // getApiSessionList={getApiSessionList}
            // getApiSessionList(sessionType, "", !typeIs, pageNo);
            // sessionType={sessionType}
            // typeIs={typeIs}
            item={dataForComponent}
          />
          //   </div>
          // </SlidingPanel>
        )}

      {showComponent === 'appointment' &&
        dataForComponent.creator_details &&
        dataForComponent.creator_details.id === localStorage.getItem('id') && (
          //  <div className="content-sidebar">
          <UserAppointments
            onClose={() => {
              setShowComponent('');
            }}
            //  userData={dataForComponent}
          />
          //  </div>
        )}
      {showComponent === 'appointment' &&
        dataForComponent.creator_details &&
        dataForComponent.creator_details.id !== localStorage.getItem('id') && (
          //  <div className="content-sidebar">
          <AppointmentList
            setShowComponent={setShowComponent}
            userData={dataForComponent.creator_details}
          />
          //  </div>
        )}
      {showComponent === 'opportunity' &&
        (dataForComponent.created_by === localStorage.getItem('id') ||
        (dataForComponent.team_member_ids &&
          dataForComponent.team_member_ids
            .split(',')
            .includes(localStorage.getItem('id'))) ? (
          <OpportunityMessageList
            item={dataForComponent}
            // setOppoDetails={setOppoDetails}
            // setAllChatUsers={setAllChatUsers}
            // setChatRoomId={setChatRoomId}
            // setChatRoomName={setChatRoomName}
            // setChatRoomStatus={setChatRoomStatus}
            onClose={() => {
              setShowComponent('');
            }}
            onEditOppo={() => {
              setShowComponent('fromOppoMsg'); // temparary majbori
              // setInviteUser(true);
              // setCreateSessionEvent(false);
            }}
            onCreateSessionEvent={() => {
              // setCreateSessionEvent(true);
              // setInviteUser(false);
              setShowComponent('');
            }}
            onShowChat={() => {
              setShowComponent('show-chats');
            }}
          />
        ) : dataForComponent.chat_detail &&
          Object.entries(dataForComponent.chat_detail).length > 0 ? (
          <SmartChatPage
            allmsg={true}
            item={[]}
            type={'other-opportunity'}
            chatDetails={dataForComponent.chat_detail}
            oppoDetails={dataForComponent}
            chatroomInfo={chatroomInfoOppo(dataForComponent, 'opportunity')}
            allChatUsers={collectingOppoUsers(dataForComponent)}
            chatRoomName={dataForComponent.chat_detail.chat_room_name}
            chatRoomId={dataForComponent.chat_detail.id}
            chatRoomStatus={newDecision}
            onClose={() => {
              setShowComponent('');
            }}
          />
        ) : (
          <OpportunityDetail
            item={dataForComponent}
            // userData={userInfo}
            onClose={() => {
              setShowComponent('');
            }}
          />
        ))}

      {showComponent === 'chat-component' && (
        <SmartChatPage
          allmsg={true}
          type={chatPropTypeFromMessages}
          item={chatDetailItemFromMessages}
          chatroomInfo={chatroomInfo(chatDetailsFromMessages)}
          oppoDetails={chatDetailsFromMessages}
          chatDetails={chatDetailsFromMessages}
          allChatUsers={allChatUsersFromMessages}
          chatRoomName={chatRoomNameFromMessages}
          chatRoomId={chatRoomIdFromMessages}
          // workSpaceItem={workspaceData}
          // chatRoomStatus={chatDetails.status}
          onClose={() => {
            setShowComponent('');
          }}
          // handleCommonChat={(data, type) => {
          //   handleCommonChat(data, type);
          // }}
        />
      )}

      {showComponent === 'network' && dataForComponent && (
        <div className="pb-4 fix-width-forms">
          <ProfileComponent
            userId={dataForComponent.id}
            show={false}
            onClose={() => {
              setShowComponent('');
            }}
            // hideCloseButton={true}
            button={''}
            userData={[]}
            moderator={false}
            // setParticipantType={setParticipantType}
            VoxeetSDK={[]}
            conferenceInfo={[]}
            jdSpecialM={[]}
            jdSpecialA={[]}
          />
        </div>
      )}

      {/* {show && (
        <ImageLightbox
          show={show}
          current={current}
          images={popupImages}
          onClose={() => {
            setShow(false);
            // setCurrent(0);
          }}
        />
      )} */}
    </>
  );
};

export default SmartReplyList;
