import React, {useState, useEffect,useImperativeHandle} from 'react';
import {DEFAULT_IMAGE, selected_lang} from '../../Utils/env';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {FormattedText} from '../../Utils/Helpers';

// import SnapshotDetail from './SnapshotDetail';
import {ShareYellowIcon} from '../../Constants/Images';

import {labels} from '../../Constants/Translations';
import {Button, Form} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import commonApi from '../../Services/CommonService';
import moment from 'moment';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import ReferOpportunity from '../Referral/ReferOpportunity';
import CustomModal from '../CustomModal';
import ShareIconSvg from '../../Svg/ShareIconSvg';
import {
  allowedImageTypes,
  allowedVideoTypes,
  fixName,
  formatDateTimeTodayPre,
} from '../../Utils/commonUtils';
import {TagsInput} from 'react-tag-input-component';
import CropImage from '../Cropper/CropImage';
import CustomDropdown from '../CustomDropdown';
const MessagesNotes = props => {
  const [showLoader, setshowLoader] = useState(true);
  const [showComponent, setShowComponent] = useState('');
  const [showModel, setShowModel] = useState('');
  const [tabType, setTabType] = useState('notes');
  const [notesData, setNotesData] = useState([]);
  const [writtenMsg, setWrittenMsg] = useState('');
  const [referitem, setReferitem] = useState('');
  const [searchQry, setSearchQry] = useState('');
  const [searchFilter, setSearchFilterModal] = useState(false);
  const [checkFilterOption, setCheckFilterOption] = useState(['all']);
  const [filterOptionValue, setFilterOptionValue] = useState(['all']);
  const [showSendNotes, setShowSendNotes] = useState(true);
  const [showAllUser, setShowAllUser] = useState([]);
  // const [showMore, setShowMore] = useState(false);
  // const showMoreClass = showMore ? 'show-all' : '';

  const [ticketImg, setTicketImg] = useState('');
  const [ticketVideo, setTicketVideo] = useState('');

  const [sliderImage, setSliderImage] = useState([]);
  // const [showModel, setShowModel] = useState('');

  const [file, setFile] = useState(null);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [relationid, setRelationid] = useState('1');
  const [stageid, setStageid] = useState('1');
  const [selected, setSelected] = useState([]);
  const [selectType, setSelectType] = useState('');
  const [slectedNote, setSlectedNote] = useState('');

  const RelationshipArray = [
    {id: "1", title: 'Friend'},
    {id: '2', title: 'Family'},
    {id: '3', title: 'Colleague'},
    // Add more relationships as needed
  ];

  const StageArray = [
    {id: "1", title: 'Prospect'},
    {id: '2', title: 'Lead'},
    {id: '3', title: 'Customer'},
    // Add more stages as needed
  ];

  useEffect(() => {
    get_notes();
    if (!showLoader) {
      setshowLoader(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showComponent, tabType,props.filterOptionValue]);

  const filterOption = [
    {id: 1, title: 'Me'},
    {id: 2, title: 'Other'},
    // {id: 3, title: 'Expire'},
  ];

  const get_notes = q => {
    let data = {
      type: props.chatType === 'opportunity' ? 'opportunity_chat' : 'chat',
      type_id: props.chatId,
      data_type: tabType === 'shared' ? 'shared' : 'all',
      filter: props.filterOptionValue.toString(','),
      search_query: q,
    };
    commonApi
      .get_notes(data)
      .then(res => {
        if (res.status) {
          // successMessage(res.message)
          setNotesData(res.notes);
          setTimeout(() => {
            setshowLoader(false);
          }, 1000);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const create_notes = () => {
    let data = {
      type: props.chatType === 'opportunity' ? 'opportunity_chat' : 'chat',
      associated_id: props.chatId,
      description: writtenMsg,
      image: props.ticketImg,
      video: props.ticketVideo,
    };
    commonApi
      .create_notes(data)
      .then(res => {
        if (res.status) {
          setWrittenMsg('');
          props.setTicketImg('');
          props.setTicketVideo('');
          successMessage(res.message);
          get_notes();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const hanldeComponent = (item, type) => {
    setReferitem(item);
    setShowComponent(type);
  };
  const handleSearchQry = q => {
    get_notes(q);
  };

  const handleClickList = (id, value) => {
    // const {id, value} = e.target

    setCheckFilterOption([id]);
    setFilterOptionValue([value]);
  };

  const handleSelectAll = id => {
    setCheckFilterOption([id]);
    setFilterOptionValue([id]);
  };

  const allNotesLines =
    Object.entries(notesData).length > 0
      ? Object.entries(notesData).map(([key, item]) =>
          item.description.split('\n'),
        )
      : [];

  const NoteItem = ({index, item, allNotesLines}) => {
    const [showMore, setShowMore] = useState(false);
    const showMoreClass = showMore ? 'show-all' : '';

    return (
      <div>
        <FormattedText
          className={`profile-description message-adjust-profile-desc${showMoreClass}`}
          content={item.description}
        />

        {((item.description && item.description.length > 250) ||
          allNotesLines[index].length > 3) && (
          <>
            {' '}
            <span
              className="show-more-link"
              onClick={() => setShowMore(!showMore)}>
              {!showMore
                ? labels.read_more[selected_lang]
                : labels.read_less[selected_lang]}
            </span>
          </>
        )}
      </div>
    );
  };

  const changeTicketImg = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    // If no image selected, return
    // || fileExtension === "gif"
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      props.setFile(file);
      // setCropImg(true);
      setSelectType('img');
      props.setShowModel('crop_modal');

      e.target.value = null;

      // const filePath = e.target.files[0];
      // const objectUrl = URL.createObjectURL(filePath);
      // let reader = new FileReader();
      // reader.readAsDataURL(filePath);
      // reader.onload = e => {

      //   uploadMedia(e.target.result);
      // };

      // setTicketImg(objectUrl);
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null; // reset the file input
    }
  };
  const changeTicketVideo = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    // If no video selected, return
    if (!/^video\//.test(file.type)) {
      errorMessage('File is not a video.');
      return false;
    } else if (allowedVideoTypes.includes(file.type)) {
      // Convert the file to a base64 string
      setSelectType('video');
      props.setShowModel('');
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result;
        uploadNotesImages(base64String); // Replace with the appropriate upload function for videos
      };
      reader.onerror = error => {
        errorMessage('Error reading video file.');
        console.log('Error: ', error);
      };

      // setSelectType('video');
      e.target.value = null;
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null; // reset the file input
    }
  };
  const deleteUpdatedImage = img => {
    var payload = {image: img};

    commonApi
      .delete_offering_image(payload)
      .then(res => {
        if (res.status === 200) {
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const uploadNotesImages = base64String => {
    setshowLoader(true);
    var bodyFormData = new FormData();
    bodyFormData.append(
      'media',
      base64String.replace(/^data:video\/[a-z]+;base64,/, ''),
    );
    bodyFormData.append('type', 'video');

    commonApi
      .upload_note_media(bodyFormData)
      .then(res => {
        if (res.status === 200) {
          props.setTicketVideo(res.file_url);
        }
        setTimeout(() => {
          setshowLoader(false);
        }, 1000);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const handleSearchReset = () => {
    if (props.serachForNotesAction) {
      props.setSerachForNotesAction('');
    }
  };
  useEffect(() => {
    if (props.serachForNotesAction || props.serachForNotesAction === '') {
      handleSearchQry(props.serachForNotesAction);
    }
  }, [props.serachForNotesAction]);


  useImperativeHandle(props.messagesNoteRef, () => ({
    changeTicketImage(e) {
    changeTicketImg(e)
    },

    changeTicketvideo(e) {
      changeTicketVideo(e)
      },

      getNotes() {
        get_notes();
        },

    

    
   
  }));

  console.log(props.filterOptionValue,'props.filterOptionValue')
  return (
    <div className="page-content-wrapper profileNotesSheet MessagesNoteSheet ">
      <div className="serach-filter-wrapper w-100">
        <div className="">
          <div className="messages-header mb-3 messages-note-head-holder">
            {/* <div className="event-tabs dark-tabs"> */}
            <div className="event-tabs tabs-style-2 mb-0">
              {' '}
              {/* Merging */}
              <ul className="LabelTabs">
                <li>
                  <Button
                    className={tabType === 'notes' ? 'active' : ''}
                    onClick={() => {
                      setTabType('notes');
                      handleSearchReset();
                    }}>
                    {labels.NOTES[selected_lang]}
                  </Button>
                </li>

                <li className="ml-20 d-flex align-items-center">
                  <Button
                    className={tabType === 'shared' ? 'active' : ''}
                    onClick={() => {
                      setTabType('shared');
                      handleSearchReset();
                    }}>
                    {labels.SHARED[selected_lang]}
                  </Button>
                </li>
                <li>
                  <Button
                    className={tabType === 'fields' ? 'active' : ''}
                    onClick={() => {
                      setTabType('fields');
                      handleSearchReset();
                    }}>
                    {labels.FIELDS[selected_lang]}
                  </Button>
                </li>
                <li>
                  <Button className="custom-filter-icon-holder">
                    <i
                      className="icon-filter-icon"
                      onClick={() =>
                         props.handleChildComponent('','notes_filter')
                        // setSearchFilterModal(true)
                        }
                    />
                  </Button>
                </li>
              </ul>
              {/* {props.threeDots && (
          <div className="person-search-wrapper mb-0 messages-users-wrapper">
            <div className="search-form d-flex">
             
              <Button>
                <i
                  className="icon-filter-icon"
                  onClick={() => setSearchFilterModal(true)}
                />
              </Button>
            </div>
          </div>
        )} */}
            </div>
            {/* <div className="search-tabs-icons">
              <span className={'icon'}>
                <i className="icon-plus" />
              </span>
            </div> */}
          </div>
        </div>
        {/* {props.threeDots && (
          <div className="person-search-wrapper mb-0 messages-users-wrapper">
            <div className="search-form d-flex">
              <Form.Group className="search-input">
                <Form.Control
                  type="text"
                  placeholder="Search for notes"
                  value={searchQry}
                  onChange={e => {
                    setSearchQry(e.target.value);
                    handleSearchQry(e.target.value);
                  }}
                />
              </Form.Group>
             
            </div>
          </div>
        )} */}
        <div className="refferal-list-wrapper">
          {showLoader && <LoaderSpinner />}
          <Scrollbars>
            <div className="">
              {/* {showLoader && <LoaderSpinner />} */}

              {tabType === 'notes' && (
                <div className="mt-4">
                  <div className="mt-4">
                    {/* <div className="message-day-saparator">
                    Today February 2, 2024
                  </div> */}
                    {notesData && Object.entries(notesData).length ? (
                      notesData.map((item, index) => {
                        return (
                          <div className="card-container messages-card-note-container">
                            <div
                              className="padd-with-borderContaier as-link message-adjust-img-container"
                              onClick={() => {
                                if (item.image || item.video) {
                                  props.handleMessageNotes('previewImgVideo',item)
                                  // setSlectedNote(item);
                                  // setShowModel('previewImgVideo');
                                }
                              }}>
                              {item.image && <img src={item.image} alt="" />}
                              {item.video && <video src={item.video} alt="" />}
                              <div className="d-flex align-items-center page-snapshots mt-2">
                                <p className="p-reg m-0 chatMsg">
                                  {/* {item.description} */}
                                  <NoteItem
                                    index={index}
                                    item={item}
                                    allNotesLines={allNotesLines}
                                  />
                                </p>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-2 p20-horizental">
                              <span className="msg-date">
                                {formatDateTimeTodayPre(item.updated_at)}
                              </span>
                              <div
                                className="d-flex align-items-center"
                                onClick={() => {
                                  props.handleChildComponent(item, 'refer_notes');
                                  
                                }}>
                                {/* <img
                                className="ScanQr as-link"
                                src={ShareYellowIcon}
                                alt="scanQr"
                              /> */}
                                <span className="as-link">
                                  <ShareIconSvg fill={'#52bd41'} />
                                </span>

                                {/* <img
                                className="sharableYellow as-link"
                                src={ShareablesYellowIcon}
                                alt="shareable"
                              /> */}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="no-results-wrapper note-no-result-wrap-holder">
                        <div className="img-bg-wrapper">
                          <img
                            src={require('../../Assets/images/norecord/no-notes.png')}
                            alt=""
                          />
                        </div>

                        <div className="no-results-content">
                          <h2>No Notes Available</h2>
                          <p>Add a note below</p>
                          
                        </div>
                      </div>
                    )}

                  
                  </div>
                </div>
              )}

              {tabType === 'shared' && (
                <div className="mt-4">
                  <div className="mt-4">
                    {notesData && Object.entries(notesData).length ? (
                      notesData.map((item, index) => {
                        return (
                          <div className="card-container messages-card-note-container">
                            <div
                              className="padd-with-borderContaier as-link message-adjust-img-container"
                              onClick={() => {
                                
                              }}>
                                <div className='message-adjust-img-container' onClick={()=>{
                                  if (item.image || item.video) {
                                    props.handleMessageNotes('previewImgVideo',item)
                                    // setSlectedNote(item);
                                    // setShowModel('previewImgVideo');
                                  }
                                }}>
                                  
                                {item.image && <img src={item.image} alt="" />}
                                {item.video && <video src={item.video} alt="" />}
                                  </div>
                              
                              <div className="d-flex align-items-center page-snapshots mt-3">
                                <div style={{marginRight:'auto'}} onClick={()=>{
                                   if (item.image || item.video) {
                                    props.handleMessageNotes('previewImgVideo',item)
                                    // setSlectedNote(item);
                                    // setShowModel('previewImgVideo');
                                  }
                                }}>
                                  <p className="p-reg m-0 chatMsg">
                                    {item.description}
                                  </p>
                                </div>
                                <div className="customer_imgs" onClick={()=>{
                                   props.handleChildComponent(item?.share_with_users,'allUserOption')
                                }}>
                                  {item?.share_with_users?.length > 0 &&
                                    item.share_with_users
                                      .slice(0, 3)
                                      .map((user, index) => (
                                        <img
                                          src={user.profile_img}
                                          alt=""
                                          key={index}
                                        />
                                      ))}
                                  {item?.share_with_users.length > 3 && (
                                    <div
                                      className="remaining_div"
                                      onClick={() => {
                                        // setShowModel('allUserOption');
                                        // setShowAllUser(item?.share_with_users);
                                       
                                      }}>
                                      +{item?.share_with_users.length - 3}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-2 p20-horizental">
                              <span className="msg-date">
                                {formatDateTimeTodayPre(item.updated_at)}
                              </span>

                              <div
                                className="d-flex align-items-center"
                                onClick={() => {
                                  // hanldeComponent(item, 'refer_notes');
                                  props.handleChildComponent(item, 'refer_notes');
                                }}>
                                {/* <img
                                className="ScanQr as-link"
                                src={ShareYellowIcon}
                                alt="scanQr"
                              /> */}

                                <span className="as-link">
                                  <ShareIconSvg fill={'#52bd41'} />
                                </span>
                                {/* <img
                                className="sharableYellow as-link"
                                src={ShareablesYellowIcon}
                                alt="shareable"
                              /> */}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="no-results-wrapper">
                        <div className="img-bg-wrapper">
                          <img
                            src={require('../../Assets/images/norecord/no-notes.png')}
                            alt=""
                          />
                        </div>

                        <div className="no-results-content">
                          <h2>No Shared Notes</h2>
                          <p>
                            Share notes with your network to stay on the same
                            page
                          </p>
                          {notesData.length === 0 && !showSendNotes && (
                            <div className="btn-wrapper mt-3">
                              <Button
                                className="btn btn-dark"
                                onClick={() => {
                                  setShowSendNotes(true);
                                }}>
                                Add Note
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {tabType === 'fields' && (
                <div className="mt-4">
                  <div className="mt-4 messages-notes-field-form-block">
                    <div className="message-day-saparator">
                      Today February 2, 2024
                    </div>

                    <Form.Group className="fieldset">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={e => {
                          setEmail(e.target.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="fieldset">
                      <Form.Label>Phone number</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter your phone number"
                        value={phoneNumber}
                        onChange={e => {
                          setPhoneNumber(e.target.value);
                        }}
                      />
                    </Form.Group>

                    <div className="fieldset ">
                      <Form.Label>Relationship</Form.Label>
                      <CustomDropdown
                            // type={'credential'}
                            setDropdownId={setRelationid}
                            dropdownId={relationid}
                            DropDownValues={RelationshipArray}
                            itemName={['title']}
                            // className={'mb-3 recruitment_dropdown'}
                            // handleDropdownValue={(e)=>{
                            //   setRelationid(e);
                            // }}
                            // disabled={props.id ? true : false}
                          />



                      {/* <div className="select-wrapper ">
                        <select
                          className="form-control"
                          // value={relationid}
                          onChange={e => {
                            //
                            setRelationid(e.target.value);
                          }}>
                          {Object.entries(RelationshipArray).length > 0 &&
                            RelationshipArray.map((item, index) => (
                              <option
                                // id={item.id}
                                value={item.title}
                                key={`field-relation-${index}`}>
                                {item.title}
                              </option>
                            ))}
                        </select>
                      </div> */}
                    </div>
                    <div className="fieldset">
                      <Form.Label>Stage</Form.Label>
                      <CustomDropdown
                      setDropdownId={setStageid}
                      dropdownId={stageid}
                      DropDownValues={StageArray}
                      itemName={['title']}
                      />
                      {/* <div className="select-wrapper">
                        <select
                          className="form-control"
                          // value={relationid}
                          onChange={e => {
                            //
                            setStageid(e.target.value);
                          }}>
                          {Object.entries(StageArray).length > 0 &&
                            StageArray.map((item, index) => (
                              <option
                                // id={item.id}
                                value={item.title}
                                key={`field-relation-${index}`}>
                                {item.title}
                              </option>
                            ))}
                        </select>
                      </div> */}
                    </div>
                    <div className="fieldset TageInput">
                      <Form.Label>Tags</Form.Label>
                      <div className="form-control">
                        <TagsInput
                          value={selected}
                          onChange={setSelected}
                          name="fruits"
                          placeHolder="Enter your tag"
                        />
                      </div>
                    </div>
                    <div className="filter-btn-wrapper mt-3">
                      <div className="invite-btn text-left">
                        <Button className="btn-dark" type="submit">
                          Update
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Scrollbars>
        </div>
        {(notesData.length > 0 || showSendNotes) && tabType !== 'shared'  && (
          <div className="message-form message-ellipse-form-holder">
            {props.ticketImg && selectType === 'img' ? (
              <div className="msg-img as-link">
                <img
                  src={props.ticketImg}
                  alt=""
                  onClick={() => {
                    props.setShowModel('imgPrewiew');
                  }}
                />
                <i
                  className="fas fa-times"
                  onClick={() => {
                    deleteUpdatedImage(props.ticketImg);
                    props.setTicketImg(null);
                  }}
                />
              </div>
            ) : selectType === 'video' && props.ticketVideo ? (
              <div className="msg-img as-link">
                <video
                  style={{width: '50px'}}
                  src={props.ticketVideo}
                  onClick={() => {
                    props.setShowModel('videoPrewiew');
                  }}
                />
                <i
                  className="fas fa-times"
                  onClick={() => {
                    deleteUpdatedImage(props.ticketVideo);
                    props.setTicketVideo(null);
                  }}
                />
              </div>
            ) : (
              <span className="as-link">
                <i
                  className="fas fa-ellipsis-vertical"
                  onClick={() => {
                    props.setShowModel('upload');
                  }}
                />
              </span>
            )}
            <Form.Group className="form-input">
              <Form.Control
                name=""
                id="text_field"
                as="textarea"
                rows={3.5}
                value={writtenMsg}
                placeholder="Type what you are thinking"
                onKeyDown={e => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    if (
                      writtenMsg.trim() === '' &&
                      !props.ticketImg &&
                      !props.ticketVideo
                    ) {
                      errorMessage("Can't send empty message.");
                    } else {
                      create_notes();
                    }
                  }
                }}
                onChange={e => {
                  setWrittenMsg(e.target.value);
                }}
              />
            </Form.Group>

            {/* <Form.Group className="form-input">
            <Form.Control
              name=""
              id="text_field"
              as="textarea"
              rows={6}
              value={writtenMsg}
              placeholder="Type what you are thinking"
              onKeyDown={e => {
                if (writtenMsg == '') {
                  errorMessage("Can't send empty messsage.");
                } else {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    // create_notes();
                  }
                }
              }}
              onChange={e => {
                setWrittenMsg(e.target.value);
              }}
            />
          </Form.Group> */}
            <span
              className="form-icon send-btn"
              onClick={() => {
                if (writtenMsg.trim() === '') {
                  errorMessage("Can't send empty messsage.");
                } else {
                  create_notes();
                }
              }}>
              <i className="icon-play-icon" />
            </span>
          </div>
        )}
      </div>
      {showComponent === 'refer_notes' && (
        <ReferOpportunity
          onClose={() => {
            setShowComponent('');
          }}
          userData={referitem}
          type={'share_notes_with_others'}
        />
      )}
      {/* {searchFilter && (
        <CustomModal
          className="shareables-workspace-modal"
          size="small"
          onClose={() => setSearchFilterModal(false)}>
          <div className="filter-wrapper">
            <div className="filter-category-list">
              <ul>
                <li
                  className={
                    checkFilterOption.includes('all') ||
                    filterOptionValue.includes('all')
                      ? 'active'
                      : ''
                  }>
                  <Form.Label>
                    All
                    <input
                      type="checkbox"
                      id="All"
                      value="All"
                      onChange={() => {
                        handleSelectAll('all');
                      }}
                      checked={
                        checkFilterOption.includes('all') ||
                        filterOptionValue.includes('all')
                      }
                    />
                    <span />
                  </Form.Label>
                </li>

                {filterOption.map((item, index) => (
                  <li
                    key={`sessionC-${index}`}
                    className={
                      checkFilterOption.includes(item.id) ||
                      filterOptionValue.includes(item.title)
                        ? 'active'
                        : ''
                    }>
                    <Form.Label>
                      {item.title}
                      <input
                        type="checkbox"
                        id={item.id}
                        value={item.title}
                        onChange={e => {
                          handleClickList(item.id, e.target.value);
                        }}
                        checked={
                          checkFilterOption.includes(item.id) ||
                          filterOptionValue.includes(item.title)
                        }
                      />
                      <span />
                    </Form.Label>
                  </li>
                ))}
              </ul>

              <Button
                type="submit"
                className="btn-dark"
                onClick={() => {
                  get_notes();
                  setSearchFilterModal(false);
                }}>
                Select
              </Button>
            </div>
          </div>
        </CustomModal>
      )} */}

      {/* {showModel === 'allUserOption' && showAllUser.length > 0 && (
        <CustomModal
          size="small"
          style={{height: '85%'}}
          onClose={() => {
            setShowModel('');
          }}>
          <div className="filter-wrapper custom-modal-filter mb-0">
            <div className="filter-headings">
              <h2>Intenets</h2>
            </div>
            <Scrollbars>
              <div className="filter-category-list">
                {showAllUser &&
                  Object.entries(showAllUser).length > 0 &&
                  showAllUser.map(
                    (item, index) =>
                      localStorage.getItem('id') !== item.id && (
                        <div className="poc_sent_card mb-2">
                          <div className="poc_card_img">
                            <img
                              src={
                                item.profile_img
                                  ? item.profile_img
                                  : DEFAULT_IMAGE
                              }
                              alt=""
                            />
                          </div>

                          <div className="poc_card_content">
                           
                            <h1> {fixName(item.first_name, item.last_name)}</h1>
                          </div>
                          
                        </div>
                      ),
                  )}
              </div>
            </Scrollbars>
          </div>
        </CustomModal>
      )} */}

      {showModel === 'crop_modal' && file && (
        <CustomModal
          size="large"
          onClose={() => {
            setShowModel('');
            setFile(null);
          }}>
          <CropImage
            type="notes"
            ratio={429 / 187}
            file={file}
            setFile={setFile}
            setCropImg={setShowModel}
            imgUrl={ticketImg}
            setImgUrl={setTicketImg}
            sliderImage={sliderImage}
            setSliderImage={setSliderImage}
            setshowLoader={setshowLoader}
            // actionId={dataOfAction.id}
          />
        </CustomModal>
      )}
      {showModel === 'upload' && (
        <CustomModal position="bottom" onClose={() => setShowModel('')}>
          <div className="camera-options-list w-100 chatActionSheet">
            <ul>
              <li className="as-link image-list image_input">
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={e => changeTicketImg(e)}
                />
                <i className="fas fa-image" />{' '}
                <span>{labels.ADD_IMAGE[selected_lang]}</span>
              </li>
              <li className="as-link image-list image_input">
                <input
                  type="file"
                  accept="video/mp4, video/webm, video/ogg"
                  onChange={e => changeTicketVideo(e)}
                />
                <i className="fa-solid fa-video" />{' '}
                <span> {labels.ADD_VIDEO[selected_lang]}</span>
              </li>
              {/* <li className="as-link video-list">
                <i class="fa-solid fa-video" />{' '}
                {labels.ADD_VIDEO[selected_lang]}
              </li> */}
            </ul>
          </div>
        </CustomModal>
      )}
      {showModel === 'imgPrewiew' && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowModel('');
          }}>
          <div className="chat-img-preview">
            <img src={ticketImg} alt="" />
          </div>
        </CustomModal>
      )}
      {showModel === 'videoPrewiew' && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowModel('');
          }}>
          <div className="chat-img-preview">
            <video src={props.ticketVideo} controls autoPlay loop alt="" />
          </div>
        </CustomModal>
      )}
      {showModel === 'previewImgVideo' && slectedNote && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowModel('');
          }}>
          <div className="chat-img-preview">
            {slectedNote.image && <img src={slectedNote.image} alt="" />}
            {slectedNote.video && (
              <video src={slectedNote.video} controls autoPlay loop alt="" />
            )}
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default MessagesNotes;
