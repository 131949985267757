import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';

import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import commonApi from '../../Services/CommonService';
import SaveCard from '../Payments/SaveCard';

const CardDetail = props => {
  const [formData, setFormData] = useState({
    amount: props.dataTwo.cost,
    card: {number: '', expMonth: 10, expYear: 2023, cvc: ''},
    card_id: '',
    cardholder_name: '',
    complete_time: props.dataOne.complete_time,
    device_type: 'web',
    id_transaction_type: props.dataOne.slot_id,
    stripe_token: '',
    transaction_type: 'appointment',
    save_card: 'no',
  });
  const [validated, setValidated] = useState(false);
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [defaultCard, setDefaultCard] = useState([]);
  const [showComponent, setShowComponent] = useState('');

  // year function

  const currentYear = new Date().getFullYear();
  const yearRange = 10;
  const startYear = currentYear + yearRange;
  const endYear = currentYear;
  const years = [];

  for (let i = endYear; i <= startYear; i++) {
    years.push(i);
  }
  // year function end

  const submitForm = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      setValidated(true);
    } else {
      event.preventDefault();

      setValidated(false);
      props.stripePayment(formData);
      props.setShowForm(false);
    }
  };

  useEffect(() => {
    setFormData({
      amount: props.dataTwo.cost,
      card: {
        number: '',
        expMonth: 10,
        expYear: 2023,
        cvc: '',
      },
      card_id: '',
      cardholder_name: '',
      complete_time: props.dataOne.complete_time,
      device_type: 'web',
      id_transaction_type: props.dataOne.slot_id,
      stripe_token: '',
      transaction_type: 'appointment',
      save_card: 'no',
    });
    getMyCard();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMyCard = () => {
    commonApi
      .get_my_card()
      .then(res => {
        if (res.status === 200) {
          setDefaultCard(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  return (
    <>
      {showComponent === 'saveCard' && (
        <SlidingPanel hidePanel={() => setShowComponent('')}>
          <Scrollbars>
            <SaveCard defaultCard={defaultCard} getMyCard={getMyCard} />
          </Scrollbars>
        </SlidingPanel>
      )}

      {showComponent === '' && (
        <div className="appointment-payment-wrapper">
          <SlidingPanel hidePanel={() => props.setShowForm(false)}>
            <Scrollbars>
              <div className="d-flex align-items-center mb-4 fix-width-forms">
                {/* <div className="back-btn" onClick={() => { props.setShowForm(false) }}>
                    <i className="fas fa-angle-left" />
                </div> */}

                <div className="payment-heading">
                  <h4>{props.dataTwo.title}</h4>
                  <span className="appointment-cost">
                    ${props.dataTwo.cost}
                  </span>
                </div>
              </div>
              <div className="appointment-form fix-width-forms">
                <Form noValidate validated={validated} onSubmit={submitForm}>
                  <Form.Group className="fieldset">
                    <Form.Label>Cardholder Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      pattern="([a-zA-Z]+)|([a-zA-Z]+( [a-zA-Z]+)+)"
                      required
                      value={formData.cardholder_name}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          cardholder_name: e.target.value,
                        })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Cardholder name is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="fieldset">
                    <Form.Label>Card Number</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Card Number"
                      inputMode="numeric"
                      pattern="[0-9]{13,19}"
                      autoComplete="cc-number"
                      maxLength="19"
                      required
                      value={formData.card.number}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          card: {
                            ...formData['card'],
                            number: e.target.value,
                          },
                        })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Card number is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="fieldset">
                    <Form.Label>Expiration Date</Form.Label>
                    <Row>
                      <Col md={6}>
                        {/* <Form.Control
                      type="text"
                      placeholder="Month"
                      required
                      value={formData.card.expMonth}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          ['card']: {
                            ...formData['card'],
                            ['expMonth']: e.target.value,
                          },
                        })
                      }
                      minLength="2"
                      maxLength="2"
                      pattern="(0?[1-9]|[1][0-2])"
                    /> */}
                        <select
                          value={formData.card.expMonth}
                          onChange={e =>
                            setFormData({
                              ...formData,
                              card: {
                                ...formData['card'],
                                expMonth: e.target.value,
                              },
                            })
                          }>
                          {months.map((month, index) => (
                            <option key={index} value={index + 1}>
                              {month}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col md={6}>
                        {/* <Form.Control
                      type="text"
                      placeholder="Year"
                      required
                      value={formData.card.expYear}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          ['card']: {
                            ...formData['card'],
                            ['expYear']: e.target.value,
                          },
                        })
                      }
                      minLength="4"
                      maxLength="4"
                      pattern="[2]{1}[0]{1}[2-9]{1}[0-9]{1}"
                    /> */}
                        <select
                          value={formData.card.expYear}
                          onChange={e =>
                            setFormData({
                              ...formData,
                              card: {
                                ...formData['card'],
                                expYear: e.target.value,
                              },
                            })
                          }>
                          {years.map(year => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="fieldset">
                    <Form.Label>CVV</Form.Label>
                    <Row>
                      <Col md={6} className="CvvColumn">
                        <Form.Control
                          type="text"
                          placeholder="CVV"
                          required
                          value={formData.card.cvc}
                          onChange={e =>
                            setFormData({
                              ...formData,
                              card: {
                                ...formData['card'],
                                cvc: e.target.value,
                              },
                            })
                          }
                          maxLength="3"
                          pattern="[0-9]{3}"
                        />
                        <Form.Control.Feedback type="invalid">
                          CCV is required.
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="fieldset col-md-6 d-flex align-items-center">
                    <Form.Check
                      onChange={e =>
                        setFormData({
                          ...formData,
                          save_card: e.target.checked ? 'yes' : 'no',
                        })
                      }
                      type={'checkbox'}
                      id="save-my-card"
                      label="Save card for future purchases"
                      value={formData.save_card}
                    />
                    {defaultCard.length > 0 && (
                      <span
                        className="icon as-link cardIcon"
                        onClick={() => {
                          setShowComponent('saveCard');
                        }}>
                        <i className="fa-sharp fa-solid fa-credit-card" />
                      </span>
                    )}
                  </Form.Group>

                  {/* <Form.Group className="fieldset">
                        <div className="pay-with-apple text-center mt-4">
                            <Button>
                                <i className="fab fa-apple" /> Pay
                            </Button>
                        </div>
                    </Form.Group> */}
                  <Form.Group className="fieldset mt-5">
                    <div className="payment-btn">
                      <Button type="submit" className="btn-dark">
                        Pay
                      </Button>
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </Scrollbars>
          </SlidingPanel>
        </div>
      )}
    </>
  );
};

// CardDetail.propTypes = {

// }

export default CardDetail;
