import React from 'react';
import {DEFAULT_IMAGE, S3_EMOJI_DEV} from '../../Utils/env';
import {fixName} from '../../Utils/commonUtils';
import CustomModal from '../CustomModal';

const UsersReactionModal = ({emojiList, setUserId, allUsers, onClose}) => {
  return (
    <CustomModal
      //   position="bottom"
      size="medium"
      onClose={() => {
        onClose();
      }}>
      <div className="user-reactions-emoji-lists">
        {Object.entries(emojiList).length > 0 &&
          emojiList.map((item, index) => {
            const found = allUsers.find(objs => objs.id === item.sent_by);
            return (
              typeof found === 'object' && (
                <div
                  className="reactions-emoji-item"
                  key={`emoji-${item.emojiID}-${index}`}>
                  <div className="user-img" style={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                  }}>
                    <img
                      className="as-link"
                      onClick={() => {
                        setUserId(found.id);
                        onClose();
                      }}
                      src={
                        found.profile_img ? found.profile_img : DEFAULT_IMAGE
                      }
                      alt=""
                    />
                     <h2
                    className="as-link user-name"
                    onClick={() => {
                      setUserId(found.id);
                      onClose();
                    }}>
                    {found.last_name
                      ? fixName(found.first_name, found.last_name)
                      : fixName(
                          found.first_name.split(' ')[0],
                          found.first_name.split(' ')[1],
                        )}
                  </h2>
                  </div>
                 
                  <div className="list-emoji-icon" style={{marginLeft:'auto'}}>
                    <img
                      src={
                        S3_EMOJI_DEV +
                        item.skinTone +
                        '/' +
                        item.emojiName +
                        '.svg'
                      }
                      alt=""
                    />
                  </div>
                </div>
              )
            );
          })}
      </div>
    </CustomModal>
  );
};

export default UsersReactionModal;
