import React, {useState, useEffect, useRef} from 'react';
import {Button,  Form} from 'react-bootstrap';
import Sidebar from '../Sidebar';
import {
  OppotunityItem,
  OpportunitiesSearchFilter,
  SlidingPanel,
  CustomModal,
  SessionSearchFilter,
  RefferalSearchFilter,
} from '../../Components';
// import { opportunitiesItems } from "../../Constants/DummyContent";
import {labels} from '../../Constants/Translations';
import {
  selected_lang,
  ENV_HOST,
  DEFAULT_IMAGE,
} from '../../Utils/env';
import commonApi from '../../Services/CommonService';
// import GoogleMapReact from "google-map-react";
import {geocodeByPlaceId} from 'react-google-places-autocomplete';
import {getLatLng} from 'react-google-places-autocomplete';
import OpportunityDetail from '../../Components/OpportunitiesList/OpportunityDetail';
import ReferOpportunity from '../../Components/Referral/ReferOpportunity';
import OpportunityMessageList from '../../Components/OpportunitiesList/OpportunityMessageList';
import EditOpportunities from '../../Components/OpportunitiesList/EditOpportunities';



import {
  ShareYellowIcon,
  ShareablesYellowIcon,
} from '../../Constants/Images';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {Scrollbars} from 'react-custom-scrollbars';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import CreateGroup from '../../Components/Messages/CreateGroup';
import AllBuilders from './StaticPages/AllBuilders';

import {useSelector, useDispatch} from 'react-redux';
import {
  getOpportunityListing,
  getQuestionListing,
} from '../../Store/Actions/OpportunityAction';
import SmartChatPage from '../../Components/Common/SmartChatPage';
import {
  chatroomInfo,
  chatroomInfoItem,
  chatroomInfoOppo,
  collectingOppoUsers,
} from '../../Utils/commonUtils';
import ProfileComponent from '../../Components/ProfilePage/ProfileComponent';
import {amplitudeEvent} from '../../Services/AmplitudeServices';
import OpportunityMap from '../../Components/GoogleMap/OpportunityMap';
import {getItem} from '../../Utils/LocalStorage';

import CreateNews from '../../Components/News/CreateNews';
import SendInvitation from './StaticPages/SendInvitation';
import AddNewSnapShot from '../../Components/Messages/AddNewSnapShot';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import ReferSnapshot from '../../Components/Referral/ReferSnapshot';
import ReferProfile from '../../Components/Referral/ReferProfile';
import SnapShotFilter from '../../Components/Snapshot/SnapShotFilter';
import ScrollLoader from '../../Components/Modals/ScrollLoader';
import PersonalModal from '../../Components/Modals/PersonalModal';
import {listForDropDown} from '../../Utils/commonUtils';

import moment from 'moment';
import {errorMessage} from '../../Utils/ToastMessages';
import ShareAblesList from '../../Components/ShareAbles/ShareAblesList';
import {SliderShare} from '../../Constants/AnalyticsConstants';
import Slider from 'react-slick';

import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';

const ShareAbles = props => {
  const [showSpinner, setShowSpinner] = useState(false);
  // const [showBottomSpinner, setShowBottomSpinner] = useState(false);
  const [showProperComponet, setProperComponent] = useState('');
  const [isOpportunity, setIsOpportunity] = useState(false);
  const [isAnswers, setIsAnswers] = useState(false);
  const [currentComponent, setCurrentComponent] = useState('news');
  const [threeDots, setThreeDots] = useState(true);
  const [paid, setPaid] = useState('');
  const [locationS, setLocationS] = useState('');
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [buildersUserId, setBuildersUserId] = useState('');
  const [opportunityType, setOpportunityType] = useState(
    'available_opportunities',
  );
  // const [showProperComponet, setProperComponent] = useState('');
  const [searchQry, setSearchQry] = useState('');
  // const [allOpportunities, setOpportunities] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [showLoader, setshowLoader] = useState(false);
  const [qaItem, setQaItem] = useState({});
  const [button, setButton] = useState();
  const [searchFilter, setSearchFilterModal] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [frequency, setFrequency] = useState();
  const [opp, setOpp] = useState();
  // const [oppoBackground, setOppoBackground] = useState([]);
  const [isActive, setIsActive] = useState(false);
  // const [mapActive, setIsMapActive] = useState(false);
  // const [oppr, setOppr] = useState([]);
  // const [dec, setDec] = useState(true);
  // const [latitute, setlatitute] = useState('31.4699684');
  // const [lug, setlug] = useState('74.3096239');
  const [Longitude, setLongitude] = useState('');
  const [Latitude, setLatitude] = useState('');
  const [showModel, setShowModel] = useState('');
  const [isCheckSF, setIsCheckSF] = useState([]);

  const [workspace, setWorkspace] = useState([]);
  const [idenntifier_Filter, setIdenntifier_Filter] = useState([]);
  const [networkFilter, SetNetworkFilter] = useState({});
  const [model, setModel] = useState('');
  const [shareUrl, setShareUrl] = useState('');
  const name = localStorage.getItem('userName');
  const lowerName = name.toLowerCase();

  // const [areas_Filter, setAreas_Filter] = useState([]);
  // const [locationS, setlocationS] = useState('');

  // const [lust, setLut] = useState(null);
  // const [lngt, setLngt] = useState(null);

  // const [inviteUser, setInviteUser] = useState(false);
  const [createSessionEvent, setCreateSessionEvent] = useState(false);
  // const [businessName, setBusinessName] = useState('');
  // const [skillSet, setSkillSet] = useState('');
  // const [oppoUrl, setOppoUrl] = useState('');
  // const [oppoTitle, setOppoTitle] = useState('');
  // const [oppoDescription, setOppoDescription] = useState('');
  // const [oppoAddress, setOppoAddress] = useState('');

  const [identiferId, setIdentiferId] = useState([]);
  const [ethnicityId, setEthnicityId] = useState([]);
  const [gender, setGender] = useState([]);
  const [focusLocations, setFocusLocations] = useState([]);


  const [item, setItem] = useState([]);
  // const [serchLocation, setSearchLocation] = useState([]);
  // const [bullseyeButton, setBullseyeButton] = useState(false);

  const [dropdownValue, setdropdownValue] = useState([
    listForDropDown[0].title,
  ]);
  // const [workSpaceDropDownIds, setWorkSpaceDropDownIds] = useState('');
  // const workSpaceDropdown = localStorage.getItem('SelectedWorkSpaceArray')
  //   ? JSON.parse(localStorage.getItem('SelectedWorkSpaceArray'))
  //   : [];

  const [appointmentId, setAppointmentId] = useState([]);
  const [categoriesId, setCategoriesId] = useState([]);
  const [persnalTypesId, setPersnalTypesId] = useState([]);
  const [sessionFilter, setSessionFilter] = useState([]);
  const [snapshotFilter, setSnapshotFilter] = useState([]);
  const [opportunityFilter, setOpportunityFilter] = useState({});

  const [workSpaceIds, setWorkSpaceIds] = useState('');
  const [workSpaceItem, setWorkSpaceItem] = useState(
    localStorage.getItem('activeWorkspaceItem')
      ? JSON.parse(localStorage.getItem('activeWorkspaceItem'))
      : [],
  );

  const [isCheckValueWorkSpace, setIsCheckValueWorkSpace] = useState([]);

  const [areas_Filter, setAreas_Filter] = useState([listForDropDown[0].id]);

  const history = useHistory();
  const locationWeb = useLocation();
  // const [singleArrData, setSingleArrData] = useState({
  //   address: false,
  //   skill_set: false,
  //   url: false,
  // });

  const [analycticsSlider, setAnalycticsSlider] = useState(false);

  const rewardSlider = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    // centerPadding : "20px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };

  // const [topIcons, setTopIcons] = useState({
  //   focus: false,
  //   location: false,
  //   vaccinate: false,
  //   visibility: false,
  // });

  // const CircleSliding = ['All', 'Circle 1', 'Circle 2', 'Circle 3', 'Circle 4'];

  // map
  // const googleMapRef = useRef(null);
  // let googleMap = null;

  // var data = [
  //   {
  //     latitude: lust,
  //     longitude: lngt,
  //   }
  // ];

  const [oppoValue, setOppoValue] = useState('');
  // const [oppoBackValue, setOppoBackValue] = useState('');
  // const [validated, setValidated] = useState(false);
  const [shareAbleItem, setShareAbleItem] = useState('');
  const [type, setType] = useState('');

  // const [chooseCircle, setChooseCircle] = useState(false);
  // const [tabType, setTabType] = useState('general');
  // const [subTabs, setSubTabs] = useState('moderator');
  // const [id, setId] = useState('');
  const [showButton, setShowButton] = useState(false);
  const [chatData, setChatData] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckWorkSpace, setIsCheckWorkSpace] = useState([]);
  const [list, setList] = useState([]);
  const [freq, setFreq] = useState('');
  const [virtual, setVirtual] = useState(false);
  const [covid, setCovid] = useState(false);
  const [location, setLocation] = useState('');
  const [amount, setAmount] = useState('');
  const [typeOfShareAbles, setTypeOfShareAbles] = useState('');

  // const [chatRoomStatus, setChatRoomStatus] = useState('pending');
  // const [chatRoomName, setChatRoomName] = useState('');
  // const [chatRoomId, setChatRoomId] = useState('');
  // const [allChatUsers, setAllChatUsers] = useState([]);
  // const [oppoDetails, setOppoDetails] = useState([]);
  const [active, setActive] = useState(false);
  const [page, setPage] = useState(1);

  const [latitudeS, setLatitudeS] = useState('');
  const [longitudeS, setLongitudeS] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  // const [hasMoreData, setHasMoreData] = useState(true); // Flag to indicate more data is available
  const [isFetching, setIsFetching] = useState(false);

  const hasMoreData = useRef(false);

  const { pathname, search} = window.location;
  const urlParts = pathname.split('/').filter(Boolean);
  const searchParams = new URLSearchParams(search);
  const id = searchParams.get('id');
  const {idss} = useParams();

  const dispatch = useDispatch();
  const opportunityData = useSelector(state => state.opportunityListing);
  // const questionData = useSelector(state => state.questionListing);

  const [activeTab, setActiveTab] = useState('App'); // Initialize the active tab state
  // const [tabData, setTabData] = useState({
  //   App: 'Data for Tab 1',
  //   Email: 'Data for Tab 2',
  //   Text: 'Data for Tab 3',
  // }); // Initialize data for each tab

  const handleTabClick = tab => {
    setActiveTab(tab);
  };

  // useEffect(() => {
  //   if (mapButton) {
  //     googleMap = initGoogleMap();
  //   }
  //   var bounds = new window.google.maps.LatLngBounds();
  //   console.log('000000000000000000000000000000');
  //   console.log(oppr);

  //   {
  //     oppr.length > 0 &&
  //       oppr?.map(x => {
  //         var aa = {lat: Number(x.latitude), lng: Number(x.longitude)};
  //         // console.log(aa);
  //         const marker = createMarker(aa);
  //         bounds.extend(marker.position);
  //       });
  //   }

  //   if (mapButton) {
  //     googleMap.fitBounds(bounds); // the map to contain all markers
  //   }
  // }, [oppr]);

  useEffect(() => {
    // setshowLoader(true);
    // setTimeout(() => {
    //   setshowLoader(false);
    // }, 1000);

    if (idss) {
      setTimeout(() => {
        getBackgroundData();
      }, 1000);
    }

    handleNotificationDirection();
    handleRedirectionFromHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirectionFromHistory = () => {
    const {pathname, state} = locationWeb;

    if (state && state.typeIs === 'historyOpportunity') {
      setCurrentComponent('opportunities');

      history.replace({pathname: pathname, key: Date.now()});
    }
  };
  const getBackgroundData = () => {
    const DBOpenRequest = window.indexedDB.open('Build', 4);

    DBOpenRequest.onsuccess = event => {
      const db = DBOpenRequest.result;

      getData(db);
    };

    function getData(db) {
      const transaction = db.transaction(['Table1'], 'readwrite');

      transaction.oncomplete = event => {
        
      };

      transaction.onerror = event => {
        console.log(transaction.error, 'transaction error');
      };

      const objectStore = transaction.objectStore('Table1');

      const objectStoreRequest = objectStore.get('1');

      objectStoreRequest.onsuccess = event => {
        

        const myRecord = objectStoreRequest.result;
        

        handleBackgroundNotification(myRecord.value.type, myRecord.value);

        setTimeout(() => {
          deleteDataFromIndexDb(db);
        }, 3000);
      };
    }
  };

  const handleBackgroundNotification = (typeIs, Payload) => {
    if (typeIs === 'opportunity') {
      setCurrentComponent('opportunities');
    }
    if (typeIs === 'favouriteOpportunity') {
      handleItem(Payload.opportunity_detaill, '');
    }
    if (typeIs === 'favouriteQuestion') {
      setCurrentComponent('qa');
    }

    if (typeIs === 'qa') {
      setCurrentComponent('qa');
      getQuestionList('questions', '', 1);
    }
  };

  const deleteDataFromIndexDb = db => {
    let transaction = db.transaction(['Table1'], 'readwrite');
    transaction.objectStore('Table1').delete('1');

    transaction.oncomplete = () => {
      
    };
  };

  const handleNotificationDirection = () => {
    const {pathname, state} = locationWeb;

    if (state && state.typeIs === 'opportunity') {
      setCurrentComponent('opportunities');

      // handleItem(state.opportunityDetail, '');
      // setIsOpportunity(true);
      // setOpportunityType('pending_opportunities');
      // setItem(state.opportunityDetail);
      // setProperComponent('new-opportunity');

      history.replace({pathname: pathname, key: Date.now()});
    }
    if (state && state.typeIs === 'favouriteOpportunity') {
      handleItem(state.opportunityDetail, '');

      history.replace({pathname: pathname, key: Date.now()});
    }
    if (state && state.typeIs === 'favouriteQuestion') {
      setCurrentComponent('qa');
      history.replace({pathname: pathname, key: Date.now()});
    }

    if (state && state.typeIs === 'qa') {
      setCurrentComponent('qa');
      getQuestionList('questions', '', 1);

      history.replace({pathname: pathname, key: Date.now()});
    }
  };

  // const getDiscoverBuilders = async () => {
  //   // const response = await dispatch(getBuilderList());
  //   // if (response) {
  //   //   setShowPreSpinner(false);
  //   // }
  //   dispatch(getBuilderList());
  // };

  // initialize the google map
 
 
 
  // const initGoogleMap = useCallback(() => {
  //   return new window.google.maps.Map(googleMapRef.current, {
  //     center: {lat: 59.2987638, lng: 17.9917639},
  //     zoom: 11,
  //   });
  // }, []);

  // create marker on google map
  // const createMarker = markerObj =>
  //   new window.google.maps.Marker({
  //     position: {lat: markerObj.lat, lng: markerObj.lng},
  //     map: googleMap,
  //   });
  // // map end

  

  // const decc = () => {
  //   dec ? setDec(false) : setDec(true);
  // };
  const handleItem = (item, type) => {
    setItem(item);
    // console.log('wwwwwwwwwwwwwwwwwwwware rude', item);

    if (opportunityType === 'available_opportunities') {
      if (type === 'refer-opportunity') {
        setProperComponent('refer-opportunity');
      } else {
        if (
          parseInt(item.created_by) === parseInt(localStorage.getItem('id'))
        ) {
          setProperComponent('mine-opportunity');
        } else if (item.team_member_details.length > 0) {
          const found = item.team_member_details.find(
            objs => parseInt(objs.id) === parseInt(localStorage.getItem('id')),
          );
          if (typeof found === 'object') {
            setProperComponent('mine-opportunity');
          } else if (
            item.hasOwnProperty('chat_detail') &&
            Object.entries(item.chat_detail).length === 0
          ) {
            setProperComponent('respond-opportunity');
          } else if (Object.entries(item.chat_detail).length > 0) {
            setProperComponent('other-opportunity'); // new issue
          }
        } else if (item.chat_detail.length === 0) {
          setProperComponent('respond-opportunity');
        } else if (Object.entries(item.chat_detail).length > 0) {
          setProperComponent('other-opportunity');
        }
      }
    } else {
      if (type === 'refer-opportunity') {
        setProperComponent('refer-opportunity');
      } else if (Object.entries(item.chat_detail).length > 0) {
        // alert('others');
        setProperComponent('other-opportunity');
      } else {
        // alert('why others');
        manageOppoChatInvitation('', item.id, 'accepted');
        setProperComponent('new-opportunity');
      }
    }
  };


  // const handClick = () => {
  //   setDec(current => !current);
  //   decc();
  //   // bug();
  // };

  ///
  const ShareAbleRefer = (type, data) => {
    if (type === 'snapShot') {
      setProperComponent('shareAble_snapShot');
      setItem(data);
    } else if (type === 'profile') {
      // setTypeOfShareAbles(type);
      setItem(data);
      setProperComponent('shareAble_Profile');
    } else {
      setTypeOfShareAbles(type);
      setItem(data);
      setProperComponent('shareAble_refer');
    }
  };

  const handleClick = h => {
    setIsActive(!isActive);
  };

  const loader = () => {
    // setshowLoader(true);
    setShowSpinner(true);
  };

  const fun = h => {
    // console.log("12345679999", h);

    // setOpportunities(h.opportunity);
    // setshowLoader(false);
    setShowSpinner(false);
  };

  const bug = () => {
    var place_id = !location ? '' : location.value.place_id;
    if (place_id) {
      geocodeByPlaceId(place_id)
        .then(results => getLatLng(results[0]))
        .then(({lat, lng}) => {
          // console.log("Successfully got latitude and longitude", { lat, lng });
          // setlug(lng);
          // setlatitute(lat);
          // setOppr([]);
          getLocation(lng, lat);
        });
    }
  };

  const handleScroll = values => {
    const {clientHeight, scrollHeight, scrollTop} = values.target;
    // // Check if the user has reached the end of the content
    if (clientHeight + 2 + scrollTop >= scrollHeight) {
      // setShowBottomSpinner(true);
      // Increment the page number and call the API
      if (currentComponent === 'opportunities') {
        getAllOpportunities(
          opportunityType,
          searchQry,
          paid,
          page + 1,
          'moreData',
        );
      } else if (currentComponent === 'qa') {
        if (isAnswers) {
          getQuestionList('answers', searchQry, page + 1);
        } else {
          getQuestionList('questions', searchQry, page + 1);
        }
      }
    }
  };

  const manageChatInvitation = (id, type, status) => {
    let payload = {
      chat_room_id: id,
      chat_status: type,
      status: status,
    };
    commonApi
      .manage_chat_invitation(payload)
      .then(response => {
        if (response) {
        }
        setShowSpinner(false);
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  const manageOppoChatInvitation = (id = '', o_id, status) => {
    let payload = {
      opportunity_id: o_id,
      chat_id: id,
      status: status,
    };
    commonApi
      .accept_opportunity_invitation(payload)
      .then(response => {
        if (response) {
        }
        setShowSpinner(false);
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  const handleQuestionItem = (item, type) => {
    
    setItem(item);
    if (type === 'refer') {
      setProperComponent('refer-opportunity');
    } else {
      // setshowLoader(true);
      setShowSpinner(true);

      if (item.invitation_detail && item.group_chat_detail.id) {
        manageChatInvitation(
          item.group_chat_detail.id,
          'group_chat',
          'accepted',
        );
      }

      if (
        item.group_chat_detail &&
        Object.entries(item.group_chat_detail).length > 0
      ) {
        // setQaItem(item.group_chat_detail);
        // setProperComponent("show-qa-chat");
        if (
          parseInt(item.created_by) === parseInt(localStorage.getItem('id'))
        ) {
          setQaItem(item.group_chat_detail);
          setProperComponent('show-qa-chat');
          // setshowLoader(false);
          setShowSpinner(false);
        } else if (
          item.group_chat_detail.moderator_ids.includes(
            localStorage.getItem('id'),
          )
        ) {
          const found = item.group_chat_detail.moderator_detail.find(
            objs => objs.id === getItem('id') && objs.status === 'pending',
          );
          if (typeof found === 'object') {
            manageChatInvitation(
              item.group_chat_detail.id,
              'group_chat',
              'accepted',
            );
          }
          setQaItem(item.group_chat_detail);
          setProperComponent('show-qa-chat');
          // setshowLoader(false);
          setShowSpinner(false);
        } else if (
          item.group_chat_detail.audience_ids.includes(
            localStorage.getItem('id'),
          )
        ) {
          const found = item.group_chat_detail.audience_detail.find(
            objs => objs.id === getItem('id') && objs.status === 'pending',
          );
          if (typeof found === 'object') {
            manageChatInvitation(
              item.group_chat_detail.id,
              'group_chat',
              'accepted',
            );
          }
          setQaItem(item.group_chat_detail);
          setProperComponent('show-qa-chat');
          // setshowLoader(false);
          setShowSpinner(false);
        } else {
          let audienceIds = item.group_chat_detail.audience_ids;
          audienceIds.push(localStorage.getItem('id'));
          audienceIds = audienceIds.toString();

          let moderatorIds = item.group_chat_detail.moderator_ids.toString();

          updateSessionGroupChat(
            audienceIds,
            item.group_chat_detail.chat_id,
            item.group_chat_detail.creator_id,
            item.group_chat_detail.group_image,
            item.group_chat_detail.group_name,
            item.group_chat_detail.left_removed_users,
            item.group_chat_detail.left_removed_users,
            moderatorIds,
            item.group_chat_detail.private_group,
            item.group_chat_detail.snap_shot_id,
          );
        }
      } else if (
        parseInt(item.created_by) === parseInt(localStorage.getItem('id')) ||
        (item.moderator_ids &&
          item.moderator_ids.split(',').includes(localStorage.getItem('id'))) ||
        (item.audience_ids &&
          item.audience_ids.split(',').includes(localStorage.getItem('id')))
      ) {
        createSessionGroupChat(
          item.audience_ids,
          'chatroom_q_' + (Math.random() + 1).toString(36).substring(3),
          item.created_by,
          item.image_url,
          item.question,
          item.moderator_ids,
          'question',
          item.id,
        );
      } else {
        let audienceIds = localStorage.getItem('id');

        if (item.audience_ids) {
          audienceIds = item.audience_ids.split(',');
          audienceIds.push(localStorage.getItem('id'));
        }

        audienceIds = audienceIds.toString();

        createSessionGroupChat(
          audienceIds,
          'chatroom_q_' + (Math.random() + 1).toString(36).substring(3),
          item.created_by,
          item.image_url,
          item.question,
          item.moderator_ids,
          'question',
          item.id,
        );
      }
    }
  };

  // const arrToStr = arr => {
  //   let txt = [];
  //   for (let str in arr) {
  //     txt.push(arr[str]['title']);
  //   }
  //   return txt.join(', ');
  // };

  // const makeQuestionItemData = obj => {
  //   let returnObj = {
  //     question: obj.question,
  //     background_detail: arrToStr(obj.background_detail),
  //     best_ans: obj.best_ans,
  //     like: obj.like,
  //     location: obj.location,
  //     updated_at: obj.updated_at,
  //     best: false,
  //     name: '',
  //     profile_img: DEFAULT_IMAGE,
  //     answer: '',
  //   };
  //   if (Object.entries(obj.best_ans_user_detail).length > 0) {
  //     returnObj.best = true;
  //     returnObj.name =
  //       obj.best_ans_user_detail.first_name +
  //       ' ' +
  //       obj.best_ans_user_detail.first_name;
  //     returnObj.profile_img = obj.best_ans_user_detail.profile_img;
  //     returnObj.answer = obj.best_ans;
  //   } else if (Object.entries(obj.last_message_user_detail).length > 0) {
  //     returnObj.best = false;
  //     returnObj.name = obj.last_message;
  //     returnObj.profile_img = obj.last_message_user_detail.profile_img;
  //     returnObj.answer = '';
  //   }
  //   return returnObj;
  // };

  const getLocation = (lng, latt) => {
    // setshowLoader(true);
    setShowSpinner(true);
    var work = {
      latitude: latt,
      longitude: lng,
      page: 1,
      radius: 100,
    };

    // console.log("12wqqwes56", work);

    commonApi
      .get_opportuntiy_location(work)
      .then(res => {
        // console.log("fffffffffff",res.frequencies );
        if (res.status === 200) {
          // setOppr(res.data);
          // setOppr(curr => [...curr, {latitude: latt, longitude: lng}]);

          // setshowLoader(false);
          setShowSpinner(false);

          // // console.log(res.data);
          // setlug(parseFloat(res.data[0].longitude));
          // // console.log("gggggggggggg", res.data[0].longitude);
          // // console.log("kkkkkkkkkkk", res.data[0].latitude);

          // setlat(parseFloat(res.data[0].latitude));
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getTabstatus = () => {
    // setshowLoader(true);
    // setShowSpinner(true);
    commonApi
      .get_tab_status()
      .then(res => {
        // setshowLoader(false);
        // setShowSpinner(false);
        // console.log("fffffffffff",res.frequencies );
        if (res.status === 200) {
          // setProperComponent('firstVist');
          const item = res.tab_status;
          if (item.length === 0) {
            setProperComponent('firstVist');
          } else {
            const newArray = [];
           if(Object.entries(item).length > 0)  {
              
                item.forEach((item, index) => {
                  newArray.push(item.tab);
                });
            }
            const found = newArray.find(element => element === 'opportunity');
            if (found === 'opportunity') {
              // setProperComponent('');
            } else {
              setProperComponent('firstVist');
            }
            
            // if (newArray === ) {

            // }
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getFrequency = () => {
    // setshowLoader(true);
    // setShowSpinner(true);
    commonApi
      .get_frequency()
      .then(res => {
        // setshowLoader(false);
        // setShowSpinner(false);
        // console.log("fffffffffff",res.frequencies );
        if (res.status === 200) {
          setFrequency(res.frequencies);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getOpp = () => {
    commonApi
      .get_opportunity_types()
      .then(res => {
        if (res.status === 200) {
          setOpp(res.opportunity_types);
          setOppoValue(res.opportunity_types[0].id);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getAllOpportunities = async (status, qry, paidStatus, pNo, check) => {
    // alert('inside func')
    
    
    
    
    
    if (check) {
      if (hasMoreData.current || isFetching) {
        // setShowSpinner(false)
        return; // Don't fetch if no more data or already fetching
      } else {
        if (pNo > 1) {
          setIsLoading(true);
        }
      }
    }

    setIsFetching(true);

    if (status === 'available_opportunities') {
      amplitudeEvent('VISIT_AVAILABLE_OPPORTUNITIES');
    } else {
      amplitudeEvent('VISIT_PENDING_OPPORTUNITIES');
    }
    // setshowLoader(true); setShowSpinner(true);
    var initData = {
      ally: active ? 'yes' : 'no',
      // gender: gender.toString(),
      gender: '',
      // otherIdentifires: identiferId.toString(),
      otherIdentifires: '',
      // ethnicities: ethnicityId.toString(),
      ethnicities: '',
      city_states: focusLocations,
      on_boarding_areas: persnalTypesId,
      appointment_types: appointmentId,
      on_boarding_categories: categoriesId,
      amount: amount,
      frequency: freq,
      latitude: latitudeS,
      like: false,
      location:
        typeof locationS === 'object'
          ? locationS.label
          : typeof locationS === 'string'
          ? locationS
          : '',
      longitude: longitudeS,
      opportunity_types: isCheck,
      page: pNo,
      paid_status: paidStatus,
      radius: 100,
      search_query: qry ? qry : '',
      status: status,
      vaccinated: covid ? 'yes' : 'no',
      virtual: virtual ? 'yes' : 'no',
    };

    const response = await dispatch(getOpportunityListing(initData));

    if (response) {
      if (pNo > 1) {
        if (response.opportunity.length > 0) {
          setPage(pNo);
        } else {
          hasMoreData.current = !hasMoreData.current;
          // setShowBottomSpinner(false);
        }
      } else {
        setPage(1);
        // setShowBottomSpinner(true);
      }
      // setshowLoader(false);
      // alert('above state')
      setShowSpinner(false);
      setIsFetching(false);
      setIsLoading(false);
      // setShowBottomSpinner(false);
    }

    // commonApi
    //   .get_opportunity(initData)
    //   .then(res => {
    //     if (res.status === 200) {
    //       if (pNo > 1) {
    //         if (res.opportunity.length > 0) {
    //           setPage(pNo);
    //           setOpportunities([...allOpportunities, ...res.opportunity]);
    //         }
    //       } else {
    //         setPage(1);
    //         setOpportunities(res.opportunity);
    //       }
    //     }
    //     setshowLoader(false); setShowSpinner(false);
    //   })
    //   .catch(err => {
    //     console.log('Err', err);
    //     return {type: 'error', message: err.message};
    //   });
  };

  const getQuestionList = async (type, qry, pNo) => {
    if (type === 'questions') {
      amplitudeEvent('VISIT_QUESTIONS');
    } else {
      amplitudeEvent('VISIT_ANSWERS');
    }

    let payload = {type: type, search_query: qry, page: pNo};

    const response = await dispatch(getQuestionListing(payload));

    if (response) {
      if (pNo > 1) {
        if (response.get_questions_list.length > 0) {
          setPage(pNo);
          // setQuestionList([...questionList, ...res.get_questions_list]);
        } else {
          // setShowBottomSpinner(false);
        }
      } else {
        setPage(1);
        // setQuestionList(res.get_questions_list);
        // setShowBottomSpinner(true);
      }
    }
    // setshowLoader(false);
    setShowSpinner(false);
    // setShowBottomSpinner(false);

    // commonApi
    //   .get_questions_list(payload)
    //   .then(res => {
    //     if (res.status === 200) {
    //       if (pNo > 1) {
    //         if (res.get_questions_list.length > 0) {
    //           setPage(pNo);
    //           setQuestionList([...questionList, ...res.get_questions_list]);
    //         }
    //       } else {
    //         setPage(1);
    //         setQuestionList(res.get_questions_list);
    //       }
    //     }
    //     setshowLoader(false); setShowSpinner(false);
    //   })
    //   .catch(err => {
    //     console.log('Err', err);
    //     return {type: 'error', message: err.message};
    //   });
  };

  const createSessionGroupChat = (
    audience,
    chat,
    creator,
    image,
    name,
    moderator,
    type,
    id,
  ) => {
    let payload = {
      audience_ids: audience,
      chat_room_name: chat,
      creator_id: creator,
      group_image: image ? image : DEFAULT_IMAGE,
      group_name: name,
      moderator_ids: moderator,
      type: type,
      type_id: id,
    };
    commonApi
      .create_session_group_chat(payload)
      .then(res => {
        if (res.status === 200) {
          // setQuestionList(res.get_questions_list);
          checkUsersInGroup(res.group_chat_id);
        }
        // setshowLoader(false); setShowSpinner(false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const updateSessionGroupChat = (
    audience,
    chat,
    creator,
    image,
    name,
    left_moderator,
    left_users,
    moderator,
    privat,
    id,
  ) => {
    let payload = {
      audience_ids: audience,
      // chat_room_name: '',
      chat_id: chat,
      creator_id: creator,
      group_image: image,
      group_name: name,
      left_removed_moderator: left_moderator,
      left_removed_users: left_users,
      moderator_ids: moderator,
      // type: '',
      // type_id: ''
      private_group: privat,
      snap_shot_id: id,
      workspace_ids: localStorage.getItem('workspaceIds'),
    };
    commonApi
      .update_session_group_chat(payload)
      .then(res => {
        if (res.status === 200) {
          amplitudeEvent('PARTICIPATE_IN_QUESTION_ANSWER');
          // setQuestionList(res.get_questions_list);
          checkUsersInGroup(res.group_chat_id);
        }
        // setshowLoader(false); setShowSpinner(false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const checkUsersInGroup = id => {
    let payload = {group_id: id};
    commonApi
      .check_new_users_in_group(payload)
      .then(res => {
        if (res.status === 200) {
          // setQuestionList(res.get_questions_list);
          setQaItem(res.group_chat_detail);
          setProperComponent('show-qa-chat');
        }
        // setshowLoader(false);
        setShowSpinner(false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (isCheck.includes('2') || isCheck.includes('5')) {
    } else {
      setFreq([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  useEffect(() => {
    bug();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // console.log(lat, lug);
  useEffect(() => {
    // setshowLoader(true);
    setShowSpinner(true);
    getAllOpportunities(opportunityType, '', paid, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunityType, paid]);

  // useEffect(() => {

  // }, [])

  useEffect(() => {
    // setshowLoader(true);
    // setShowSpinner(true);
    getAllOpportunities(opportunityType, '', paid, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workSpaceItem]);

  // const getOppoBackground = () => {
  //   commonApi
  //     .get_background()
  //     .then(res => {
  //       if (res.status === 200) {
  //         // setOppoBackground(res.background);
  //         setOppoBackValue(res.background[0].id);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  // const createOpportunity = () => {
  //   var initData = {
  //     address: oppoAddress ? oppoAddress.label : '',
  //     ally: 'no',
  //     amount: '',
  //     association_type: 'myself',
  //     association_with: 'user_id',
  //     background_ids: [oppoBackValue],
  //     business_name: businessName,
  //     description: oppoDescription,
  //     education_level_id: 'Master',
  //     frequency: '',
  //     opportunity_type_id: oppoValue,
  //     paid: 'no',
  //     personal_invite_ids: audienceArr,
  //     shop_id: '',
  //     skill_set: skillSet,
  //     status: 'pending',
  //     team_member_ids: moderatorArr,
  //     title: oppoTitle,
  //     url: oppoUrl,
  //     vaccinate: topIcons.vaccinate ? 'yes' : 'no',
  //     visibility: topIcons.visibility ? 'private' : 'public',
  //     workspace_ids: localStorage.getItem('workspaceIds'),
  //   };
  //   commonApi
  //     .create_opportunity(initData)
  //     .then(res => {
  //       amplitudeEvent('CREATE_OPPORTUNITY');
        
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  // const getFollowersList = () => {
  //   var initData = {
  //     search_query: '',
  //     user_id: localStorage.getItem('id'),
  //   };
  //   commonApi
  //     .followers_list(initData)
  //     .then(res => {
  //       // console.log('working res');
  //       setFollowers(
  //         res.data.filter(
  //           item =>
  //             !moderatorArr.includes(item.id) && !audienceArr.includes(item.id),
  //         ),
  //       );
  //       setAllModeratorArr(
  //         res.data.filter(
  //           item =>
  //             !moderatorArr.includes(item.id) && !audienceArr.includes(item.id),
  //         ),
  //       );
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };



  useEffect(() => {
    // getDiscoverBuilders();
    getTabstatus();
    // getFollowersList();
 
    getFrequency();
    getOpp();

    // var urlUser = window.location.pathname;
    // urlUser = urlUser.slice(1);
    // var url = urlUser.split(/[.\-/]/);
    // console.log(url);

    // if (url && url[1] == 'opportunity_invite') {
    //   //  joinTheSession(url[2])
    //   getOpportunityDetails(url[2]);
    // }
    // if (url && url[1] == 'question_invite') {
    //   //  joinTheSession(url[2])
    //   get_questions_detail(url[2]);
    // }

    if (urlParts[1] === 'opportunity') {
      setCurrentComponent('opportunities');
      getOpportunityDetails(id);
    }

    if (urlParts[1] === 'question') {
      get_questions_detail(id);
    }

    amplitudeEvent('VISIT_POSTS');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const get_questions_detail = id => {
    var payLoad = {
      question_id: id,
    };
    commonApi
      .get_questions_detail(payLoad)
      .then(response => {
        if (response.status === 200) {
       
          handleQuestionItem(response.get_questions_list);
          setCurrentComponent('qa');
          getQuestionList('questions', '', 1);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getOpportunityDetails = id => {
    var payLoad = {
      opportunity_id: id,
      page: 1,
      search_query: '',
      status: 'pending',
    };
    commonApi
      .get_opportunity_details(payLoad)
      .then(response => {
        if (response.status === 200) {
          
          response.available_opportunity.forEach(item => {
            if (item.id === id) {
              
              const pending = item.chat_detail;

              if (Array.isArray(pending)) {
                setIsOpportunity(false);

                getAllOpportunities('available_opportunities', '', '', 1);
              } else if (typeof pending === 'object') {
                getAllOpportunities('pending_opportunities', '', '', 1);
                setIsOpportunity(true);
              } else {
                
              }
              setChatData(item.chat_detail);

              // const type = response.show_pending_opportunities === true ? 'available_opportunities' : 'pending_opportunities'
              handleItem(item, 'available_opportunities');
            }
          });
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // const handleCreateOppo = () => {
  //   setItem([]);
  //   setDec(true);
  //   // setInviteUser(!inviteUser);
  //   if (showProperComponet === 'inviteUser') {
  //     setProperComponent('');
  //   } else {
  //     setProperComponent('inviteUser');
  //   }
  //   // getOpp();
  //   // getOppoBackground();
  // };

  // const handleModerators = id => {
  //   if (!moderatorArr.includes(id)) {
  //     setModeratorArr([...moderatorArr, id]);
  //   }
  // };

  const handleClickList = e => {
    const {id, value} = e.target;
    if (!isCheckWorkSpace.includes(id)) {
      setIsCheckWorkSpace([...isCheckWorkSpace, id]);
      setIsCheckValueWorkSpace([...isCheckValueWorkSpace, value]);
    } else {
      if (isCheckWorkSpace.length === 1) {
        errorMessage('One workspace should be selected.');
      } else {
        setIsCheckWorkSpace(isCheckWorkSpace.filter(item => item !== id));
        setIsCheckValueWorkSpace(
          isCheckValueWorkSpace.filter(item => item !== value),
        );
      }
    }
  };
  

  const collectingUsers = item => {
    const creator = item.hasOwnProperty('creator_details')
      ? 'creator_details'
      : 'creator_detail';
    const moderator =
      item.chat_type === 'opportunity'
        ? 'team_member_details'
        : 'moderator_detail';
    const audience =
      item.chat_type === 'opportunity'
        ? 'other_persons_opportunity_chat_room'
        : 'audience_detail';

    const chatUsers = [
      {
        id: item[creator]['id'],
        first_name: item[creator]['first_name'],
        last_name: item[creator]['last_name'],
        profile_img: item[creator]['profile_img'],
        type: 'creator',
      },
    ];
    if (item[audience].length > 0) {
      item[audience].forEach(item => {
        item.type = 'audience';
        chatUsers.push(item);
      });
    }
    if (item[moderator].length > 0) {
      item[moderator].forEach(item => {
        item.type = 'moderator';
        chatUsers.push(item);
      });
    }
    return chatUsers;
  };

  // const handleAudience = id => {
  //   if (!audienceArr.includes(id)) {
  //     setAudienceArr([...audienceArr, id]);
  //   }
  // };





  



  // const submitForm = event => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     // console.log('submitForm sorry ');
  //     setValidated(true);
  //   } else {
  //     event.preventDefault();
  //     // console.log('submitForm createOpportunity();');
  //     createOpportunity();
  //     // setInviteUser(!inviteUser);
  //     if (showProperComponet === 'inviteUser') {
  //       setProperComponent('');
  //     } else {
  //       setProperComponent('inviteUser');
  //     }
  //     handleResetForm();
  //     setValidated(false);
  //   }
  // };
  // console.log("wwwwwwwwwwwww",allOpportunities)
  // const handleResetForm = () => {
  //   setOppoAddress('');
  //   setOppoBackValue('');
  //   setOppoValue('');
  //   setOppoDescription('');
  //   setTopIcons({
  //     focus: false,
  //     location: false,
  //     vaccinate: false,
  //     visibility: false,
  //   });
  //   setAudienceArr([]);
  //   setModeratorArr([]);
  //   setBusinessName('');
  //   setSkillSet('');
  //   setOppoUrl('');
  //   setOppoTitle('');
  // };

  const handleCommonChat = (data, type) => {
    
    
    
    setItem(data);
    if (type === 'edit-group-component') {
      // setAddQuestion(false);
      // setCreateSessionEvent(false);
      // setInviteUser(false);
      // setCreateGroup(true);
      setProperComponent('createGroup');
    } else if (type === 'create-session-component') {
      // setAddQuestion(false);
      // setCreateGroup(false);
      // setProperComponent('');
      // setInviteUser(false);
      // setCreateSessionEvent(true);
    }
  };

  // console.log("testtest", chatData)

  // const buildersHandle = (t, id) => {
  //   setProperComponent(t);
  //   setBuildersUserId(id);
  //   // setProperComponent(t);
  //   // setPlusButton(true);
  //   // setMapButton(false);
  // };

  const newDecision = () => {
    if (item.login_user && item.login_user.status) {
      return item.login_user.status;
    } else if (item.chat_detail.hasOwnProperty('status')) {
      return item.chat_detail.status;
    } else {
      return 'pending';
    }
  };

  useEffect(() => {
    if (showProperComponet !== '' && showProperComponet !== 'firstVist') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showProperComponet]);
  // useEffect(() => {
  //   if (Object.entries(workSpaceItem).length > 0) {
  //     const newIsCheck = [];
  //     const newIsCheckValue = [];

  //     workSpaceItem.forEach((item, index) => {
  //       newIsCheck.push(item.id); // Assuming you want to add 'id' to isCheck
  //       newIsCheckValue.push(item.name); // Assuming you want to add 'value' to isCheckValue
  //     });

  //     // Update the state arrays after iterating through workSpaceItem
  //     setIsCheckWorkSpace(newIsCheck);
  //     setIsCheckValueWorkSpace(newIsCheckValue);
  //   }
  // }, [workSpaceItem]);

  // const dropDownSelection = e => {
    
  //   const {id, innerText} = e.target;

  //   setAreas_Filter(id);
  //   setdropdownValue([innerText]);
  // };

  const showData = (val, item, type) => {
    
    

    if (val === 'title') {
      if (type === 'event') {
        return item.session_title;
      } else if (type === 'session') {
        return item.session_title;
      } else if (type === 'snapShot') {
        return item.title;
      } else if (type === 'opportunity') {
        return item.title;
      } else if (type === 'appointment') {
        return item.title;
      } else if (type === 'message') {
        return item.group_name;
      } else if (type === 'conference') {
        return item.session_title;
      }
    }

    if (val === 'description') {
      if (type === 'event') {
        return item.session_description;
      } else if (type === 'session') {
        return item.session_description;
      } else if (type === 'snapShot') {
        return item.description;
      } else if (type === 'opportunity') {
        return item.description;
      } else if (type === 'appointment') {
        return item.description;
      } else if (type === 'conference') {
        return item.session_description;
      }
    }

    if (val === 'date') {
      if (type === 'event') {
        return moment(item.session_start_time).format('ddd, MMMD @ h:mm A');
      } else if (type === 'session') {
        return moment(item.session_start_time).format('ddd, MMMD @ h:mm A');
      } else if (type === 'snapShot') {
        if (item.date) {
          return (
            item.date !== '0000-00-00' &&
            moment(item.date).format('ddd, MMMD @ h:mm A')
          );
        }
      } else if (type === 'opportunity') {
        return moment(item.created_at).format('ddd, MMMD @ h:mm A');
      } else if (type === 'appointment') {
        return moment(item.date).format('ddd, MMMD @ h:mm A');
      } else if (type === 'message') {
        return moment(item.created_at).format('ddd, MMMD @ h:mm A');
      } else if (type === 'conference') {
        return moment(item.session_start_time).format('ddd, MMMD @ h:mm A');
      }
    }

    if (val === 'image') {
      if (type === 'event') {
        return item.image.slider_images;
      } else if (type === 'session') {
        return item.image;
      } else if (type === 'snapShot') {
        return item.images[0];
      } else if (type === 'opportunity') {
        return DEFAULT_IMAGE;
      } else if (type === 'appointment') {
        return DEFAULT_IMAGE;
      } else if (type === 'message') {
        return item.group_image;
      } else if (type === 'conference') {
        return DEFAULT_IMAGE;
      }
    }

    if (val === 'location') {
      if (type === 'event') {
        return item.address;
      } else if (type === 'session') {
        return item.address;
      } else if (type === 'snapShot') {
        return;
      } else if (type === 'opportunity') {
        return;
      } else if (type === 'appointment') {
        return;
      } else if (type === 'message') {
        return;
      } else if (type === 'conference') {
        return;
      }
    }
  };

  const ShowModelLinks = (model, type, data) => {
    
    // alert('hehe')

    const typeSelecttion =
      type === 'conference'
        ? 'session'
        : type === 'snapShot'
        ? 'snapshot'
        : type === 'message'
        ? 'group_chat'
        : type;
    if (type === 'profile' || type === 'referperson') {
      // alert('hehe222')
      setShareUrl(
        'www.' +
          ENV_HOST +
          'roiforpros.com/' +
          data.unique_user_name +
          '/profile?id=' +
          data.id,
      );
      setModel(model);
    } else {
      // alert('hehe2333')
      setShareUrl(
        'www.' +
          ENV_HOST +
          'roiforpros.com/' +
          lowerName +
          '/' +
          typeSelecttion +
          '/invite?id=' +
          data.id,
      );
      setModel(model);
      setShowModel('');
    }
  };



  const formatingArray = array => {
    const personalIndex = array.indexOf('Personal');

    if (personalIndex !== -1) {
      // If "Personal" exists, move it to the beginning of the array
      array.splice(personalIndex, 1);
      array.unshift('Personal');
    }

    return array;
  };

  
  
  
  return (
    <>
      {/* {showLoader && <LoaderModal show={showLoader} />} */}
      <div className="page-wrapper">
        <Sidebar
        showHiddenPanel={showHiddenPanel}
          setWorkSpaceItem={setWorkSpaceItem}
          workSpaceItem={workSpaceItem}
          location={props.location}
          setShowComponent={setProperComponent}
          showComponent={showProperComponet}
        />
        <div className="page-content-wrapper slider-screen-main ">
          {/* {showLoader && <LoaderSpinner />} */}

          
         

            <div className="sessions-list-wrapper shareables_page">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {SliderShare.length > 0 &&
                      SliderShare.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>
           
           {/* {showProperComponet !== 'firstVist' &&
             showProperComponet !== 'WorkSpace' && (
               <DiscoverBuilders
                 buildersHandle={buildersHandle}
                 apiHit={workSpaceItem}
               />
             )} */}

           {showProperComponet !== 'firstVist' && (
            //  <div className="">
              

               <>
                 {/* Search Wrapper */}
                 <div className="search-tabs-wrapper">
                   {/* <div className="d-flex justify-content-between align-items-center mb-2">
                           <div className="event-tabs tabs-style-2 mb-0">
                             <ul>
                               <li>
                                 <Button
                                style={{ color: 'black' }}
                                   className={
                                     currentComponent === 'news'
                                       ? 'active'
                                       : ''
                                   }
                                   onClick={() => {
                                     if (
                                       currentComponent === 'opportunities'
                                     ) {
                                       setShowSpinner(true);
                                     }

                                     hasMoreData.current =
                                       !hasMoreData.current;
                                     getQuestionList('questions', '', 1);
                                     setSearchQry('');
                                     setCurrentComponent('news');
                                     setInviteUser(false);
                                     handClick();
                                     setMapButton(false);
                                     setIsAnswers(false);
                                     if (
                                       ['inviteUser', 'addQuestion'].includes(
                                         showProperComponet,
                                       )
                                     ) {
                                       setProperComponent('');
                                     }
                                   }}>
                                   Shares
                                 </Button>
                               </li>
                               <li>
                                 <Button
                                   className={
                                     currentComponent === 'opportunities'
                                       ? 'active'
                                       : ''
                                   }
                                   onClick={() => {
                                     if (currentComponent === 'qa') {
                                     
                                       setShowSpinner(true);
                                     }

                                     setIsOpportunity(false);
                                     setOpportunityType(
                                       'available_opportunities',
                                     );
                                     setSearchQry('');
                                     setCurrentComponent('opportunities');
                                    
                                     
                                     getAllOpportunities(
                                       'available_opportunities',
                                       '',
                                       '',
                                       1,
                                     );
                                     setIsAnswers(false);
                                     if (
                                       ['inviteUser', 'addQuestion'].includes(
                                         showProperComponet,
                                       )
                                     ) {
                                       setProperComponent('');
                                     }
                                   }}>
                                   {
                                     labels.gallery_type_opportunities[
                                       selected_lang
                                     ]
                                   }
                                 </Button>
                               </li>
                               <li>
                                 <Button
                                   className={
                                     currentComponent === 'qa' ? 'active' : ''
                                   }
                                   onClick={() => {
                                     if (
                                       currentComponent === 'opportunities'
                                     ) {
                                       setshowLoader(true);
                                       setShowSpinner(true);
                                     }
                                     getQuestionList('questions', '', 1);
                                     setSearchQry('');
                                     setCurrentComponent('qa');
                                   
                                     handClick();
                                    
                                     setIsAnswers(false);
                                     if (
                                       ['inviteUser', 'addQuestion'].includes(
                                         showProperComponet,
                                       )
                                     ) {
                                       setProperComponent('');
                                     }
                                   }}>
                                   {labels.gallery_type_qa[selected_lang]}
                                 </Button>
                               </li>
                             </ul>
                           </div>
                           <div className="search-tabs-icons">
                             {currentComponent !== 'news' && (
                               <span
                                 className={
                                   ['inviteUser', 'addQuestion'].includes(
                                     showProperComponet,
                                   )
                                     ? 'icon active'
                                     : 'icon'
                                 }
                                 onClick={() => {
                                   setProperComponent('');
                                   if (currentComponent === 'opportunities') {
                                     handleCreateOppo();
                                   
                                     setCreateSessionEvent(false);
                                   } else {
                               
                                     if (showProperComponet === 'createNews') {
                                       setProperComponent('');
                                     } else {
                                       setProperComponent('createNews');
                                     }
                                     
                                     setCreateSessionEvent(false);
                                   }
                                 }}>
                                 <i className="icon-plus" />
                               </span>
                             )}
                             
                             {currentComponent === 'opportunities' && (
                               <span
                                 onClick={() => {
                                   if (
                                     opportunityType ===
                                     'available_opportunities'
                                   ) {
                                     setOpportunityType(
                                       'pending_opportunities',
                                     );
                                   } else {
                                     setOpportunityType(
                                       'available_opportunities',
                                     );
                                   }
                                   setSearchQry('');
                                   setIsOpportunity(!isOpportunity);
                                   setPage(1);
                                 }}
                                 className={
                                   isOpportunity ? 'icon active' : 'icon'
                                 }>
                                 <i className="icon-plane"></i>
                               </span>
                             )}
                             <span
                               onClick={() => {
                                 setThreeDots(!threeDots);
                               }}
                               className={threeDots ? 'icon active' : 'icon'}>
                               <i className="fas fa-ellipsis-vertical" />
                             </span>
                           </div>
                         </div> */}

                   {threeDots && currentComponent === 'opportunities' && (
                     <div className="search-form d-flex">
                       <Form.Group className="search-input">
                         <Form.Control
                           onChange={e => {
                             setSearchQry(e.target.value);
                             getAllOpportunities(
                               opportunityType,
                               e.target.value,
                               paid,
                               1,
                             );
                           }}
                           type="text"
                           placeholder="Search for a Recruitments"
                           value={searchQry}
                         />
                       </Form.Group>
                       {!isOpportunity && (
                         <Button
                           style={{
                             color:
                               showProperComponet === 'mapButton'
                                 ? '#68b245'
                                 : '',
                           }}
                           onClick={() => {
                             // setMapButton(!mapButton);
                             if (showProperComponet === 'mapButton') {
                               setProperComponent('');
                             } else {
                               setProperComponent('mapButton');
                             }
                           }}>
                           <i className="icon-map-pin" />
                         </Button>
                       )}
                       <Button
                         // className={isActive ?'icon active': 'icon'}
                         className={isActive ? 'active' : ''}
                         onClick={() => {
                           setSearchFilterModal(true);
                           getFrequency();
                           getOpp();
                           handleClick();
                         }}>
                         <i className="icon-filter-icon" />
                       </Button>
                     </div>
                   )}
                   {threeDots && currentComponent === 'news' && (
                     <>
                       {workSpaceItem.length > 1 && (
                         <div
                           className="select-wrapper custom-dropdown as-link"
                           onClick={() => {
                             if (workSpaceItem.length > 1) {
                               setShowModel('work-place-list');
                             } else {
                             }
                           }}>
                           <p className="p-reg m-0 single-line-text">
                             {isCheckValueWorkSpace.length > 0
                               ? formatingArray(isCheckValueWorkSpace).join(
                                   ', ',
                                 )
                               : ''}
                           </p>
                           {/* <Form.Label></Form.Label>
                                     <Form.Control
                                       className="as-link"
                                       readOnly={'readOnly'}
                                       value={
                                         isCheckValueWorkSpace.length > 0
                                           ? isCheckValueWorkSpace.join(', ')
                                           : ''
                                       }
                                       defaultValue={
                                         isCheckValueWorkSpace.length > 0
                                           ? isCheckValueWorkSpace.join(', ')
                                           : ''
                                       }
                                       onClick={() => {
                                         if (workSpaceItem.length > 1) {
                                           setShowModel('work-place-list');
                                         } else {
                                           
                                         }
                                       }}
                                     /> */}
                           {/* <select
                                     onChange={e => {
                                       setWorkSpaceDropDownIds(e.target.value);
                                     }}
                                     className="form-control">
                                     {Object.entries(workSpaceItem).length >
                                       0 &&
                                       workSpaceItem.map((item, index) => (
                                         <option
                                           // id={item}
                                           value={item.id}
                                           key={`hehe-${index}`}>
                                           {item.name}
                                         </option>
                                       ))}
                                   </select> */}
                         </div>
                       )}
                       {/* <div className="category-btns-wrapper category-btns-wrapper-slider mt-3 mb-3">
                               <CustomSlidingButtons
                                 items={listForDropDown}
                                 activeButtons={areas_Filter}
                                 onItemClick={e => {
                                   dropDownSelection(e);
                                 }}
                                 showItems={2}
                               />
                             </div> */}
                     </>
                   )}
                   {/* {threeDots && currentComponent === 'qa' && (
                           <div className="search-form d-flex">
                             <Form.Group className="search-input">
                               <Form.Control
                                 onChange={e => {
                                   setSearchQry(e.target.value);
                                   if (isAnswers) {
                                     getQuestionList(
                                       'answers',
                                       e.target.value,
                                       1,
                                     );
                                   } else {
                                     getQuestionList(
                                       'questions',
                                       e.target.value,
                                       1,
                                     );
                                   }
                                 }}
                                 type="text"
                                 placeholder="Search for a Q&A"
                                 value={searchQry}
                               />
                             </Form.Group>
                           </div>
                         )} */}
                 </div>

                 {currentComponent === 'opportunities' && (
                   <>
                     <div className="refferal-list-wrapper">
                       {showSpinner && <LoaderSpinner />}
                       <Scrollbars
                         autoHide
                         onScroll={handleScroll}
                         style={{
                           width: '100%',
                           flex: 1,
                         }}>
                         <div className="opportunities-list-wrapper">
                           {Object.entries(opportunityData.listing).length >
                           0 ? (
                             Object.entries(opportunityData.listing).length >
                               0 &&
                             opportunityData.listing.map((item, index) => (
                               <OppotunityItem
                                 key={`opportunity-item-${index}`}
                                 setShowButton={setShowButton}
                                 onShare={() => {
                                   setProperComponent('refer-opportunity');
                                 }}
                                 setChatData={setChatData}
                                 handleItem={handleItem}
                                 item={item}
                               />
                             ))
                           ) : (
                             <div className="no-results-wrapper">
                               <div className="img-bg-wrapper">
                                 <img
                                   src={require('../../Assets/images/norecord/opportunity.png')}
                                   alt=""
                                 />
                               </div>

                               <div className="no-results-content">
                                 <h2>
                                   {labels.Share_Opportunity[selected_lang]}
                                 </h2>
                                 <p>{labels.Someone_Waiting[selected_lang]}</p>

                                 <div className="btn-wrapper mt-3">
                                   <Button
                                     className="btn btn-dark"
                                     onClick={() => {
                                       // setInviteUser(true);
                                       setProperComponent('inviteUser');
                                     }}>
                                     {labels.Create_Opportunity[selected_lang]}
                                   </Button>
                                 </div>
                               </div>
                             </div>
                           )}
                         </div>
                         {/* {showBottomSpinner && (
                           <LoaderSpinner className="spinner-center" />
                         )} */}
                       </Scrollbars>

                       {isLoading && <ScrollLoader />}
                     </div>
                   </>
                 )}

                
                 {currentComponent === 'news' && (
                   <>
                     <ShareAblesList
                       workSpaceDropDownIds={isCheckWorkSpace}
                       shareUrl={shareUrl}
                       model={model}
                       setModel={setModel}
                       ShowModelLinks={ShowModelLinks}
                       setShareAbleItem={setShareAbleItem}
                       setType={setType}
                       dropdownValue={dropdownValue}
                       setdropdownValue={setdropdownValue}
                       setProperComponent={setProperComponent}
                       threeDots={threeDots}
                       setShowModel={setShowModel}
                       ShareAbleRefer={ShareAbleRefer}
                       setShowComponent={setShowComponent}
                       sessionCatgory={sessionFilter}
                       snapshotFilter={snapshotFilter}
                       opportunityFilter={opportunityFilter}
                       networkFilter={networkFilter}
                       workspaceFilter={workSpaceIds.toString(',')}
                     />
                   </>
                 )}
               </>



            //  </div>
           )}

           {searchFilter && fun && loader && opp && frequency.length > 0 && (
             <OpportunitiesSearchFilter
               show={searchFilter}
               onClose={() => setSearchFilterModal(false)}
               frequency={frequency}
               opp={opp}
               fun={fun}
               loader={loader}
               locationS={locationS}
               setLocationS={setLocationS}
               isCheckAll={isCheckAll}
               setIsCheckAll={setIsCheckAll}
               isCheck={isCheck}
               setIsCheck={setIsCheck}
               list={list}
               setList={setList}
               freq={freq}
               setFreq={setFreq}
               virtual={virtual}
               setVirtual={setVirtual}
               covid={covid}
               setCovid={setCovid}
               amount={amount}
               setAmount={setAmount}
               setActive={setActive}
               active={active}
               setFocusLocations={setFocusLocations}
               setIdentiferId={setIdentiferId}
               setEthnicityId={setEthnicityId}
               setGender={setGender}
               gender={gender}
               focusLocations={focusLocations}
               ethnicityId={ethnicityId}
               identiferId={identiferId}
               Longitude={Longitude}
               setLongitude={setLongitude}
               setLatitude={setLatitude}
               Latitude={Latitude}
               setPersnalTypesId={setPersnalTypesId}
               setCategoriesId={setCategoriesId}
               setAppointmentId={setAppointmentId}
               persnalTypesId={persnalTypesId}
               appointmentId={appointmentId}
               categoriesId={categoriesId}
               opportunityType={opportunityType}
               searchQry={searchQry}
               setOpportunityFilter={setOpportunityFilter}
             />
           )}

           {showComponent === 'opportunity' && (
             <OpportunitiesSearchFilter
               show={true}
               onClose={() => setShowComponent('')}
               frequency={frequency}
               opp={opp}
               fun={fun}
               loader={loader}
               locationS={locationS}
               setLocationS={setLocationS}
               isCheckAll={isCheckAll}
               setIsCheckAll={setIsCheckAll}
               isCheck={isCheck}
               setIsCheck={setIsCheck}
               list={list}
               setList={setList}
               freq={freq}
               setFreq={setFreq}
               virtual={virtual}
               setVirtual={setVirtual}
               covid={covid}
               setCovid={setCovid}
               amount={amount}
               setAmount={setAmount}
               setActive={setActive}
               active={active}
               setFocusLocations={setFocusLocations}
               setIdentiferId={setIdentiferId}
               setEthnicityId={setEthnicityId}
               setGender={setGender}
               gender={gender}
               focusLocations={focusLocations}
               ethnicityId={ethnicityId}
               identiferId={identiferId}
               Longitude={Longitude}
               setLongitude={setLongitude}
               setLatitude={setLatitude}
               Latitude={Latitude}
               setPersnalTypesId={setPersnalTypesId}
               setCategoriesId={setCategoriesId}
               setAppointmentId={setAppointmentId}
               persnalTypesId={persnalTypesId}
               appointmentId={appointmentId}
               categoriesId={categoriesId}
               opportunityType={opportunityType}
               searchQry={searchQry}
               shareableFilter={true}
               setOpportunityFilter={setOpportunityFilter}
             />
           )}

           {showComponent === 'session' && (
             <SessionSearchFilter
               show={true}
               onClose={() => setShowComponent('')}
               sessionType={''}
               typeIs={''}
               fun={''}
               isCheckAll={isCheckAll}
               setIsCheckAll={setIsCheckAll}
               isCheck={isCheck}
               setIsCheck={setIsCheck}
               list={list}
               setList={setList}
               active={''}
               setActive={''}
               pageNo={''}
               setFocusLocations={''}
               setIdentiferId={''}
               setEthnicityId={''}
               setGender={''}
               gender={''}
               focusLocations={''}
               ethnicityId={''}
               identiferId={''}
               setPersnalTypesId={''}
               setCategoriesId={setCategoriesId}
               setAppointmentId={''}
               persnalTypesId={''}
               appointmentId={''}
               categoriesId={categoriesId}
               shareAbleType={'stop'}
               setSessionFilter={setSessionFilter}
             />
           )}

           {showComponent === 'snapshot' && (
             <SnapShotFilter
               setSnapshotFilter={setSnapshotFilter}
               onClose={() => setShowComponent('')}
             />
           )}
           {showComponent === 'network' && (
             <RefferalSearchFilter
               show={true}
               onClose={() => setShowComponent('')}
               subTabType={''}
               builderData={''}
               arrowButton={''}
               isCheckAll={isCheckAll}
               setIsCheckAll={setIsCheckAll}
               isCheck={isCheckSF}
               isCheckArea={isCheck}
               setIsCheck={setIsCheckSF}
               list={list}
               setList={setList}
               locationS={locationS}
               setLocationS={setLocationS}
               appointmentId={appointmentId}
               setAppointmentId={setAppointmentId}
               categoriesId={categoriesId}
               setCategoriesId={setCategoriesId}
               setActive={''}
               active={''}
               setFocusLocations={''}
               setIdentiferId={''}
               setEthnicityId={''}
               setGender={''}
               gender={''}
               focusLocations={''}
               ethnicityId={''}
               identiferId={''}
               setLongitude={setLongitude}
               longitude={Longitude}
               setLatitude={setLatitude}
               latitude={Latitude}
               setPersnalTypesId={setPersnalTypesId}
               setCategoriesId_Focus={''}
               setAppointmentId_Focus={''}
               setIdenntifier_Filter={setIdenntifier_Filter}
               setAreas_Filter={setAreas_Filter}
               idenntifier_Filter={idenntifier_Filter}
               areas_Filter={areas_Filter}
               persnalTypesId={persnalTypesId}
               appointmentId_Focus={''}
               categoriesId_Focus={''}
               networkFilter={true}
               SetNetworkFilter={SetNetworkFilter}
             />
           )}
           {showComponent === 'workspace' && (
             <PersonalModal
               list={[]}
               setWorkspace={setWorkspace}
               workspace={workspace}
               type={'shareabales'}
               setWorkSpaceIds={setWorkSpaceIds}
               onClose={() => {
                 setShowComponent('');
               }}
             />
           )}

           {showModel === 'work-place-list' && (
             <CustomModal
               className="shareables-workspace-modal"
               size="small"
               onClose={() => setShowModel('')}>
               <div className="filter-wrapper">
                 <div className="filter-category-list">
                   <ul>
                     {Object.entries(workSpaceItem).length > 0 && (
                       <>
                         {workSpaceItem
                           .filter(item => item.title === 'Personal')
                           .map((item, index) => (
                             <li key={`sessionC-${index}`}>
                               <Form.Label>
                                 {item.name}
                                 <input
                                   type="checkbox"
                                   id={item.id}
                                   value={item.name}
                                   onChange={e => {
                                     handleClickList(e);
                                   }}
                                   checked={
                                     isCheckWorkSpace.includes(item.id) ||
                                     isCheckValueWorkSpace.includes(item.title)
                                   }
                                 />
                                 <span />
                               </Form.Label>
                             </li>
                           ))}

                         {Object.entries(workSpaceItem).length > 0 && (
                           <>
                             {workSpaceItem
                               .slice() // Create a copy to avoid modifying the original array
                               .sort((a, b) => {
                                 if (a.name === 'Personal') return -1;
                                 if (b.name === 'Personal') return 1;
                                 return 0;
                               })
                               .map((item, index) => (
                                 <li key={`sessionC-${index}`}>
                                   <Form.Label>
                                     {item.name}
                                     <input
                                       type="checkbox"
                                       id={item.id}
                                       value={item.name}
                                       onChange={e => {
                                         handleClickList(e);
                                       }}
                                       checked={
                                         isCheckWorkSpace.includes(item.id) ||
                                         isCheckValueWorkSpace.includes(
                                           item.title,
                                         )
                                       }
                                     />
                                     <span />
                                   </Form.Label>
                                 </li>
                               ))}
                           </>
                         )}
                       </>
                     )}
                   </ul>
                   <Button
                     type="submit"
                     className="btn-dark"
                     onClick={() => {
                       setShowModel('');
                     }}>
                     Update
                   </Button>
                 </div>
               </div>
             </CustomModal>
           )}
           {showModel === 'preview' && shareAbleItem && (
             <CustomModal
               size="medium"
               onClose={() => {
                 setShowModel('');
               }}>
               <div className="new-session-modal-wrapper text-left shareablesPreview_Modal m-0 w-100">
                 <div className="tabbed-modal-wrapper">
                   <div className="tab-buttons">
                     <button
                       className={`tab-button ${
                         activeTab === 'App' ? 'active' : ''
                       }`}
                       onClick={() => handleTabClick('App')}>
                       App
                     </button>
                     <button
                       className={`tab-button ${
                         activeTab === 'Email' ? 'active' : ''
                       }`}
                       onClick={() => handleTabClick('Email')}>
                       Email
                     </button>
                     <button
                       className={`tab-button ${
                         activeTab === 'Text' ? 'active' : ''
                       }`}
                       onClick={() => handleTabClick('Text')}>
                       Text
                     </button>
                   </div>
                   <div className="tab-content">
                     {/* {tabData[activeTab]} */}

                     {activeTab === 'App' && (
                       <div className="content-wapper">
                         <div className="shareablesEvent-Image">
                           <img src={showData('image', shareAbleItem, type)} alt="" />
                         </div>
                         <div className="shareables-eventContent">
                           <div className="d-flex align-items-center justify-content-between py-2">
                             <p className="p-reg date-time m-0">
                               {showData('date', shareAbleItem, type)}
                             </p>
                             <p className="p-reg location m-0">
                               {showData('location', shareAbleItem, type)}
                             </p>
                           </div>
                           <p className="p-reg description truncate_2_lines">
                             {showData('title', shareAbleItem, type)}
                           </p>
                         </div>
                       </div>
                     )}

                     {activeTab === 'Email' && (
                       <div className="content-wapper">
                         <div className="shareablesEvent-Image">
                           <img src={showData('image', shareAbleItem, type)} alt="" />
                         </div>
                         <div className="shareables-eventContent">
                           <div className="d-flex align-items-center justify-content-between py-2">
                             <p className="p-reg date-time m-0">
                               {showData('date', shareAbleItem, type)}
                             </p>
                             <p className="p-reg location m-0">
                               {showData('location', shareAbleItem, type)}
                             </p>
                           </div>
                           <p className="p-reg description truncate_2_lines">
                             {showData('title', shareAbleItem, type)}
                           </p>
                         </div>
                       </div>
                     )}

                     {activeTab === 'Text' && (
                       <div className="content-wapper">
                         <div className="shareablesEvent-Image">
                           <img src={showData('image', shareAbleItem, type)} alt="" />
                         </div>
                         <div className="shareables-eventContent">
                           <div className="d-flex align-items-center justify-content-between py-2">
                             <p className="p-reg date-time m-0">
                               {showData('date', shareAbleItem, type)}
                             </p>
                             <p className="p-reg location m-0">
                               {showData('location', shareAbleItem, type)}
                             </p>
                           </div>
                           <p className="p-reg description truncate_2_lines">
                             {showData('description', shareAbleItem, type)}
                           </p>
                         </div>
                       </div>
                     )}
                     <div className="shareables-preview-footer">
                       <span>
                         <img
                           className="ScanQr as-link"
                           src={ShareYellowIcon}
                           alt="scanQr"
                           onClick={() => {
                             ShareAbleRefer(type, shareAbleItem);
                           }}
                         />
                       </span>
                       <span>
                         <img
                           className="sharableYellow as-link"
                           src={ShareablesYellowIcon}
                           alt="shareable"
                           onClick={() => {
                             ShowModelLinks('link_model', type, shareAbleItem);
                           }}
                         />
                       </span>
                     </div>
                   </div>
                 </div>
                 {/* <div className="img-bg-wrapper">
                         <img
                           src={require('../../Assets/images/norecord/session.png')}
                           alt="no-session"
                         />
                       </div> */}
               </div>
             </CustomModal>
           )}

           {model === 'link_model' && (
             <CustomModal
               position="bottom"
               className={'share-links-modal'}
               onClose={() => setModel('')}>
               <div className="camera-options-list w-100 social-links-modal">
                 <ul>
                   <li className="as-link">
                     <FacebookShareButton url={shareUrl} title={false}>
                       <i className="fa-brands fa-facebook"></i>
                     </FacebookShareButton>
                   </li>
                   <li className="as-link">
                     <WhatsappShareButton url={shareUrl}>
                       <i className="fa-brands fa-whatsapp"></i>
                     </WhatsappShareButton>
                   </li>
                   <li className="as-link">
                     <EmailShareButton url={shareUrl}>
                       <i className="fa-solid fa-envelope"></i>
                     </EmailShareButton>
                   </li>
                   <li className="as-link">
                     <TwitterShareButton url={shareUrl}>
                       <i className="fa-brands fa-twitter"></i>
                     </TwitterShareButton>
                   </li>
                   <li className="as-link">
                     <LinkedinShareButton url={shareUrl}>
                       <i className="fa-brands fa-linkedin"></i>
                     </LinkedinShareButton>
                   </li>
                 </ul>
               </div>
             </CustomModal>
           )}
           {showProperComponet !== '' && (
             <div className="secondary-content-wrapper">
               {showProperComponet === 'shareAble_snapShot' && (
                 <ReferSnapshot
                   onClose={() => {
                     setProperComponent('');
                   }}
                   userData={item}
                   snapshotData={item}
                 />
               )}
               {showProperComponet === 'shareAble_Profile' && (
                 <ReferProfile
                   userData={item}
                   onClose={() => {
                     setProperComponent('');
                   }}
                 />
               )}
               {showProperComponet === 'shareAble_refer' && (
                 <ReferOpportunity
                   userData={item}
                   onClose={() => {
                     setProperComponent('');
                   }}
                   type={typeOfShareAbles}
                 />
               )}
               {showProperComponet === 'other-opportunity' && (
                 // <SlidingPanel
                 //   hidePanel={() => {
                 //     dispatch(
                 //       getOpportunityListing(opportunityData.payload),
                 //     );
                 //     setShowButton(false);
                 //     setProperComponent('');
                 //   }}>
                 <SmartChatPage
                   type={'other-opportunity'}
                   allmsg={true}
                   chatroomInfo={chatroomInfoOppo(item, 'opportunity')}
                   item={[]}
                   oppoDetails={item}
                   chatDetails={item.chat_detail}
                   allChatUsers={collectingOppoUsers(item)}
                   chatRoomName={
                     item.chat_detail.hasOwnProperty('chat_room_name')
                       ? item.chat_detail.chat_room_name
                       : ''
                   }
                   chatRoomId={
                     item.chat_detail.hasOwnProperty('id')
                       ? item.chat_detail.id
                       : ''
                   }
                   onClose={() => {
                     dispatch(getOpportunityListing(opportunityData.payload));
                     setShowButton(false);
                     setProperComponent('');
                   }}
                   userData={
                     item.hasOwnProperty('creator_details')
                       ? item.creator_details
                       : item.creator_detail
                   }
                   chatRoomStatus={newDecision()}
                   // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
                 />
                 // </SlidingPanel>
               )}

               {showProperComponet === 'AllBuilders' && (
                 // <SlidingPanel
                 //   hidePanel={() => {
                 //     setProperComponent('');
                 //   }}>
                 //   <Scrollbars>
                 <div className="pb-4">
                   <AllBuilders
                     onClose={() => {
                       setProperComponent('');
                       // toggleHiddenPanel(!showHiddenPanel);
                     }}
                   />
                 </div>
                 //   </Scrollbars>
                 // </SlidingPanel>
               )}

               {showProperComponet === 'mine-opportunity' && (
                 <OpportunityMessageList
                   item={item}
                   // setOppoDetails={setOppoDetails}
                   // setAllChatUsers={setAllChatUsers}
                   // setChatRoomId={setChatRoomId}
                   // setChatRoomName={setChatRoomName}
                   // setChatRoomStatus={setChatRoomStatus}
                   onClose={() => {
                     dispatch(getOpportunityListing(opportunityData.payload));
                     setShowButton(false);
                     setProperComponent('');
                   }}
                   onEditOppo={() => {
                     setProperComponent('fromOppoMsg'); // temparary majbori
                     // setInviteUser(true);
                     setCreateSessionEvent(false);
                   }}
                   onCreateSessionEvent={() => {
                     setCreateSessionEvent(true);
                     // setInviteUser(false);
                     setProperComponent('');
                   }}
                   createSessionEvent={createSessionEvent}
                   onShowChat={() => {
                     setProperComponent('show-chats');
                   }}

                   chatData={chatData}
                   showButton={showButton}
                 />
               )}

               {/* {chatRoomName !== '' &&
                   allChatUsers.length > 0 &&
                   showProperComponet === 'show-chats' && (
                     <>
                      
                       <SmartChatPage
                         allmsg={true}
                         type={'mine-opportunity'}
                         item={item}
                         chatroomInfo={chatroomInfoOppoMsgList(
                           oppoDetails,
                           'opportunity',
                         )}
                         oppoDetails={oppoDetails}
                         allChatUsers={allChatUsers}
                         chatDetails={oppoDetails.chat_detail}
                         chatRoomName={chatRoomName}
                         chatRoomId={chatRoomId}
                         chatRoomStatus={chatRoomStatus}
                         onClose={() => {
                           setChatRoomName('');
                           setShowButton(true);
                           setProperComponent('mine-opportunity');
                         }}
                       />
               
                     </>
                   )} */}

               {showProperComponet === 'show-qa-chat' &&
                 Object.entries(qaItem) && (
                   // <SlidingPanel
                   //   hidePanel={() => {
                   //     setProperComponent('');
                   //   }}>
                   <SmartChatPage
                     allmsg={true}
                     type={'qa-chats'}
                     item={item}
                     chatroomInfo={chatroomInfo(qaItem)}
                     oppoDetails={qaItem}
                     allChatUsers={collectingUsers(qaItem)}
                     chatDetails={qaItem}
                     chatRoomName={qaItem.chat_room_name}
                     // chatRoomId={qaItem.chat_id}
                     chatRoomId={qaItem.id}
                     chatRoomStatus={qaItem.chat_room_status}
                     onClose={() => {
                       setProperComponent('');
                     }}
                     handleCommonChat={(data, type) => {
                       handleCommonChat(data, type);
                     }}
                   />
                   // </SlidingPanel>
                 )}

               {showProperComponet === 'new-opportunity' && (
                 // <SlidingPanel
                 //   hidePanel={() => {
                 //     dispatch(
                 //       getOpportunityListing(opportunityData.payload),
                 //     );
                 //     setShowButton(false);
                 //     setProperComponent('');
                 //   }}>
                 <SmartChatPage
                   type={'new-opportunity'}
                   allmsg={true}
                   item={[]}
                   oppoDetails={item}
                   chatDetails={item.chat_detail}
                   chatroomInfo={chatroomInfoItem(item)}
                   allChatUsers={
                     item.hasOwnProperty('creator_details')
                       ? [item.creator_details]
                       : [item.creator_detail]
                   }
                   chatRoomName={
                     item.chat_detail.hasOwnProperty('chat_room_name')
                       ? item.chat_detail.chat_room_name
                       : ''
                   }
                   chatRoomId={
                     item.chat_detail.hasOwnProperty('id')
                       ? item.chat_detail.id
                       : ''
                   }
                   onClose={() => {
                     dispatch(getOpportunityListing(opportunityData.payload));
                     setShowButton(false);
                     setProperComponent('');
                   }}
                   userData={
                     item.hasOwnProperty('creator_details')
                       ? item.creator_details
                       : item.creator_detail
                   }
                   chatRoomStatus={
                     item.chat_detail.hasOwnProperty('status')
                       ? item.chat_detail.status
                       : 'pending'
                   }
                   // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
                 />
                 // </SlidingPanel>
               )}

               {showProperComponet === 'refer-opportunity' && (
                 <ReferOpportunity
                   userData={item}
                   onClose={() => {
                     setProperComponent('');
                   }}
                   type={
                     currentComponent === 'qa' ? 'question' : 'opportunity'
                   }
                 />
               )}

               {showProperComponet === 'respond-opportunity' && (
                 <OpportunityDetail
                   item={item}
                   userData={item.creator_details}
                   onClose={() => {
                     setShowButton(false);
                     setProperComponent('');
                   }}
                 />
               )}
               {showProperComponet === 'inviteOutSideTheBuild' && (
                 <SendInvitation
                   onClose={() => {
                     setProperComponent('');
                   }}
                 />
               )}
               {buildersUserId &&
                 showProperComponet === 'profileComponent' && (
                   <div className="pb-4 fix-width-forms">
                     <ProfileComponent
                       userId={buildersUserId}
                       setBuildersUserId={setBuildersUserId}
                       show={false}
                       onClose={() => {
                         setProperComponent('');
                       }}
                       // hideCloseButton={true}
                       button={button}
                       setButton={setButton}
                       setLocation={setLocation}
                       setLatitudeS={setLatitudeS}
                       setLongitudeS={setLongitudeS}
                       userData={[]}
                       moderator={false}
                       // setParticipantType={setParticipantType}
                       VoxeetSDK={[]}
                       conferenceInfo={[]}
                       jdSpecialM={[]}
                       jdSpecialA={[]}
                     />
                   </div>
                 )}
               {showProperComponet === 'mapButton' && (
                 <>
                   <OpportunityMap
                     showProperComponet={showProperComponet}
                     onClose={() => {
                       setProperComponent('');
                     }}


                     //temporary warnings for remove warnings
                     setIsAnswers={setIsAnswers}
                     setPaid={setPaid}
                     setThreeDots={setThreeDots}
                     setOpportunityType={setOpportunityType}
                     setButton={setButton}
                    
                     oppoValue={oppoValue}
                   />
                   {/* <div className="location-field form-control">
                           <GooglePlacesAutocomplete
                             location
                             selectProps={{
                               location,
                               onChange: setLocation,
                               name: 'city',
                               placeholder: 'Type city and state',
                               classNames: 'hello-test',
                             }}
                           />
                         </div>

                         <div
                           ref={googleMapRef}
                           className="mt-4"
                           style={{width: '100%', height: '100%'}}
                         /> */}

                   {/* <div style={{ height: "100vh", width: "100%" }}>
                         <GooglePlacesAutocomplete
                           // location
                           selectProps={{
                             // location,
                             // onChange: setLocation,
                             name: "city",
                             placeholder: "Type city and state",
                           }}
                         />
                         {lat && lug && <GoogleMapReact
                         
                         //   defaultCenter={defaultProps.center}
                           
                         center={{lat: lat , lng: lug }}
                         // center={{lat: 31.520370, lng: 74.358749 }}
                           defaultZoom={11}
                           yesIWantToUseGoogleMapApiInternals
                           onGoogleApiLoaded={({ map, maps }) =>
                             handleApiLoaded(map, maps)
                           }
                           
                         > */}

                   {/* <AnyReactComponent
                         lat = {lat} 
                         lng = {lug}                       
                         text='My Marker'
                       />  
                     </GoogleMapReact>}
                   </div>
                 ) : (
                   <></>
                 )}  */}
                 </>
               )}

               {showProperComponet === 'inviteUser' && (
                 <EditOpportunities
                   onClose={() => {
                     // setInviteUser(false);
                     setProperComponent('');
                   }}
                   item={item}
                 />
               )}
               {showProperComponet === 'fromOppoMsg' && (
                 <SlidingPanel
                   hidePanel={() => {
                     // setInviteUser(false);
                     // setProperComponent('');
                     setProperComponent('mine-opportunity');
                   }}>
                   <Scrollbars>
                     <EditOpportunities
                       onClose={() => {
                         // setInviteUser(false);
                         // setProperComponent('');
                         setProperComponent('mine-opportunity');
                       }}
                       item={item}
                     />
                   </Scrollbars>
                 </SlidingPanel>
               )}

               {/* {createSessionEvent && (
                   <div className="pb-4 fix-width-forms">
                     <CreateSession
                       onClose={() => {
                         setCreateSessionEvent(false);
                       }}
                       opportunitySession={item}
                       showCloseIcon={true}
                     />
                   </div>
                 )} */}
               
               {showProperComponet === 'createNews' && (
                 <CreateNews
                   onClose={() => {
                     setProperComponent('');
                   }}
                 />
               )}
               {showProperComponet === 'create-snapshot' && (
                 <AddNewSnapShot
                   onClose={() => {
                     setProperComponent('');
                   }}
                 />
               )}
               {showProperComponet === 'createGroup' && (
                 // <div className="pb-4 fix-width-forms">

                 <CreateGroup
                   setGroupChat={setItem}
                   groupChat={item}
                   onClose={() => {
                     // setCreateGroup(false);
                     setProperComponent('');
                   }}
                   editGroupChat={
                     showProperComponet === 'createGroup' ? true : false
                   }
                 />
                 // </div>
               )}
             </div>
           )}

           {showProperComponet === 'WorkSpace' && (
             <CreateWorkSpace
               onClose={() => {
                 setProperComponent('');
               }}
             />
           )}
         </div>
        
         
        </div>
        {/* {frequency &&(
                 <OpportunitiesSearchFilter show={frequency} />
            )} */}
      </div>
    </>
  );
};

// const ShareAbles = props => {
//     const [selectedAud, setSelectedAud] = useState(false);
//     const [topic, setTopic] = useState([]);
//     const [topicId, setTopicId] = useState('');
//     const [showLoader, setshowLoader] = useState(false);
//     const [feedBack, setFeedBack] = useState('');

//     const [isFloated, toggleFloatedPanel] = useState(false);
//     const [showHiddenPanel, toggleHiddenPanel] = useState(false);
//     const [validated, setValidated] = useState(false);
//     const [showComponent, setShowComponent] = useState(false);
//     const [workspaceData, setWorkspaceData] = useState([]);

//     return (
//       <>
//         <div className="page-wrapper">
//           <Sidebar
//             location={props.location}
//             setShowComponent={setShowComponent}
//             setWorkSpaceItem={setWorkspaceData}
//             showComponent={showComponent}
//           />
//           <div className="page-content-wrapper">
//             <Pannels
//               isFloated={isFloated}
//               showHiddenPanel={showHiddenPanel}
//               updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
//               primary={
//                 <div className="flex items-center justify-center h-screen bg-white">
//                   <div className="text-center">
//                     <svg
//                       className="w-16 h-16 mx-auto mb-4 text-green-500"
//                       fill="none"
//                       stroke="currentColor"
//                       viewBox="0 0 24 24"
//                       xmlns="http://www.w3.org/2000/svg">
//                       <path
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                         strokeWidth="2"
//                         d="M5 13l4 4L19 7"></path>
//                     </svg>
//                     <h1 className="text-2xl font-semibold text-gray-800">
//                       No Records Found
//                     </h1>
//                     <p className="text-gray-600">
//                       Sorry, we couldn't find any records against this page.
//                     </p>
//                   </div>
//                 </div>
//               }
//               secondary={<></>}
//             />
//           </div>
//         </div>
//       </>
//     );
//   }

export default ShareAbles;
