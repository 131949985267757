import moment from 'moment';
export const getTimeFormatForChat = time => {
  const sourceTime = new Date(time);
  const options = {
    timeZone: 'America/New_York',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const newYorkTime = sourceTime.toLocaleString('en-US', options);
  return newYorkTime;
};
export const getDateFormatForChat = date => {
  return moment(date).format('YYYY-MM-DD').toString();
};
