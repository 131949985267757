import React, {useState, useEffect} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import commonApi from '../../../Services/CommonService';
import {
  CustomModal,
  CustomSlidingButtons,
  Pannels,
  RefferalSearchFilter,
} from '../../../Components';
import {labels} from '../../../Constants/Translations';
import {selected_lang} from '../../../Utils/env';
import Sidebar from '../../Sidebar';
import LoaderSpinner from '../../../Components/Modals/LoaderSpinner';
import ProfileComponent from '../../../Components/ProfilePage/ProfileComponent';
import CreatCircleList from '../../../Components/Circle/CreatCircleList';
import {errorMessage, successMessage} from '../../../Utils/ToastMessages';
import SendInvitation from '../StaticPages/SendInvitation';
import {DEFAULT_IMAGE} from '../../../Utils/env';
import {useHistory, useLocation} from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';
import {checkPermissions, fixName} from '../../../Utils/commonUtils';
import OpportunityScreen from '../../../Components/FrontScreens/OpportunityScreen';
import CreateWorkSpace from '../../../Components/WorkSpace/CreateWorkSpace';
import {SliderCircles} from '../../../Constants/AnalyticsConstants';
import Slider from 'react-slick';
import {useSelector} from 'react-redux';
import CustomDropdown from '../../../Components/CustomDropdown';

const Builders = props => {
  const [tabType, setTabType] = useState('circles');
  const [tabTypeSub, setTabTypeSub] = useState('exstingBuilders');
  const [builders, setbuilders] = useState([]);
  const [searchQry, setSearchQry] = useState('');
  const [areas, setAreas] = useState([]);
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckSF, setIsCheckSF] = useState([]);
  const [circleId, setCircleId] = useState('');
  const [showModaL, setShowModel] = useState(false);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [circles, setcircles] = useState([]);
  const [circleUser, setcircleUser] = useState([]);
  const [newCircleUser, setNewCircleUser] = useState([]);
  const [currentCircle, setCurrentCircle] = useState('');
  const [addToCircleUsers, setAddToCircleUsers] = useState([]);
  const [newToCircleUsers, setNewToCircleUsers] = useState([]);
  const [addToCircleUserList, setAddToCircleUserList] = useState([]);
  const [categoriesId_Focus, setCategoriesId_Focus] = useState([]);
  const [persnalTypesId, setPersnalTypesId] = useState([]);
  const [appointmentId_Focus, setAppointmentId_Focus] = useState([]);
  const [idenntifier_Filter, setIdenntifier_Filter] = useState([]);
  const [areas_Filter, setAreas_Filter] = useState([]);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [button, setButton] = useState(true);
  const [showSpinner, setShowSpinner] = useState(true);
  const [newUserId, setNewUserId] = useState('');
  const [filterButton, setFilterButton] = useState(false);
  const [appointmentId, setAppointmentId] = useState([]);
  const [categoriesId, setCategoriesId] = useState([]);
  const [locationS, setLocationS] = useState('');
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [userData, setUserData] = useState([]);
  const history = useHistory();
  const locationWeb = useLocation();
  const [list, setList] = useState([]);
  const [active, setActive] = useState(false);
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [identiferId, setIdentiferId] = useState([]);
  const [ethnicityId, setEthnicityId] = useState([]);
  const [gender, setGender] = useState([]);
  const [focusLocations, setFocusLocations] = useState([]);

  const menuPermesions = useSelector(state => state.menuPermesionList);

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const getAreas = () => {
    setShowSpinner(true);
    commonApi
      .get_areas()
      .then(response => {
        if (response) {
          setAreas(response.all_boarding_areas);
          setAreas_Filter(response.all_boarding_areas.map(item => item.id));
          setAreas_Filter(
            selectedValues(response.all_boarding_areas, 'id', 'selected'),
          );
        }
        // setShowSpinner(false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const selectedValues = (arr, idColName, selectColName) => {
    const returnArr = [];
    arr.forEach(item => {
      if (item[selectColName] === true || item[selectColName] === 'true') {
        returnArr.push(item[idColName]);
      }
    });
    return returnArr;
  };
  const getCircles = () => {
    // setShowSpinner(true);
    commonApi
      .get_circles()
      .then(response => {
        if (response) {
          setcircles(
            response.data.length > 0
              ? response.data.map(circle => ({
                  id: circle.circle_id,
                  circle_name: circle.circle_name,
                }))
              : [],
          );

          if (response.data.length) {
            setCircleId(response.data[0].circle_id);
            // getCirclesUsers(response.data[0].circle_id);
          }
        }
        // setShowSpinner(false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const builderData = h => {
    setbuilders(h);
    // setSaerchData(h);

    setShowSpinner(false);
  };
  const getCirclesUsers = id => {
    setShowSpinner(true);
    setCurrentCircle(id);
    setAddToCircleUserList([]);
    var data = {circle_id: id};
    commonApi
      .get_circle_users(data)
      .then(response => {
        if (response) {
          setcircleUser(response.data);
          setNewCircleUser(response.data);
        }
        setTimeout(() => {
          setShowSpinner(false);
        }, 500);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getBuilders = (sr_qry, area_ids) => {
    var adress =
      typeof props.locationS === 'object'
        ? props.locationS.label
        : typeof props.locationS === 'string'
        ? props.locationS
        : '';
    var data = {
      allies: active ? 'yes' : 'no',
      appointment_type_ids: appointmentId,
      // gender: gender.toString(),
      gender: '',
      otherIdentifires: identiferId.toString(),
      // ethnicities: ethnicityId.toString(),
      ethnicities: '',
      city_states: focusLocations,
      on_boarding_areas: persnalTypesId,
      appointment_types: appointmentId_Focus,
      on_boarding_categories: categoriesId_Focus,
      on_boarding_areas_filter: areas_Filter.toString(),
      other_identifier_filter: idenntifier_Filter.toString(),

      areas: area_ids,
      categories: categoriesId,
      hometown_location: adress,
      search_query: sr_qry,
      travel_location: locationS,
      latitude: longitude,
      longitude: longitude,
    };
    // allies: "no",
    // appointment_type_ids: ["1", "2"],
    // areas: [],
    // categories: ["15"],
    // hometown_location: "",
    // search_query    : "",
    // travel_location: "",
    commonApi
      .get_builders(data)
      .then(response => {
        if (response) {
          setbuilders(response.discover_builder);
        }
        setTimeout(() => {
          setShowSpinner(false);
        }, 500);
      })

      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getAddToCircleUsers = circleId => {
    setShowSpinner(true);

    var data = {
      chat_room_id: '',
      circle_id: circleId,
      search_query: '',
      user_id: localStorage.getItem('id'),
    };
    commonApi
      .excluded_followers_list(data)
      .then(res => {
        if (res) {
          setAddToCircleUsers(res.data);
          setNewToCircleUsers(res.data);
        }
        setShowSpinner(false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const addUserInCircle = () => {
    if (addToCircleUserList.length > 0) {
      var data = {
        circle_id: currentCircle,
        circle_name: '',
        user_ids: addToCircleUserList,
        workspace_ids: localStorage.getItem('workspaceIds'),
      };
      commonApi
        .create_circle(data)
        .then(res => {
          if (res) {
            setAddToCircleUserList([]);
            successMessage(res.message);
          }
        })
        .catch(err => {
          console.log('Err', err);
          return {type: 'error', message: err.message};
        });
    } else {
      errorMessage('Select user first');
    }
  };

  const handleChangeSearch = (array, query) => {
    setcircleUser(
      array.filter(el => {
        return el.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      }),
    );
  };
  const handleChangeSearchAdd = (array, query) => {
    setAddToCircleUsers(
      array.filter(el => {
        return el.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      }),
    );
  };

  const handleClick = e => {
    const {id} = e.target;
    if (areas_Filter && areas_Filter.length > 0 && areas_Filter.includes(id)) {
      setAreas_Filter(areas_Filter.filter(item => item !== id));
    } else {
      setAreas_Filter([...areas_Filter, id]);
    }
  };

  const handleUpdateCircleUsers = userId => {
    setAddToCircleUsers(addToCircleUsers.filter(item => item.id !== userId));
    if (!addToCircleUserList.includes(userId)) {
      setAddToCircleUserList([...addToCircleUserList, userId]);
    }
  };

  useEffect(() => {
    getBuilders(searchQry, areas_Filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areas_Filter]);
  useEffect(() => {
    if (circleId) {
      getCirclesUsers(circleId);
    }
  }, [circleId]);

  useEffect(() => {
    setShowSpinner(true);
    getTabstatus();
    getCircles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //createCircle

  useEffect(() => {
    if (
      locationWeb &&
      locationWeb.state &&
      locationWeb.state.typeIs === 'search'
    ) {
      if (
        history.location &&
        history.location.state &&
        history.location.state.typeIs
      ) {
        const state = {...history.location.state};
        delete state.typeIs;
        history.replace({...history.location, state});
      }
      // locationWeb.state = undefined;
      setTabType('search');
      // getApiSessionList(sessionType, "", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeCircleUser = () => {
    var data = {
      circle_id: circleId,
      user_ids: userData.id,
    };
    commonApi
      .remove_circle_user(data)
      .then(res => {
        if (res) {
          // setSnapshotData(res.accomplishment);
          // setShowSnapshot(true);
          getCirclesUsers(circleId);
          successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getProfileData = userId => {
    // getUserData(userId);
    // getSnapshotData(userId);
    // getUpcommingData(userId);
    // getOpportunityData(userId);

    setNewUserId(userId);
    setShowComponent('profile');
  };
  useEffect(() => {
    if (showComponent !== '' && showComponent !== 'firstVist') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);

  const getTabstatus = () => {
    commonApi
      .get_tab_status()
      .then(res => {
        // setShowSpinner(false);
        // console.log("fffffffffff",res.frequencies );
        if (res.status === 200) {
          // setShowComponent('firstVist');
          const item = res.tab_status;
          if (item.length === 0) {
            setShowComponent('firstVist');
          } else {
            const newArray = [];

            Object.entries(item).length > 0 &&
              item.forEach((item, index) => {
                newArray.push(item.tab);
              });

            const found = newArray.find(element => element === 'circle');
            if (found === 'circle') {
              // setShowComponent('');
            } else {
              setShowComponent('firstVist');
            }
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (workspaceData.length > 0) {
      // setShowSpinner(true);
      getBuilders(searchQry, areas_Filter);

      getCircles();
    }

    setTimeout(() => {
      const {pathname} = window.location;
      const urlPart = pathname.split('/').filter(Boolean);

      if (
        Object.keys(workspaceData).length > 0 &&
        urlPart.length === 1 &&
        showComponent !== ''
      ) {
        setShowComponent('');
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceData]);
  const CirclePermessions =
    menuPermesions && checkPermissions(menuPermesions, 'Circles');

  return (
    <>
      {/* {showLoader && <LoaderModal show={showLoader} />} */}

      <div className="page-wrapper">
        {!props.hide && (
          <Sidebar
            setWorkSpaceItem={setWorkspaceData}
            location={props.location}
            setShowComponent={setShowComponent}
            showComponent={showComponent}
          />
        )}

        <div className={!props.hide ? 'page-content-wrapper' : ''}>
          <div className="sessions-list-wrapper slider-screen-main">
            {CirclePermessions &&
              (CirclePermessions === 'allow' ||
                CirclePermessions?.analytics_row === true) && (
                <>
                  <div className="Reward_main analytics_slider_bg">
                    <span
                      className="analytics_slider_heading "
                      style={{color: analycticsSlider ? '' : '#999999'}}
                      onClick={() => {
                        setAnalycticsSlider(!analycticsSlider);
                      }}>
                      Analytics Row
                    </span>

                    {analycticsSlider && (
                      <div className="reward-top mt-3 mb-2">
                        <Slider {...rewardSlider}>
                          {SliderCircles.length > 0 &&
                            SliderCircles.map((item, index) => (
                              <div className="reward-main">
                                <div className="reward-card">
                                  <span
                                    style={{fontWeight: 700, fontSize: '13px'}}>
                                    {item.name}
                                  </span>
                                  <span
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}>
                                    <span style={{fontSize: '17px'}}>
                                      {item.money}
                                    </span>
                                    {item.name === 'Ratings' && (
                                      <img src={item.svgIcon} alt="" />
                                    )}
                                    {item.name === 'Conversion' ? (
                                      <i
                                        className="fa-solid fa-arrow-down"
                                        style={{color: '#BD4141'}}></i>
                                    ) : (
                                      <i
                                        class="fa-solid fa-arrow-up"
                                        style={{color: '#52bd41'}}></i>
                                    )}
                                  </span>
                                  <div class="message-date-info">
                                    <span
                                      style={{color: '#333'}}
                                      class="message-date">
                                      {item.time}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </Slider>
                      </div>
                    )}
                  </div>
                  <div className="add_border"></div>
                </>
              )}
            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              primary={
                <>
                  {/* {showLoader && <LoaderSpinner />} */}
                  {showComponent === 'firstVist' && (
                    <OpportunityScreen
                      onClose={() => {
                        setShowComponent('');
                      }}
                      type={'circle'}
                      setShowComponent={setShowComponent}
                    />
                  )}
                  {showComponent !== 'firstVist' && (
                    <>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        {!props.hide && (
                          <div className="event-tabs tabs-style-2 mb-0">
                            <ul>
                              <li>
                                <Button
                                  className={
                                    tabType === 'circles' ? 'active' : ''
                                  }
                                  onClick={() => {
                                    setSearchQry('');
                                    setTabType('circles');
                                    getCirclesUsers(currentCircle);
                                  }}>
                                  {labels.circles_tab_text[selected_lang]}
                                </Button>
                              </li>
                              <li>
                                <Button
                                  className={
                                    tabType === 'search' ? 'active' : ''
                                  }
                                  onClick={() => {
                                    setTabType('search');
                                    getAreas();
                                  }}>
                                  {labels.search_tab_text[selected_lang]}
                                </Button>
                              </li>
                            </ul>
                          </div>
                        )}
                        <div className="search-tabs-icons">
                          {tabType === 'circles' && (
                            <span
                              className="icon"
                              onClick={() => {
                                setShowComponent('createCircle');
                              }}>
                              {CirclePermessions &&
                                (CirclePermessions === 'allow' ||
                                  CirclePermessions?.create === true) && (
                                  <>
                                    <i className="icon-plus"></i>
                                  </>
                                )}
                            </span>
                          )}
                          {tabType === 'search' && (
                            <span
                              className={`icon ${
                                showComponent === 'inviteuser' ? 'active' : ''
                              }`}>
                              <i
                                className="icon-plus"
                                onClick={() => {
                                  setShowComponent('inviteuser');

                                  setNewUserId('');
                                }}></i>
                            </span>
                          )}
                          {/* {props.hide && (
                      <span
                        className="close as-link"
                        onClick={() => props.onClose(false)}>
                        <i className="fas fa-times" />
                      </span>
                    )} */}
                          {/* <span className="icon">
                                  <i className="fas fa-ellipsis-vertical" />
                                </span> */}
                        </div>
                      </div>

                      {tabType === 'search' && (
                        <>
                          <div className="person-search-wrapper">
                            <div className="search-form d-flex">
                              <Form.Group className="search-input">
                                <Form.Control
                                  onChange={e => {
                                    setSearchQry(e.target.value);
                                    getBuilders(e.target.value, areas_Filter);
                                  }}
                                  type="text"
                                  value={searchQry}
                                  placeholder="Search your network"
                                />
                              </Form.Group>
                              <Button
                                onClick={() => {
                                  setFilterButton(true);
                                }}>
                                <i className="icon-filter-icon" />
                              </Button>
                            </div>

                            {/* <div className="category-btns-wrapper category-btns-wrapper-slider">
                              <CustomSlidingButtons
                                items={areas}
                                activeButtons={areas_Filter}
                                onItemClick={e => {
                                  handleClick(e);
                                }}
                                showItems={2}
                              />
                            </div> */}
                          </div>

                          <div className="persons-imgs-wrapper">
                            <Scrollbars
                              style={{position: 'absolute', width: '100%'}}>
                              <div className="stage-list-wrapper">
                                {showSpinner && <LoaderSpinner />}
                                <Row>
                                  {Object.entries(builders).length > 0 ? (
                                    Object.entries(builders).length > 0 &&
                                    builders.map((item, index) => (
                                      <Col md={3} key={`stage-person-${index}`}>
                                        <div className="stage-list-item cursor-default">
                                          <div
                                            className={
                                              item?.linked_user_detail
                                                ?.user_type === 'business'
                                                ? 'stage-person-img business as-link'
                                                : 'stage-person-img as-link'
                                            }>
                                            <img
                                              onClick={() => {
                                                getProfileData(item.id);
                                              }}
                                              className="as-link"
                                              src={
                                                item.profile_img
                                                  ? item.profile_img
                                                  : DEFAULT_IMAGE
                                              }
                                              alt=""
                                            />
                                          </div>
                                          <div
                                            className="stage-person-name as-link"
                                            onClick={() => {
                                              getProfileData(item.id);
                                            }}>
                                            <h3>
                                              {fixName(
                                                item.first_name,
                                                item.last_name,
                                              )}
                                            </h3>
                                          </div>
                                        </div>
                                      </Col>
                                    ))
                                  ) : (
                                    <div className="no-results-wrapper no-appointments my-5">
                                      <div className="img-bg-wrapper">
                                        <img
                                          src={require('../../../Assets/images/norecord/no-circles.png')}
                                          alt=""
                                        />
                                      </div>
                                      <div className="no-results-content">
                                        {!searchQry && (
                                          <p className="noRecord-title">
                                            Add your Network
                                          </p>
                                        )}
                                        {/* <p className="noRecord-desc">
                                        Quickly access your colleagues to
                                        invite, refer and connect
                                      </p> */}

                                        <p
                                          className={
                                            searchQry
                                              ? 'noRecord-title'
                                              : 'noRecord-desc'
                                          }>
                                          {searchQry
                                            ? `  We didn't find the ${searchQry} match for the name`
                                            : `  Quickly access your colleagues to
                            invite, refer and connect `}
                                        </p>

                                        <div className="btn-wrapper mt-3">
                                          <Button
                                            className="btn btn-dark"
                                            onClick={() => {
                                              setShowComponent('inviteuser');
                                            }}>
                                            Add Contact
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Row>
                              </div>
                            </Scrollbars>
                          </div>
                        </>
                      )}

                      {tabType === 'circles' && circles.length > 0 ? (
                        <div className="fix-width-forms builder-form referal_page_content">
                          <div className="invite-page-search">
                            {Object.entries(circles).length > 0 && (
                              <CustomDropdown
                                setDropdownId={setCircleId}
                                dropdownId={circleId}
                                DropDownValues={circles}
                                itemName={['circle_name']}
                                className={'dropdown_font mb-3'}
                              />
                            )}
                            <div className="search-form">
                              {/* <Form.Group>
                                <div className="select-wrapper">
                                  <select
                                    className="form-control mb-3"
                                    onChange={e => {
                                      setCircleId(e.target.value);
                                      getCirclesUsers(e.target.value);
                                      setTabTypeSub('exstingBuilders');
                                    }}
                                    disabled={
                                      circles.length > 0 ? false : true
                                    }>
                                    {Object.entries(circles).length > 0 &&
                                      circles.map((item, index) => (
                                        <option
                                          value={item.circle_id}
                                          key={`stage--${index}`}>
                                          {item.circle_name}
                                        </option>
                                      ))}
                                  </select>

                                  
                                </div>
                              </Form.Group> */}

                              <div className="event-tabs tabs-style-2">
                                <ul>
                                  <li>
                                    <Button
                                      className={
                                        tabTypeSub === 'exstingBuilders'
                                          ? 'active'
                                          : ''
                                      }
                                      onClick={() => {
                                        getCirclesUsers(currentCircle);
                                        setTabTypeSub('exstingBuilders');
                                      }}>
                                      Existing Builders
                                    </Button>
                                  </li>
                                  <li>
                                    <Button
                                      className={
                                        tabTypeSub === 'addToCircle'
                                          ? 'active'
                                          : ''
                                      }
                                      onClick={() => {
                                        setTabTypeSub('addToCircle');
                                        getAddToCircleUsers(currentCircle);
                                      }}>
                                      Add To Circle
                                    </Button>
                                  </li>
                                </ul>
                              </div>

                              {tabTypeSub === 'exstingBuilders' && (
                                <>
                                  <Form.Group className="search-input">
                                    <Form.Control
                                      onChange={e => {
                                        handleChangeSearch(
                                          newCircleUser,
                                          e.target.value,
                                        );
                                      }}
                                      type="text"
                                      placeholder="Search for network"
                                    />
                                  </Form.Group>
                                  <div className="mt-2">
                                    Builders in circle (tap to remove)
                                  </div>
                                </>
                              )}
                              {tabTypeSub === 'addToCircle' && (
                                <Form.Group className="search-input">
                                  <Form.Control
                                    onChange={e => {
                                      handleChangeSearchAdd(
                                        newToCircleUsers,
                                        e.target.value,
                                      );
                                    }}
                                    type="text"
                                    placeholder="Search from your network"
                                  />
                                </Form.Group>
                              )}
                            </div>
                          </div>

                          <Scrollbars>
                            {/* <div className="invite-persons-wrapper"> */}
                            {/* <div className="invite-persons-heading">
                                  <span className="builder-text">30 Builders Removed</span>
                                  <span className="circle-link-icon" onClick={() => ""}>
                                      2 Added <i className="icon-circles" />
                                  </span>
                              </div> */}

                            {tabTypeSub === 'exstingBuilders' ? (
                              <div className="invite-persons-list persons-imgs-wrapper">
                                <Row>
                                  {showSpinner && <LoaderSpinner />}
                                  {Object.entries(circleUser).length > 0 ? (
                                    Object.entries(circleUser).length > 0 &&
                                    circleUser.map((item, index) => (
                                      <Col md={4} xl={3} key={`s-${index}`}>
                                        <div className="stage-list-item cursor-default">
                                          <div
                                            className={
                                              item?.linked_user_detail
                                                ?.user_type === 'business'
                                                ? 'stage-person-img business as-link'
                                                : 'stage-person-img as-link'
                                            }>
                                            <img
                                              className="as-link"
                                              onClick={() => {
                                                setUserData(item);
                                                setShowModel(true);
                                              }}
                                              src={
                                                item.profile_img
                                                  ? item.profile_img
                                                  : DEFAULT_IMAGE
                                              }
                                              alt=""
                                            />
                                          </div>
                                          <div
                                            className="stage-person-name as-link"
                                            onClick={() => {
                                              setUserData(item);
                                              setShowModel(true);
                                            }}>
                                            <h3>
                                              {fixName(
                                                item.first_name,
                                                item.last_name,
                                              )}
                                            </h3>
                                          </div>
                                        </div>
                                      </Col>
                                    ))
                                  ) : !showSpinner ? (
                                    // <div className="text-center">No record found</div>
                                    <div className="no-results-wrapper no-appointments my-5">
                                      <div className="img-bg-wrapper">
                                        <img
                                          src={require('../../../Assets/images/norecord/no-circles.png')}
                                          alt=""
                                        />
                                      </div>
                                      <div className="no-results-content">
                                        <p className="noRecord-title">
                                          Add your Network
                                        </p>
                                        <p className="noRecord-desc">
                                          Quickly access your colleagues to
                                          invite, refer and connect
                                        </p>

                                        <div className="btn-wrapper mt-3">
                                          <Button
                                            className={'btn btn-dark'}
                                            onClick={() => {
                                              // setShowComponent('inviteuser');
                                              setTabTypeSub('addToCircle');
                                            }}>
                                            Add Contact
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </Row>
                                {props.hide && (
                                  <div className="invite-btns">
                                    <Button
                                      onClick={() => {
                                        if (circleUser.length > 0) {
                                          props.CircleUserListData(circleUser);
                                          props.onClose();
                                        } else {
                                          errorMessage(
                                            'Please add first user in circle',
                                          );
                                        }
                                      }}>
                                      Invite Circle
                                    </Button>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div
                                className="invite-persons-list persons-imgs-wrapper"
                                style={{paddingBottom: '55px'}}>
                                {showSpinner && <LoaderSpinner />}
                                <Row>
                                  {Object.entries(addToCircleUsers).length >
                                  0 ? (
                                    Object.entries(addToCircleUsers).length >
                                      0 &&
                                    addToCircleUsers.map((item, index) => (
                                      <Col md={4} xl={3} key={`per-${index}`}>
                                        <div className="stage-list-item cursor-default">
                                          <div
                                            className={
                                              item?.linked_user_detail
                                                ?.user_type === 'business'
                                                ? 'stage-person-img business as-link'
                                                : 'stage-person-img as-link'
                                            }>
                                            <img
                                              onClick={() => {
                                                handleUpdateCircleUsers(
                                                  item.id,
                                                );
                                              }}
                                              className="as-link"
                                              src={
                                                item.profile_img
                                                  ? item.profile_img
                                                  : DEFAULT_IMAGE
                                              }
                                              alt=""
                                            />
                                          </div>
                                          <div
                                            className="stage-person-name as-link"
                                            onClick={() => {
                                              handleUpdateCircleUsers(item.id);
                                            }}>
                                            <h3>
                                              {fixName(
                                                item.first_name,
                                                item.last_name,
                                              )}
                                            </h3>
                                          </div>
                                        </div>
                                      </Col>
                                    ))
                                  ) : !showSpinner ? (
                                    // <div className="text-center">
                                    //   No record found
                                    // </div>
                                    <div className="no-results-wrapper no-appointments my-5">
                                      <div className="img-bg-wrapper">
                                        <img
                                          src={require('../../../Assets/images/norecord/no-circles.png')}
                                          alt=""
                                        />
                                      </div>
                                      <div className="no-results-content">
                                        <p className="noRecord-title">
                                          Add your Network
                                        </p>
                                        <p className="noRecord-desc">
                                          Quickly access your colleagues to
                                          invite, refer and connect
                                        </p>

                                        <div className="btn-wrapper mt-3">
                                          <Button
                                            className="btn btn-dark"
                                            onClick={() => {
                                              setShowComponent('inviteuser');
                                            }}>
                                            Add Contact
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </Row>
                              </div>
                            )}
                            {/* </div> */}
                          </Scrollbars>
                          {tabTypeSub === 'addToCircle' &&
                            (addToCircleUsers.length > 0 ||
                              addToCircleUserList.length > 0) &&
                            !showSpinner && (
                              <div className="invite-btns as-link mb-3 bg_transparent bottom-0 pt-2 pb-3">
                                <Button
                                  disabled={
                                    addToCircleUsers.length > 0 ||
                                    addToCircleUserList.length > 0
                                      ? false
                                      : true
                                  }
                                  onClick={() => {
                                    addUserInCircle();
                                  }}>
                                  Update Circle
                                </Button>
                              </div>
                            )}
                        </div>
                      ) : (
                        !showSpinner &&
                        tabType === 'circles' && (
                          <div className="no-results-wrapper mt-5 fix-width-forms">
                            <div className="img-bg-wrapper">
                              <img
                                src={require('../../../Assets/images/norecord/no-circles.png')}
                                alt=""
                              />
                            </div>

                            <div className="no-results-content">
                              <h2>Manage circle</h2>
                              <p>You haven't created any circle yet.</p>

                              <div className="btn-wrapper mt-3">
                                <Button
                                  className="btn btn-dark"
                                  onClick={() => {
                                    setShowComponent('createCircle');
                                  }}>
                                  Create Circle
                                </Button>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </>
                  )}

                  {showModaL && (
                    <CustomModal onClose={() => setShowModel(false)}>
                      <div className="remove-user-text">
                        <p>
                          Are you sure you want to remove{' '}
                          <b>
                            {fixName(userData.first_name, userData.last_name)}
                          </b>
                          ?
                        </p>
                        <Button
                          className="btn-dark"
                          onClick={() => {
                            !props.hide
                              ? removeCircleUser()
                              : setcircleUser(
                                  circleUser.filter(
                                    item => item.id !== userData.id,
                                  ),
                                );

                            setShowModel(false);
                          }}>
                          Yes
                        </Button>
                        <Button
                          className="btn-dark"
                          onClick={() => {
                            setShowModel(false);
                          }}>
                          No Thanks!
                        </Button>
                      </div>
                    </CustomModal>
                  )}

                  {filterButton && (
                    <RefferalSearchFilter
                      show={filterButton}
                      onClose={() => setFilterButton(false)}
                      subTabType={''}
                      builderData={builderData}
                      arrowButton={''}
                      isCheckAll={isCheckAll}
                      setIsCheckAll={setIsCheckAll}
                      isCheck={isCheckSF}
                      isCheckArea={isCheck}
                      setIsCheckArea={setIsCheck}
                      setIsCheck={setIsCheckSF}
                      list={list}
                      setList={setList}
                      locationS={locationS}
                      setLocationS={setLocationS}
                      appointmentId={appointmentId}
                      setAppointmentId={setAppointmentId}
                      categoriesId={categoriesId}
                      setCategoriesId={setCategoriesId}
                      setActive={setActive}
                      active={active}
                      setFocusLocations={setFocusLocations}
                      setIdentiferId={setIdentiferId}
                      setEthnicityId={setEthnicityId}
                      setGender={setGender}
                      gender={gender}
                      focusLocations={focusLocations}
                      ethnicityId={ethnicityId}
                      identiferId={identiferId}
                      setLongitude={setLongitude}
                      longitude={longitude}
                      setLatitude={setLatitude}
                      latitude={latitude}
                      setPersnalTypesId={setPersnalTypesId}
                      setCategoriesId_Focus={setCategoriesId_Focus}
                      setAppointmentId_Focus={setAppointmentId_Focus}
                      setIdenntifier_Filter={setIdenntifier_Filter}
                      setAreas_Filter={setAreas_Filter}
                      idenntifier_Filter={idenntifier_Filter}
                      areas_Filter={areas_Filter}
                      persnalTypesId={persnalTypesId}
                      appointmentId_Focus={appointmentId_Focus}
                      categoriesId_Focus={categoriesId_Focus}
                    />
                  )}
                </>
              }
              secondary={
                <div className="session-profile-wrapper profile-modal">
                  {showComponent === 'inviteuser' && (
                    <SendInvitation
                      onClose={() => {
                        setShowComponent('');
                      }}
                    />
                  )}

                  {showComponent === 'createCircle' && (
                    <CreatCircleList
                      onClose={type => {
                        if (type) {
                          getCircles();
                        }
                        setShowComponent('');
                      }}
                    />
                  )}

                  {/* {(showUserInfo && showSnapshots && showOpportunity && showUpcomming) ? <><ProfileBlock profile={userInfo} />
                        <div className="invited-by-wrapper">
                            <div className="user-img">
                                <img src={(userInfo.data.invited_by.profile_img) ? userInfo.data.invited_by.profile_img : 'https://build-dev.s3.us-east-1.amazonaws.com/build-default-profile.jpg'} alt="" />
                            </div>
                            <div className="user-info">
                                <span className="info-name">
                                    Invited By <b>{userInfo.data.invited_by.first_name + ' ' + userInfo.data.invited_by.last_name}</b>
                                </span>
                                <span className="info-date">{userInfo.data.invited_by.invitation_date}</span>
                            </div>
                        </div>
                        <ProfileGallery userId={userInfo.data.id} gallery={userSnapshots} isNewSession={true} oppos={userOpportunity} sesList={userUpcomming} />
                    </>
                    : <></>} */}
                  {newUserId && showComponent === 'profile' && (
                    <ProfileComponent
                      userId={newUserId}
                      show={false}
                      // closeModal={() => {setShowComponent("")}}
                      hideCloseButton={true}
                      button={button}
                      setButton={setButton}
                      userData={[]}
                      moderator={false}
                      onClose={() => {
                        setShowComponent('');
                      }}
                      // setParticipantType={setParticipantType}
                      VoxeetSDK={[]}
                      conferenceInfo={[]}
                      jdSpecialM={[]}
                      jdSpecialA={[]}
                    />
                  )}
                </div>
              }
            />
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>

      {/* <Modal
        show={showModaL}
        onHide={() => {
          setShowModel(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are yo sure you want to remove {userData.first_name}{' '}
          {userData.last_name}?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              {
                !props.hide
                  ? removeCircleUser()
                  : setcircleUser(
                      circleUser.filter(item => item.id !== userData.id),
                    );
              }

              setShowModel(false);
            }}>
            Yes
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShowModel(false);
            }}>
            No Thanks!
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default Builders;
