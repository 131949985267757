import React, {useEffect, useState} from 'react';
import {RoiLogo} from '../../Constants/Images';
import SlidingPanel from '../SlidingPanel';
import {
  capitalizeFirst,
  fixName,
  getTimeDifference,
  SeprateAllArrays,
} from '../../Utils/commonUtils';
import CustomDropdown from '../CustomDropdown';
import {Form} from 'react-bootstrap';
import NorecordFound from '../NoRecord/NorecordFound';

const DetailProject = props => {
  const [dropdownId, setDropdownId] = useState('MileStones');
  const [selectedData, setSelectedData] = useState(null);
  const dropdownValues = [
    {title: 'MileStones', id: 'MileStones'},
    {title: 'Deliverables', id: 'Deliverables'},
    {title: 'Tasks', id: 'Tasks'},
    {title: 'Offerings', id: 'Offerings'},
    {title: 'Overview', id: 'Overview'},
    // {id: '4', title: 'Uploads'},
  ];

  const {selectedProjectData, setOpenEdit} = props;

  const renderProjectButtons = profile => (
    <>
      {localStorage.getItem('id') === localStorage.getItem('user') ? (
        <p
          className="p-bold m-0 f_600_h"
          onClick={() => setOpenEdit(true)}
          style={{cursor: 'pointer'}}>
          Edit
        </p>
      ) : (
        <img src={RoiLogo} alt="" />
      )}
    </>
  );

  const handelDetail = () => {
    const data = SeprateAllArrays(selectedProjectData && selectedProjectData);
    // props.setSelectedProjectData(data)
    setSelectedData(data);
  };

  useEffect(() => {
    handelDetail();
  }, []);

  const handleLoopVariable = (array, type) => {
    if (type !== 'Overview') {
      if (type === 'MileStones') {
        return array.MileStones;
      }
      if (type === 'Deliverables') {
        return array.deliverableTitles;
      }
      if (type === 'Tasks') {
        return array.tasks;
      }
      if (type === 'Offerings') {
        return array.offerings;
      }
    }

    return [];
  };

  console.log(selectedData, 'selectedDataselectedDataselectedData');

  // const handleOverview = (array, type) => {
  //   if(type === 'Overview'){
  //     if (array.customer_details) {
  //       return array.customer_details;
  //     }
  //     if (array.poc_details) {
  //       return array.poc_details;
  //     }
  //     if (array.team_alias_detail) {
  //       return array.team_alias_detail;
  //     }

  //   }

  //   console.log(array, 'pppppppppppppppp')

  //   return [];
  // };

  console.log(selectedData, 'selectedDataselectedData');
  const noRecordFound = {
    img: null,
    title: null,
    description: `No ${dropdownId} available.`,
    button: '',
  };

  return (
    <SlidingPanel
      hidePanel={() => {
        props.onClose();
      }}
      renderData={renderProjectButtons}
      showBlackHeader={true}
      lastDivStyle={{
        borderRadius: '35px',
        background: '#F7F6F2',
        // marginBottom: '20px',
      }}
      firstDivStyle={{
        padding: 0,
        background:
          'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)' /*linear-gradient(to bottom, black, white)*/,
      }}>
      <div
        className="serach-filter-wrapper create-sessionEvents"
        style={{
          paddingLeft: '23px',
          paddingTop: '20px',
        }}>
        <div className="create-ticket-form  primary_panel_content">
          <div className=" create-project-content fix-width-forms  ">
            <div className="fieldset">
              {/* <Form.Label controlid="validationCustom01"> */}
              Project Name
              {/* </Form.Label> */}
            </div>
            <div className="fieldset">
              <CustomDropdown
                setDropdownId={setDropdownId}
                dropdownId={dropdownId}
                DropDownValues={dropdownValues}
                itemName={['title']}
                className={'mb-3'}
                //   handleDropdownValue={handleDropdownValue}
              />
            </div>

            <div className="fieldset">
              <div className="mb-3">
                {/* <p>{handleDropdownValue()}</p> */}
                <p>
                  {selectedData && dropdownId &&  selectedData?.MileStones &&
                    handleLoopVariable(selectedData, dropdownId).length > 0 &&
                    dropdownId}
                </p>

                <div>
                  {selectedData &&
                  handleLoopVariable(selectedData, dropdownId).length > 0 ? (
                    handleLoopVariable(selectedData, dropdownId).map(
                      (item, index) => (
                        <div
                          className="d-flex align-items-center phases_item mt-3"
                          key={index}>
                          <div
                            className="customer_name flex-1"
                            style={{
                              backgroundColor:
                                (dropdownId === 'Tasks' ||
                                  dropdownId === 'Offerings') &&
                                'white',
                            }}>
                            <div className="alias_name">
                              {dropdownId === 'Tasks' ||
                              dropdownId === 'Offerings'
                                ? capitalizeFirst(item.title)
                                : capitalizeFirst(item)}
                            </div>
                            {(dropdownId === 'Tasks' ||
                              dropdownId === 'Offerings') && (
                              <div className="d-flex align-items-center">
                                <div
                                  style={{
                                    color: 'rgb(90, 189, 66)',
                                    marginRight: '10px',
                                  }}>
                                  {item?.date &&
                                  item?.date !== '0000-00-00' &&
                                  item?.date !== ''
                                    ? getTimeDifference(item.date)
                                    : '0'}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ),
                    )
                  ) : dropdownId !== 'Overview' ? (
                    <NorecordFound noRecordData={noRecordFound} />
                  ) : null}
                </div>

                {selectedData &&
                  selectedData.Overview &&
                  dropdownId === 'Overview' && (
                    <div className="create-project-content fix-width-forms">
                      {selectedData.Overview.customer_details &&
                        selectedData.Overview.customer_details.length > 0 && (
                          <div className="fieldset" style={{color: '#a0a0a0'}}>
                            Customers:
                            {selectedData.Overview.customer_details.map(
                              (customer, index) => (
                                <div
                                  key={index}
                                  className="d-flex align-items-center phases_item mt-3">
                                  <div className="customer_name flex-1">
                                    <div className="alias_name">
                                      {capitalizeFirst(
                                        customer.first_name +
                                          ' ' +
                                          customer.last_name,
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ),
                            )}
                          </div>
                        )}

                      {selectedData.Overview.poc_details &&
                        selectedData.Overview.poc_details.length > 0 && (
                          <div className="fieldset" style={{color: '#a0a0a0'}}>
                            POCs:
                            {selectedData.Overview.poc_details.map(
                              (poc, index) => (
                                <div
                                  key={index}
                                  className="d-flex align-items-center phases_item mt-3">
                                  <div
                                    className="customer_name flex-1"
                                    style={{
                                      backgroundColor:
                                        (dropdownId === 'Tasks' ||
                                          dropdownId === 'Offerings') &&
                                        'white',
                                    }}>
                                    <div className="alias_name">
                                      {capitalizeFirst(
                                        poc.first_name + ' ' + poc.last_name,
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ),
                            )}
                          </div>
                        )}

                      {selectedData.Overview.team_alias_detail &&
                        selectedData.Overview.team_alias_detail.length > 0 && (
                          <div className="fieldset" style={{color: '#a0a0a0'}}>
                            Team Alias Details:
                            {selectedData.Overview.team_alias_detail.map(
                              (teamAlias, index) => (
                                <div
                                  key={index}
                                  className="d-flex align-items-center phases_item mt-3">
                                  <div
                                    className="customer_name flex-1"
                                    style={{
                                      backgroundColor:
                                        (dropdownId === 'Tasks' ||
                                          dropdownId === 'Offerings') &&
                                        'white',
                                    }}>
                                    <div className="alias_name">
                                      {capitalizeFirst(teamAlias.role_alias)}
                                    </div>
                                  </div>
                                </div>
                              ),
                            )}
                          </div>
                        )}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SlidingPanel>
  );
};

export default DetailProject;
