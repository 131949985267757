import {Button} from 'react-bootstrap';

const Navbar = props => {
  // const [currentSlide, setCurrentSlide] = useState(0);

  const currentUrl = window.location.href;
  return (
    <nav className={`app__navbar ${props.currentStep === 'name' ? '' : 'navbar_bg'}`}>
      <div className="app__navbar-logo">
        {props.currentStep !== 'name'  &&
          currentUrl.includes('registercompany') && (
            <>
              <span
              style={{marginLeft: props.currentStep === 1 && window.innerWidth <= 650? '110px' : ''}}
                className="btn-roi-template as-link mt-3-bold"
                onClick={() => {}}>
                {/* {props.currentSlide > 0 ? 'Help Me' : 'Use ROI Template'} */}
                Use ROI Template
              </span>

              <i
                class="fa-solid fa-circle-question"
                style={{
                  color: '#68b245',
                  marginLeft: '10px',
                  fontSize: '22px',
                }}></i>
            </>
          )}

        {/* {props.currentStep ==='customerDeatils' && (
          <Button
          className="new-btn btn-green mr-6 "
          onClick={() => {
            props.handleBackbtn();
          }}>
          Back
        </Button>
        )} */}
      </div>

      {/* <div className="app__navbar-roi">
        <img src={Roi} alt="" />
      </div> */}

      <div className="app__navbar-login skip_button">
        {props.currentStep === 3 && (
          <span
            className="as-link  skip_btn"
            onClick={() => {
              props.registerNewCompany('skip');
            }}>
            Skip
          </span>
        )}

        {/* <button type="submit" className="as-link ml-5">
          Save
        </button> */}

        <div className='navbar_btns'>
        {(props.currentStep >= 1 || props.currentStep === 'name') &&
          props.currentStep !== 3 && (
            <Button
              className="new-btn btn-green mr-6 "
              // onClick={() => {
              //   props.slider?.current?.slickNext();
              // }}
              type="submit">
              Next
            </Button>
          )}

        {(props.currentStep === 3 || props.currentStep ==='customerDeatils') && (
          <Button
            className="new-btn btn-green mr-6 get_started_btn"
            disabled={props.isButtonDisabled ? props.isButtonDisabled : false}
            onClick={() => {
              props.setshowLoader &&  props.setshowLoader(true)
              props.registerNewCompany();
            }}>
            Get Started
          </Button>
        )}
        </div>

       
      </div>
    </nav>
  );
};

export default Navbar;
