import React from 'react';
import {useState, useEffect} from 'react';
import {Button, Form, Dropdown} from 'react-bootstrap';
import CustomModal from '../CustomModal';
import ComparisonItem from './ComparisonItem';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import {errorMessage} from '../../Utils/ToastMessages';
import {successMessage} from '../../Utils/ToastMessages';
import commonApi from '../../Services/CommonService';
import {useImperativeHandle} from 'react';
import {checkCatalogIds, filterOutLevels, validateStandard,checkAvailablitiy} from '../../Utils/commonUtils';
import { labels } from '../../Constants/Translations';
import { selected_lang } from '../../Utils/env';

const InvoiceEstimate = props => {
  const [comparisonName, setComparisonName] = useState('');
  const [standardName, setStandardName] = useState('');
  const [type, setType] = useState(props.type === 'comparison' ? '1' : '2');
  const [comparisonList, setComparisonList] = useState([]);
  const [standardList, setStandardList] = useState([]);
  const [moreOptions, setMoreOptions] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [comparisonTitle, setComparisonTitle] = useState('');
  const [subType, setSubType] = useState('comparison');
  const [originalcomparisonDraft, setOriginalComparisonDraft] = useState([]);
  const [comparisonDraft, setComparisonDraft] = useState([]);
  const [originalstandardDraft, setOriginalStandardDraft] = useState([]);
  const [standardDraft, setStandardDraft] = useState([]);
  const [redraw, setRedraw] = useState(false);
  const [showErrorFromApi, setShowErrorFromApi] = useState([]);
  const [outOfStockItems, setOutOfStockItems] = useState([]);

  const InitialComparison = [
    {
      invoice_type: 'estimate',
      sub_type: 'comparison',
      group_name: 'Good',
      add_on: '',
    },
    {
      invoice_type: 'estimate',
      sub_type: 'comparison',
      group_name: 'Better',
      add_on: '',
    },
    {
      invoice_type: 'estimate',
      sub_type: 'comparison',
      group_name: 'Best',
      add_on: '',
    },
  ];

  const [invoiceDate, setInvoiceDate] = useState(() => {
    const date = new Date();

    // date.setHours(12, 0, 0, 0);
    return date;
  });
  const [dueDate, setDueDate] = useState(() => {
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    // nextDay.setHours(12, 0, 0, 0);
    return nextDay;
  });
  const [note, setNote] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const [endIsOpen, setEndIsOpen] = useState(false);

  const dropdownMenu = [
    {id: '1', name: 'Comparison'},
    {
      id: '2',
      name: 'Standard',
    },
  ];

  useImperativeHandle(props.titleRef, () => ({
    addComparison(title) {
      setComparisonList(prev => [
        ...prev,
        {
          invoice_type: 'estimate',
          sub_type: props.subType,
          group_name: title,

          add_on: '',
        },
      ]);

      props.setComparisonTitle('');
      props.setShowComponent('');
    },

    updateComparsion(title, item, index) {
      setComparisonList(prevList => {
        const newList = [...prevList];

        newList[index] = {
          ...newList[index],
          group_name: title,
        };

        return newList;
      });

      props.setComparisonTitle('');
      props.setShowComponent('');
    },

    deleteComparison(item, index) {
      setComparisonList(prevList => {
        const newList = prevList.filter((_, i) => i !== index);

        return newList;
      });

      props.setComparisonTitle('');
      props.setShowComponent('');
    },

    getDraft() {
      getEstimatesDraft('comparison');
    },

    discardDraft() {
      discardEstimateDraft('comparison');
    },

    checkStockAvailabilty(){
      if(outOfStockItems.length > 0 ){
       const checkProductAvailablitiy = checkAvailablitiy(
          'estimate',
          [],
          props.comparsionUsers,
          comparisonList,
        );
        setOutOfStockItems(checkProductAvailablitiy.length > 0 ? checkProductAvailablitiy : []);
      }
    }
    //   DeleteCatalogItem(catalog) {

    //     console.log(catalog,'catalog')

    //     setComparisonList(prevInvoices => {
    //       return prevInvoices.map((invoice, index) => {
    //           if (index === catalog.comparsionItemIndex) {
    //               console.log("Original catalog_ids:", invoice.catalog_ids);
    //               const catalogIdsArray = invoice.catalog_ids.split(',').filter(id => id !== catalog.item.id);
    //               console.log("Filtered catalogIdsArray:", catalogIdsArray);
    //               const newCatalogIds = catalogIdsArray.join(',');
    //               console.log("New catalog_ids:", newCatalogIds);
    //               return {
    //                   ...invoice,
    //                   catalog_ids: newCatalogIds
    //               };
    //           }
    //           return invoice;
    //       });
    //   });

    // setRedraw(!redraw)

    //     props.setShowComponent('');
    //   },
  }));
  // const addComparison = title => {

  // };

  useImperativeHandle(props.templateRef, () => ({
    addTemplateItem(item) {
      console.log(item, 'comparison template item');
      if (props.subType === 'comparison') {
        setComparisonDraft(item.invoices);
        handleComparsionDraft(item.invoices);
      } else {
        setStandardDraft(item.invoices);
        handleStandardDraft(item.invoices);
      }
    },
  }));

  useImperativeHandle(props.standardRef, () => ({
    addStandard(title, addOn) {
      setStandardList(prev => [
        ...prev,
        {
          invoice_type: 'estimate',
          sub_type: props.subType,
          group_name: title,

          add_on: addOn,
        },
      ]);

      props.setComparisonTitle('');
      props.setShowComponent('');
    },

    updateStandard(title, item, addOn, index) {
      console.log(title, 'invoicetitle');
      console.log(item, 'updtaeItem');

      setStandardList(prevList => {
        const newList = [...prevList];

        newList[index] = {
          ...newList[index],
          group_name: title,
          add_on: addOn,
        };

        return newList;
      });

      props.setComparisonTitle('');
      props.setShowComponent('');
    },

    deleteStandard(item, index) {
      setStandardList(prevList => {
        const newList = prevList.filter((_, i) => i !== index);

        return newList;
      });

      props.setComparisonTitle('');
      props.setShowComponent('');
    },

    getDraft() {
      getEstimatesDraft('standard');
    },

    discardDraft() {
      discardEstimateDraft('standard');
    },
    checkStockAvailabilty(){
      if(outOfStockItems.length > 0 ){
       const checkProductAvailablitiy = checkAvailablitiy(
          'estimate',
          [],
          props.standardUsers,
          standardList,
        );
        setOutOfStockItems(checkProductAvailablitiy.length > 0 ? checkProductAvailablitiy : []);
      }
    }
  }));

  // const addStandard = title => {
  //   setStandardList(prev => [
  //     ...prev,
  //     {
  //       invoice_type: 'estimate',
  //       sub_type: subType,
  //       group_name: title,

  //       add_on: 'OR',
  //     },
  //   ]);

  //   setComparisonTitle('');
  // };

  useEffect(() => {
    if (type === '1') {
      props.setSubType('comparison');
    } else {
      props.setSubType('standard');
    }
  }, [type]);

  useEffect(() => {
    // getComparisonTemplates()

    if (
      props.estimateTemplateItem &&
      Object.keys(props.estimateTemplateItem).length > 0
    ) {
      if (props.type === 'comparison') {
        // setOriginalComparisonDraft(res.draft_invoice);
        setComparisonDraft(props.estimateTemplateItem.invoices);
        handleComparsionDraft(props.estimateTemplateItem.invoices);
      } else {
        // setOriginalStandardDraft(res.draft_invoice);
        setStandardDraft(props.estimateTemplateItem.invoices);
        handleStandardDraft(props.estimateTemplateItem.invoices);
      }
    }

    // getEstimatesDraft('comparison');

    getEstimatesDraft('standard');

    setTimeout(() => {
      getEstimatesDraft('comparison');
     
    }, 3000);
  }, []);

  const getEstimatesDraft = type => {
    const payload = {invoice_type: 'estimate', sub_type: type};
    commonApi
      .check_invoice_draft(payload)
      .then(res => {
        if (res.status === 200) {
          if (
            res.draft_invoice.length > 0 &&
            !props.estimateTemplateItem &&
            !props.type
          ) {
            if (type === 'comparison') {
              setOriginalComparisonDraft(res.draft_invoice);
              setComparisonDraft(res.draft_invoice);
              handleComparsionDraft(res.draft_invoice);
            } else {
              setOriginalStandardDraft(res.draft_invoice);
              setStandardDraft(res.draft_invoice);
              handleStandardDraft(res.draft_invoice);
            }
          } else {
            if (
              type === 'comparison' &&
              !props.estimateTemplateItem &&
              !props.type
            ) {
              setComparisonList(InitialComparison);
            }
          }

          setTimeout(() => {
            props.setShowLoader(false);
          }, 300);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleStandardDraft = draftItem => {
    if (draftItem && Object.keys(draftItem).length > 0) {
      setStandardName(draftItem[0]?.invoice_title);

      const mappedArray = draftItem.map(item => ({
        invoice_type: item.invoice_type,
        sub_type: item.sub_type,
        group_name: item.group_name,
        add_on: item.add_on,
        catalog_ids: item.catalog_ids,
        catalog_details:item.catalog_details,
        customer_note: item.customer_note,
        name: '',
        subscripton: Number(item.sub_total),
        subtotal: Number(item.sub_total),
        tax: Number(item.taxes_fee),
        discount: Number(item.discount),
        shipping: Number(item.shipping_handling),
        total: Number(item.total),
        payment_Schedule: '',
      }));
      // console.log(mappedArray, 'mappedArray');
      setStandardList(mappedArray);

      setRedraw(!redraw);
      // setInvoiceDate(new Date(draftItem[0]?.invoice_date));
      // setDueDate(new Date(draftItem[0]?.due_date));
      props.setStandardUsers(
        draftItem[0]?.draft_user_ids
          ? draftItem[0]?.draft_user_ids.split(',')
          : [],
      );

      props.setStandUsers(
        draftItem[0]?.draft_user_ids
          ? draftItem[0]?.draft_user_ids.split(',')
          : [],
      );
    } else {
      setStandardList([]);
      setStandardName('');

      // const date = new Date();

      // date.setHours(12, 0, 0, 0);

      // setInvoiceDate(date);

      // const nextDay = new Date();
      // nextDay.setDate(nextDay.getDate() + 1);
      // nextDay.setHours(12, 0, 0, 0);
      // setDueDate(nextDay);
      props.setStandardUsers([]);
      props.setStandUsers([]);
    }
  };

  const handleComparsionDraft = draftItem => {
    if (draftItem && Object.keys(draftItem).length > 0) {
      setComparisonName(draftItem[0]?.invoice_title);

      const mappedArray = draftItem.map(item => ({
        invoice_type: item.invoice_type,
        sub_type: item.sub_type,
        group_name: item.group_name,
        add_on: item.add_on,
        catalog_ids: item.catalog_ids,
        catalog_details:item.catalog_details,
        customer_note: item.customer_note,
        name: '',
        subscripton: Number(item.sub_total).toFixed(2),
        subtotal: Number(item.sub_total),
        tax: Number(item.taxes_fee).toFixed(2),
        discount: Number(item.discount).toFixed(2),
        shipping: Number(item.shipping_handling).toFixed(2),
        total: Number(item.total),
        payment_Schedule: '',
      }));
      // console.log(mappedArray, 'mappedArray');
      setComparisonList(mappedArray);
      setRedraw(!redraw);

      // setInvoiceDate(new Date(draftItem[0]?.invoice_date));
      // setDueDate(new Date(draftItem[0]?.due_date));
      props.setComparsionUsers(
        draftItem[0]?.draft_user_ids
          ? draftItem[0]?.draft_user_ids.split(',')
          : [],
      );
      props.setCompUsers(
        draftItem[0]?.draft_user_ids
          ? draftItem[0]?.draft_user_ids.split(',')
          : [],
      );
    } else {
      setComparisonName('');
      setComparisonList(InitialComparison);

      props.setComparsionUsers([]);
      props.setCompUsers([]);
    }

    const date = new Date();

    // date.setHours(12, 0, 0, 0);

    setInvoiceDate(date);

    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    // nextDay.setHours(12, 0, 0, 0);
    setDueDate(nextDay);
  };

  useEffect(() => {}, [comparisonDraft, comparisonList]);

  const handleChange = e => {
    if (endIsOpen === true) {
      setDueDate(e);
      setEndIsOpen(false);
    } else if (e) {
      setInvoiceDate(e);
      setIsOpen(false);
    } else {
      setIsOpen(false);
    }
  };

  const handleClickD = e => {
    e.preventDefault();
    setIsOpen(true);
    setEndIsOpen(false);
  };
  const handleClickE = e => {
    e.preventDefault();
    setEndIsOpen(true);
    setIsOpen(false);
  };

  const handleOnClickOutside = () => {
    setIsOpen(false);
    setEndIsOpen(false);
  };

  const handleComparisonSubmit = type => {
    const currentDate = new Date();
    const dueDateCheck = new Date(dueDate);
    const invoiceDateCheck = new Date(invoiceDate);

    let checkDate =
      (currentDate <= invoiceDateCheck && currentDate <= dueDateCheck) ||
      invoiceDateCheck <= dueDateCheck;

    if (comparisonName !== '') {
      if (
        (comparisonList.length > 1  &&
          checkCatalogIds(comparisonList)) ||
        type === 'template' ||
        type === 'draft'
      ) {
        if (dueDate !== '' && invoiceDate !== '' && checkDate) {
          if (
            props.comparsionUsers.length > 0 ||
            type === 'template' ||
            type === 'draft'
          ) {
            sendEstimate(type);
          } else {
            errorMessage('Please select Users');
          }
        } else {
          errorMessage(
            !checkDate && dueDate !== ''
              ? 'Please select valid date'
              : 'Please select Date',
          );
        }
      } else {
        errorMessage(
          comparisonList.length === 1
            ? 'Please Add One More Comparison'
            : 'Please Add Catalogs',
        );
      }
    } else {
      errorMessage(comparisonName === '' ? 'Please add Title' : '');
    }
  };

  const handleStandardSubmit = type => {
    const currentDate = new Date();
    const dueDateCheck = new Date(dueDate);
    const invoiceDateCheck = new Date(invoiceDate);

    let checkDate =
      (currentDate <= invoiceDateCheck && currentDate <= dueDateCheck) ||
      invoiceDateCheck <= dueDateCheck;

    const checkIds = checkCatalogIds(standardList);

    const validateAddOn = validateStandard(standardList);

    console.log(checkIds, 'checkIds');

    console.log(validateAddOn, 'validateAddOn');

    if (standardName !== '') {
      if (
        (standardList.length > 0 && checkIds) ||
        type === 'template' ||
        type === 'draft'
      ) {
        if (dueDate !== '' && invoiceDate !== '' && checkDate) {
          if (
            (checkIds && validateAddOn) ||
            type === 'template' ||
            type === 'draft'
          ) {
            if (props.subType === 'standard') {
              if (
                props.standardUsers.length > 0 ||
                type === 'template' ||
                type === 'draft'
              ) {
                sendEstimate(type);
              } else {
                errorMessage('Please select Users');
              }
            } else {
              sendEstimate(type);
            }
          } else {
            errorMessage('Please Add One More OR Add-on');
          }
        } else {
          errorMessage(
            !checkDate && dueDate !== ''
              ? 'Please select valid date'
              : 'Please select Date',
          );
        }
      } else {
        errorMessage('Please Add Catalogs');
      }
    } else {
      errorMessage(standardName === '' ? 'Please add Title' : '');
    }
  };

  const sendEstimate = type => {
    // console.log(comparisonList, 'comparisonList payload');

    let checkProductAvailablitiy = []
    if(props.subType === 'comparison'){
      checkProductAvailablitiy = checkAvailablitiy(
        'estimate',
        [],
        props.comparsionUsers,
        comparisonList,
      );
    }else{
      checkProductAvailablitiy = checkAvailablitiy(
        'estimate',
        [],
        props.standardUsers,
        standardList,
      );
    }
  

    console.log(
      checkProductAvailablitiy,
      'checkProductAvailablitiycheckProductAvailablitiy',
    );
    if (checkProductAvailablitiy.length > 0) {
      setOutOfStockItems(checkProductAvailablitiy);
      if(checkProductAvailablitiy.length > 0 && props.subType === 'comparison' ? props.comparsionUsers.length > 1 : props.standardUsers.length > 1){
        errorMessage(`${labels.OUT_OF_STOCK_FOR_USERS[selected_lang]}`);
      }
      return;
    }



    let updatedArray = [];

    if (props.subType === 'comparison') {
      updatedArray = comparisonList.map(
        ({shipping, tax, subtotal,catalog_details, ...item}) => ({
          ...item,
          // discount: item.discount ? String(item.discount) : '0.00',
          // shipping_handling: shipping ? String(shipping) : '0.00',
          catalog_details:filterOutLevels(catalog_details),
          total: item.total ? String(item.total) : '0.00',
          // sub_total: subtotal ? String(subtotal) : '0.00',
          // taxes_fee: tax ? String(tax) : '0.00',
          is_draft: type === 'draft' ? true : false,
          is_template: type === 'template' ? true : false,

          invoice_title: comparisonName,
          invoice_date: invoiceDate
            ? Moment(invoiceDate).format('YYYY-MM-DD HH:mm:ss').toString()
            : '',
          due_date: dueDate
            ? Moment(dueDate).format('YYYY-MM-DD HH:mm:ss').toString()
            : '',
        }),
      );
    } else {
      updatedArray = standardList.map(({shipping, tax, subtotal,catalog_details, ...item}) => ({
        ...item,
        // discount: item.discount ? String(item.discount) : '0.00',
        // shipping_handling: shipping ? String(shipping) : '0.00',
        catalog_details:filterOutLevels(catalog_details && catalog_details),
        total: item.total ? String(item.total) : '0.00',
        // sub_total: subtotal ? String(subtotal) : '0.00',
        // taxes_fee: tax ? String(tax) : '0.00',
        is_draft: type === 'draft' ? true : false,
        is_template: type === 'template' ? true : false,
        invoice_title: standardName,
        invoice_date: invoiceDate
          ? Moment(invoiceDate).format('YYYY-MM-DD').toString()
          : '',
        due_date: dueDate
          ? Moment(dueDate).format('YYYY-MM-DD').toString()
          : '',
      }));
    }

    var Payload = {
      inserted_data: updatedArray,
      user_id:
        props.subType === 'comparison'
          ? props.comparsionUsers
          : props.standardUsers,
    };

    console.log(Payload, 'Payload');

    commonApi
      .send_invoice_estimate(Payload)
      .then(res => {
        if (res.success && !res.error) {
        successMessage(res.message);

        if (props.type && type === 'draft') {
          props.getDraft();
        }
        if (type !== 'template') {
          props.onClose();
        }

        if (type === 'template') {
          if (props.subType === 'comparison') {
            props.getEstimateTemplates('comparison');
          } else {
            props.getEstimateTemplates('standard');
          }
        }

        
      }

      if (res.error && !res.user_issue) {
        // errorMessage(`${labels.QUANTITY_NOT_AVAILABLE[selected_lang]}`);
        if (res.not_available.length > 0) {
          setOutOfStockItems(res.not_available);
        }
        
        // setShowErrorFromApi(res.not_available)
      }

      if(res.error && res.user_issue){
        if (res.not_available.length > 0) {
          setOutOfStockItems(res.not_available);
        }
        errorMessage(`${labels.OUT_OF_STOCK_FOR_USERS[selected_lang]}`);
      }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleResetDraft = type => {
    if (type === 'comparison') {
      setComparisonDraft(originalcomparisonDraft);
      handleComparsionDraft(originalcomparisonDraft);
    } else {
      setStandardDraft(originalstandardDraft);
      handleStandardDraft(originalstandardDraft);
    }
  };

  const handleUpdateList = array => {
    // setComparisonList(array)
  };

  const discardEstimateDraft = type => {
    var payload = {
      inserted_data: [
        {
          invoice_type: 'estimate',
          sub_type: type,
          discard_draft: true,
        },
      ],
    };
    commonApi
      .send_invoice_estimate(payload)
      .then(res => {
        successMessage(res.message);

        if (type === 'comparison') {
          setComparisonName('');
          setComparisonList(InitialComparison);

          props.setComparsionUsers([]);
          props.setCompUsers([]);
          setComparisonDraft([])
          
        }
        if (type === 'standard') {
          setStandardList([]);
          setStandardName('');
          props.setStandardUsers([]);
          props.setStandUsers([]);
          setStandardDraft([])
        }

        setRedraw(!redraw);

        props.setShowComponent('')
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleReset =()=>{
    
    setOutOfStockItems([])
  }

  console.log(comparisonList, 'comparisonList');
  // console.log(standardList, 'standardList');

  // console.log(comparisonDraft, 'comparisonDraft');
  // console.log(comparisonName, 'comparisonName');

  console.log(standardList,'standardListstandardListstandardList')

  return (
    <>
      {type === '1' && (
        <div className="estimate_tab">
          <div className="roi-form-dropdown mb-3">
            <div className="select-wrapper">
              <Dropdown
                onSelect={(eventKey, event) => {
                  handleReset()
                  setType(eventKey);
                  handleResetDraft('comparison');
                }}
                drop="down">
                <Dropdown.Toggle variant="primary">
                  {type
                    ? dropdownMenu.find(item => item.id === type).name
                    : 'Select Role'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {dropdownMenu.map((item, index) => (
                    <Dropdown.Item
                      key={`st-${index}`}
                      eventKey={item.id.toString()}
                      active={item.id === type}>
                      {item.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="roi-form-input">
            <Form.Control
              type="text"
              value={comparisonName}
              pattern="^\S.*$"
              required
              placeholder="Title"
              onChange={e => {
                setComparisonName(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Title is required.
            </Form.Control.Feedback>
          </div>

          {comparisonList.length > 0 && (
            <>
              <div className="d-flex justify-content-between">
                <div>
                  {(Object.keys(comparisonDraft).length > 0 &&  !props.type) && (
                    <div
                      className="template-text fit_content  text-custom-13 as-link"
                      onClick={() => {
                        if (Object.keys(comparisonDraft).length > 0) {
                          props.setShowComponent('discardComparisonDraft');
                        }
                      }}>
                      Discard Draft
                    </div>
                  )}
                </div>

                <div
                  className="template-text pb-2 as-link fit_content"
                  onClick={() => {
                    props.setAddUser('comparisonUsers');
                  }}>
                  {props.comparsionUsers && props.comparsionUsers.length > 0
                    ? props.comparsionUsers.length > 1
                      ? `${props.comparsionUsers.length} Contacts Selected`
                      : '1 Contact Selected'
                    : 'Select Contact'}
                </div>
              </div>
            </>
          )}

          {Object.entries(comparisonList).length > 0 ? (
            comparisonList.map((item, index) => (
              <ComparisonItem
                key={`comaparison-${index}`}
                item={item}
                redraw={redraw}
                ItemIndex={index}
                comparisonList={comparisonList}
                setComparisonList={setComparisonList}
                comparisonDraft={comparisonDraft}
                selectedItems={
                  comparisonDraft.length > 0 &&
                  comparisonDraft[index]?.catalog_details &&
                  comparisonDraft[index].catalog_details.length > 0
                    ? comparisonDraft[index].catalog_details
                    : []
                }
                handleUpdateList={handleUpdateList}
                setItemToUpdate={props.setComparisonItem}
                setShowComponent={props.setShowComponent}
                handleUpdateItem={props.handleUpdateItem}
                deleteCatalogItem={props.deleteCatalogItem}
                setDeleteCatalogItem={props.setDeleteCatalogItem}
                checkPermesionOfCreate={props.checkPermesionOfCreate}
                outOfStockItems={outOfStockItems}
                setOutOfStockItems={setOutOfStockItems}
                builders={props.comparsionUsers}
              />
            ))
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{height: '20vh'}}>
              <div>
                <Button
                  className="new-btn btn-green "
                  onClick={() => {
                    props.setShowComponent('comparisonModal');
                  }}>
                  Add Comparison
                </Button>
              </div>
            </div>
          )}

          {comparisonList.length > 0 && (
            <>
              <button
                style={{
                  color: '#68b245',
                }}
                className="as-link d-block mb-3 "
                onClick={() => {
                  props.setShowComponent('comparisonModal');
                  // addComparison(comparisonTitle);
                }}>
                Add Comparison
              </button>
            </>
          )}

          {comparisonList.length > 0 && !moreOptions && (
            <div
              className="more-option template-text as-link mb-3 fit_content"
              onClick={() => {
                setMoreOptions(!moreOptions);
              }}>
              More Options
            </div>
          )}

          {comparisonList.length > 0 && moreOptions && (
            <div
              className="more-option template-text as-link mb-3 fit_content"
              onClick={() => {
                setMoreOptions(!moreOptions);
              }}>
              Less Options
            </div>
          )}

          {moreOptions && comparisonList.length > 0 && (
            <>
              <div className="template-flex payment-schedule">
                <span>Delivery Date And Time</span>

                <div onClick={handleClickD}>
                  {invoiceDate === '' ? (
                    <span className="template-text">
                      {' '}
                      <i className="fa-solid fa-calendar-days" />
                    </span>
                  ) : (
                    <span className="template-text">
                      {Moment(invoiceDate)
                        .format('MMM D, YYYY h:mm A')
                        .toString()}
                    </span>
                  )}
                </div>

                <div className="invoice_calender-wrapper">
                  {isOpen && (
                    <DatePicker
                      selected={invoiceDate}
                      onChange={handleChange}
                      onClickOutside={() => {
                        handleOnClickOutside();
                      }}
                      showTimeSelect
                      timeIntervals={1}
                      minDate={new Date()}
                      maxDate={dueDate}
                      inline
                    />
                  )}
                </div>
              </div>

              <div className="template-flex payment-schedule">
                <span>Expiration Date And Time</span>

                <div onClick={handleClickE}>
                  {dueDate === '' ? (
                    <span className="template-text">
                      {' '}
                      <i className="fa-solid fa-calendar-days" />
                    </span>
                  ) : (
                    <span className="template-text">
                      {Moment(dueDate).format('MMM D, YYYY h:mm A').toString()}
                    </span>
                  )}
                </div>

                <div className="invoice_calender-wrapper">
                  {endIsOpen && (
                    <DatePicker
                      selected={dueDate}
                      onChange={handleChange}
                      showTimeSelect
                      timeIntervals={1}
                      onClickOutside={() => {
                        handleOnClickOutside();
                      }}
                      minDate={new Date()}
                      inline
                    />
                  )}
                </div>
              </div>
            </>
          )}

          {comparisonList.length > 0 && (
            <div className="template-btns">
              <div>
                <Button
                  className="new-btn btn-green "
                  onClick={() => {
                    handleComparisonSubmit('');
                  }}>
                  Send Estimates
                </Button>
              </div>

              <button
                style={{
                  color: '#68b245',
                }}
                className="as-link "
                onClick={() => {
                  handleComparisonSubmit('template');
                }}>
                Save As Template
              </button>

              <button
                style={{
                  color: '#68b245',
                }}
                className="as-link "
                onClick={() => {
                  handleComparisonSubmit('draft');
                }}>
                Save as Draft
              </button>
            </div>
          )}
        </div>
      )}

      {type === '2' && (
        <div className="estimate">
          <div className="roi-form-dropdown mb-3">
            <div className="select-wrapper">
              <Dropdown
                onSelect={(eventKey, event) => {
                  setType(eventKey);
                  handleResetDraft('standard');
                }}
                drop="down">
                <Dropdown.Toggle variant="primary">
                  {type
                    ? dropdownMenu.find(item => item.id === type).name
                    : 'Select Role'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {dropdownMenu.map((item, index) => (
                    <Dropdown.Item
                      key={`st-${index}`}
                      eventKey={item.id.toString()}
                      active={item.id === type}>
                      {item.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="roi-form-input">
            <Form.Control
              type="text"
              value={standardName}
              pattern="^\S.*$"
              required
              placeholder="Title"
              onChange={e => {
                setStandardName(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Title is required.
            </Form.Control.Feedback>
          </div>

          {standardList.length > 0 && (
            <>
              <div className="d-flex justify-content-between">
                <div>
                  {(Object.keys(standardDraft).length > 0  &&  !props.type )&& (
                    <div
                      className="template-text fit_content  text-custom-13 as-link"
                      onClick={() => {
                        if (Object.keys(standardDraft).length > 0) {
                          props.setShowComponent('discardStandardDraft');
                        }
                      }}>
                      Discard Draft
                    </div>
                  )}
                </div>
                <div
                  className="template-text pb-2 as-link fit_content  text-custom-13"
                  onClick={() => {
                    props.setAddUser('standardUsers');
                  }}>
                  {props.standardUsers && props.standardUsers.length > 0
                    ? props.standardUsers.length > 1
                      ? `${props.standardUsers.length} Contacts Selected`
                      : '1 Contact Selected'
                    : 'Select Contact'}
                </div>
              </div>
            </>
          )}

          {Object.entries(standardList).length > 0 ? (
            standardList.map((item, index) => (
              <ComparisonItem
                type={'standard'}
                item={item}
                redraw={redraw}
                ItemIndex={index}
                comparisonList={standardList}
                setComparisonList={setStandardList}
                comparisonDraft={standardDraft}
                selectedItems={
                  standardDraft.length > 0 &&
                  standardDraft[index]?.catalog_details &&
                  standardDraft[index].catalog_details.length > 0
                    ? standardDraft[index].catalog_details
                    : []
                }
                handleUpdateItem={props.handleUpdateItem}
                setShowComponent={props.setShowComponent}
                deleteCatalogItem={props.deleteCatalogItem}
                setDeleteCatalogItem={props.setDeleteCatalogItem}
                outOfStockItems={outOfStockItems}
                setOutOfStockItems={setOutOfStockItems}
                builders={props.standardUsers}
              />
            ))
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{height: '20vh'}}>
              <div>
                <Button
                  className="new-btn btn-green "
                  onClick={() => {
                    props.setShowComponent('standardModal');
                  }}>
                  Add Add-on Option
                </Button>
              </div>
            </div>
          )}

          {standardList.length > 0 && (
            <>
              <button
                style={{
                  color: '#68b245',
                }}
                className="as-link d-block mb-3 "
                onClick={() => {
                  props.setShowComponent('standardModal');
                }}>
                Add Add-on Option
              </button>

              <div
                className="more-option template-text as-link fit_content mb-3"
                onClick={() => {
                  setMoreOptions(!moreOptions);
                }}>
                More Options
              </div>
            </>
          )}

          {moreOptions && standardList.length > 0 && (
            <>
              <div className="template-flex payment-schedule">
                <span>Delivery Date And Time</span>

                <div onClick={handleClickD}>
                  {invoiceDate === '' ? (
                    <span className="template-text">
                      {' '}
                      <i className="fa-solid fa-calendar-days" />
                    </span>
                  ) : (
                    <span className="template-text">
                      {Moment(invoiceDate)
                        .format('MMM D, YYYY h:mm A')
                        .toString()}
                    </span>
                  )}
                </div>

                <div className="invoice_calender-wrapper">
                  {isOpen && (
                    <DatePicker
                      selected={invoiceDate}
                      onChange={handleChange}
                      onClickOutside={() => {
                        handleOnClickOutside();
                      }}
                      showTimeSelect
                      timeIntervals={1}
                      minDate={new Date()}
                      maxDate={dueDate}
                      inline
                    />
                  )}
                </div>
              </div>

              <div className="template-flex payment-schedule">
                <span>Expiration Date And Time</span>

                <div onClick={handleClickE}>
                  {dueDate === '' ? (
                    <span className="template-text">
                      {' '}
                      <i className="fa-solid fa-calendar-days" />
                    </span>
                  ) : (
                    <span className="template-text">
                      {Moment(dueDate).format('MMM D, YYYY h:mm A').toString()}
                    </span>
                  )}
                </div>

                <div className="invoice_calender-wrapper">
                  {endIsOpen && (
                    <DatePicker
                      selected={dueDate}
                      onChange={handleChange}
                      onClickOutside={() => {
                        handleOnClickOutside();
                      }}
                      showTimeSelect
                      timeIntervals={1}
                      minDate={new Date()}
                      inline
                    />
                  )}
                </div>
              </div>
            </>
          )}

          {standardList.length > 0 && (
            <div className="template-btns">
              <div>
                <Button
                  className="new-btn btn-green "
                  onClick={() => {
                    handleStandardSubmit('');
                  }}>
                  Send Estimates
                </Button>
              </div>

              <button
                style={{
                  color: '#68b245',
                }}
                className="as-link "
                onClick={() => {
                  handleStandardSubmit('template');
                }}>
                Save As Template
              </button>

              <button
                style={{
                  color: '#68b245',
                }}
                className="as-link "
                onClick={() => {
                  handleStandardSubmit('draft');
                }}>
                Save as Draft
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default InvoiceEstimate;
