import React, {useState} from 'react';
import {Form, Button} from 'react-bootstrap';
import {registerCompany} from '../../Constants/Images';
import {errorMessage} from '../../Utils/ToastMessages';
import {CustomModal} from '../../Components';
import CropImage from '../../Components/Cropper/CropImage';
import Scrollbars from 'react-custom-scrollbars';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';

const SliderComponent1 = ({
  type,
  hide,
  companyData,
  setCompanyData,
  setImageCreate,
  ticketImg,
  companyNameExist,
  companyLogo,
  setCompanyLogo,
  currentStep,
  setStep,
  handleFunctionReset,
}) => {
  const [showModel, setShowModel] = useState('');
  const [file, setFile] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [showLoader, setshowLoader] = useState(false);
  const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

  // const [ticketImg, setImageCreate] = useState('');

  // const [selectedSchedulerTypeItems, setSelectedSchedulerTypeItems] = useState([]);

  const companyImageUpload = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    // If no image selected, return
    // || fileExtension === "gif"
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      setFile(file);

      setShowModel('modal');

      e.target.value = null;
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null;
    }
  };

  const companyLogoUpload = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    // If no image selected, return
    // || fileExtension === "gif"
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      setLogoFile(file);

      setShowModel('logo');

      e.target.value = null;
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null;
    }
  };

  let ImgModalStyle = {
    width: window.innerWidth <= 650 ? '' : '60%',
  };

  const dotsArray = [1, 2, 3];

  console.log(currentStep, 'currentStepcurrentStep={handlePage}');
  return (
    <>
      <div className="slider-component create_company_form">
        {/* <div className="slider-component-img"> */}
        {/* {!hide && ( */}
        <div
          className="slider-component-img"
          style={{
            backgroundImage: `url(${registerCompany})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}>
          {/* <img src={registerCompany} alt="" /> */}

          <div className="slider-component-img-text">
            Welcome to the ROI Family!
          </div>
        </div>
        {/* )} */}

        <div className="slider-component-content refferal-list-wrapper">
          {type && type === 'create-account' && currentStep === 1 && (
            <div className="back-btn">
              <Button
                className="new-btn btn-green "
                onClick={() => {
                  setStep('name');
                  handleFunctionReset();
                }}>
                Back
              </Button>
            </div>
          )}

          {showLoader && <LoaderSpinner />}
          <Scrollbars>
            <div className="slider-content">
              <div className="slider-content-inputs">
                <h1 className="tell-us mb-4">Tell us about your company</h1>

                <div className="d-flex gap-3 mb-5">
                  {dotsArray.map((dot, index) => (
                    <span
                      key={index}
                      className={` company_dots ${
                        currentStep === 1 && index === 0 ? 'bg_green' : ''
                      }`}
                      onClick={() => {
                        if (currentStep === 3) {
                          if (index === 0) {
                            setStep(1);
                          } else if (index === 1) {
                            setStep(2);
                          }
                        }
                      }}></span>
                  ))}
                </div>

                {!hide && (
                  <>
                    <div className="input-group mb-4 ">
                      <Form.Label controlid="validationCustom01">
                        First Name
                      </Form.Label>

                      <Form.Control
                        type="text"
                        value={companyData.firstName}
                        pattern="^\S.*$"
                        required={hide ? false : true}
                        placeholder=""
                        onChange={e => {
                          setCompanyData({
                            ...companyData,
                            firstName: e.target.value,
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Name is required.
                      </Form.Control.Feedback>
                    </div>
                    <div className="input-group mb-4 ">
                      <Form.Label controlid="validationCustom01">
                        Last Name
                      </Form.Label>

                      <Form.Control
                        type="text"
                        value={companyData.lastName}
                        pattern="^\S.*$"
                        required={hide ? false : true}
                        placeholder=""
                        onChange={e => {
                          setCompanyData({
                            ...companyData,
                            lastName: e.target.value,
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Name is required.
                      </Form.Control.Feedback>
                    </div>
                  </>
                )}

                <div>
                  <Form.Label controlid="validationCustom01">
                    Company Logo
                  </Form.Label>

                  <div className="ticket-img-wrapper mb-3 m-0">
                    {/* <p onClick={()=>{setShowModel('modal')}}>Onclick</p> */}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={e => companyLogoUpload(e)}
                    />
                    {companyLogo && <img src={companyLogo} alt="" />}
                    <i className="icon-camera" />
                  </div>
                </div>

                <div className="input-group mb-4 ">
                  <Form.Label className="mb-2" controlid="validationCustom01">
                    Company Name
                  </Form.Label>

                  <Form.Control
                    type="text"
                    value={companyData.name}
                    pattern="^\S.*$"
                    required
                    placeholder=""
                    onChange={e => {
                      setCompanyData({...companyData, name: e.target.value});
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Company name is required.
                  </Form.Control.Feedback>
                  {companyNameExist === true && (
                    <div className="text-danger textforerror">
                      Company name already exist
                    </div>
                  )}
                </div>

                <div className="input-group mb-4">
                  <Form.Label className="mb-2" controlid="validationCustom01">
                    Billing POC Name
                  </Form.Label>

                  <Form.Control
                    type="text"
                    value={companyData.contact}
                    pattern="^\S.*$"
                    required
                    placeholder=""
                    onChange={e => {
                      setCompanyData({...companyData, contact: e.target.value});
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    POC is required.
                  </Form.Control.Feedback>
                </div>
                <div className="input-group mb-4">
                  <Form.Label className="mb-2" controlid="validationCustom01">
                    Billing Email
                  </Form.Label>

                  <Form.Control
                    type="email"
                    value={companyData.billing_email}
                    pattern=".+@.+\..+"
                    required
                    placeholder=""
                    onChange={e => {
                      setCompanyData({
                        ...companyData,
                        billing_email: e.target.value,
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {companyData.billing_email === ''
                      ? 'Email is required.'
                      : 'Invalid Email'}
                  </Form.Control.Feedback>
                </div>

                <div className="input-group mb-4">
                  <Form.Label className="mb-2" controlid="validationCustom01">
                    Email
                  </Form.Label>

                  <Form.Control
                    type="email"
                    value={companyData.email}
                    pattern=".+@.+\..+"
                    required
                    placeholder=""
                    onChange={e => {
                      setCompanyData({...companyData, email: e.target.value});
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {companyData.email === ''
                      ? 'Email is required.'
                      : 'Invalid Email'}
                  </Form.Control.Feedback>
                </div>

                <div className="input-group mb-4">
                  <Form.Label controlid="validationCustom01">
                    Number of Team Members
                  </Form.Label>

                  <Form.Control
                    type="number"
                    value={companyData.teamMembers}
                    pattern="^\S.*$"
                    required
                    placeholder=""
                    onChange={e => {
                      setCompanyData({
                        ...companyData,
                        teamMembers: e.target.value,
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Team is required.
                  </Form.Control.Feedback>
                </div>

                <div>
                  <Form.Label controlid="validationCustom01">
                    Company Image
                  </Form.Label>

                  <div className="ticket-img-wrapper mb-3 m-0">
                    {/* <p onClick={()=>{setShowModel('modal')}}>Onclick</p> */}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={e => companyImageUpload(e, 'img')}
                    />
                    {ticketImg && <img src={ticketImg} alt="" />}
                    <i className="icon-camera" />
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>

          {showModel === 'modal' && file && (
        <CustomModal
          size="large"
          onClose={() => {
            setShowModel('');
            setFile(null);
          }}
          // style={ImgModalStyle}
          >
          <CropImage
            type="company"
            ratio={100 / 23}
            file={file}
            setFile={setFile}
            setCropImg={setShowModel}
            imgUrl={ticketImg}
            setImgUrl={setImageCreate}
            setshowLoader={setshowLoader}
          />
        </CustomModal>
      )}
      {showModel === 'logo' && logoFile && (
        <CustomModal
          size="large"
          onClose={() => {
            setShowModel('');
            setFile(null);
          }}
          // style={ImgModalStyle}
          >
          <CropImage
            type="company"
            ratio={25 / 24}
            file={logoFile}
            setFile={setLogoFile}
            setCropImg={setShowModel}
            imgUrl={companyLogo}
            setImgUrl={setCompanyLogo}
            setshowLoader={setshowLoader}
          />
        </CustomModal>
      )}
        </div>
      </div>
      {/* <div className="company-info-screen">
        <div className="company-top-q">
          <h1 className="mb-2">Tell us about your company</h1>
         
        </div>

        <div className="input-container1">
          <div className="input-group ">
           

            <Form.Label controlid="validationCustom01">Company Name</Form.Label>

            <Form.Control
              type="text"
              value={companyName}
              pattern="^\S.*$"
              required
              placeholder=""
              onChange={e => {
                setCompanyName(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Name is required.
            </Form.Control.Feedback>

            
          </div>

          <div className="input-group">
            <Form.Label controlid="validationCustom01">Primary Point of Contact</Form.Label>

            <Form.Control
              type="text"
              value={contact}
              pattern="^\S.*$"
              required
              placeholder=""
              onChange={e => {
                setContact(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Contact is required.
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="input-container2">
          <div className="input-group">
            <Form.Label controlid="validationCustom01">Number of employees</Form.Label>

            <Form.Control
              type="text"
              value={employees}
              pattern="^\S.*$"
              required
              placeholder=""
              onChange={e => {
                setEmployees(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Employees is required.
            </Form.Control.Feedback>
            
          </div>

          <div className="input-group">
            <Form.Label controlid="validationCustom01"> Number of Affiliates or Partners</Form.Label>

            <Form.Control
              type="text"
              value={affliates}
              pattern="^\S.*$"
              required
              placeholder=""
              onChange={e => {
                setAffliates(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
            Affliates/Partners is required.
            </Form.Control.Feedback>
          </div>
        </div>

       
      </div> */}

      {/* <div className="company-info-screen">
        <div className="company-top-q">
          <h1 className="mb-2">Tell us about your company</h1>
          <div className="mb-5">
            Don’t worry, you can always save and continue
          </div>
        </div>

        <div className="input-container1">
          <div className="input-group ">
           

            <Form.Label controlid="validationCustom01">Company Name</Form.Label>

            <Form.Control
              type="text"
              value={companyName}
              pattern="^\S.*$"
              required
              placeholder=""
              onChange={e => {
                setCompanyName(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Name is required.
            </Form.Control.Feedback>

            
          </div>

          <div className="input-group">
            <Form.Label controlid="validationCustom01">Primary Point of Contact</Form.Label>

            <Form.Control
              type="text"
              value={contact}
              pattern="^\S.*$"
              required
              placeholder=""
              onChange={e => {
                setContact(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Contact is required.
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="input-container2">
          <div className="input-group">
            <Form.Label controlid="validationCustom01">Number of employees</Form.Label>

            <Form.Control
              type="text"
              value={employees}
              pattern="^\S.*$"
              required
              placeholder=""
              onChange={e => {
                setEmployees(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Employees is required.
            </Form.Control.Feedback>
            
          </div>

          <div className="input-group">
            <Form.Label controlid="validationCustom01"> Number of Affiliates or Partners</Form.Label>

            <Form.Control
              type="text"
              value={affliates}
              pattern="^\S.*$"
              required
              placeholder=""
              onChange={e => {
                setAffliates(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
            Affliates/Partners is required.
            </Form.Control.Feedback>
          </div>
        </div>

        <div>
          <div className="decribe-text">
            {' '}
            <label style={{margin: '0px 10px'}}>
              Which best describes your company type?
            </label>
          </div>

          <div className="input-container3">
            {Object.entries(Boxes).length > 0 &&
              Boxes.map((item, index) => (
                <div
                  className={`box ${
                    selectedServiceId.includes(item.id) ? 'selected-box' : ''
                  }`}
                  key={`b-item-${index}`}
                  onClick={() => {
                    addServices(item.id);
                  }}>
                  <div className="blank-box"></div>

                  <div className="box-content">
                    <h5>{item.type}</h5>
                    <p>{item.detail}</p>
                    <h5>Examples</h5>
                    <p>{item.examples}</p>
                  </div>
                </div>
              ))}

          
          </div>
        </div>
      </div> */}

     
    </>
  );
};

export default SliderComponent1;
