import React, {useState} from 'react';
import {Button,Form, } from 'react-bootstrap';
import CustomModal from '../CustomModal';
import {errorMessage,} from '../../Utils/ToastMessages';
import // addEventToCalendar,
//   getAccessTokenFromRefreshToken,
'../../Utils/commonUtils';
import commonApi from '../../Services/CommonService';
// import {async} from 'rxjs';



const CalanderModel = props => {
  const [modelId, setModelId] = useState([]);
  const [modelIdItem, setModelItem] = useState([]);




  const hanldeCalenderlist = item => {
    if (!modelId.includes(item.id)) {
      setModelId(prev => [...prev, item.id]);
      setModelItem(prev => [...prev, item]);
    } else {
      setModelId(previous => previous.filter(id => id !== item.id));
      setModelItem(previous =>
        previous.filter(miniItem => miniItem.id !== item.id),
      );
    }
    
  };
  
  const getRefreshToken = async () => {
    commonApi.get_refresh_token().then(response => {
      for (const item of modelIdItem) {
        const {email} = item;

        if (email) {
          // Find the matching token for the current calendar email
          const matchingToken = response.refresh_token.find(
            token => token.email === email,
          );

          if (matchingToken && matchingToken.refresh_token) {
          
            // const accessToken = getAccessTokenFromRefreshToken(
            //   matchingToken.refresh_token,
            //   item.google_calendar_id,
            // );

            // You can use the accessToken as needed
            
          } else {
            
          }
        }
      }
      // getAccessTokenFromRefreshToken(response.refresh_token[0].refresh_token);
    });
  };

  // 

  // const getRefreshToken = async () => {
  //   try {
  //     const response = await commonApi.get_refresh_token();

  //     // Create an array of promises to fetch access tokens
  //     const accessTokensPromises = modelIdItem.map(async (item) => {
  //       const { email } = item;
  //       if (email) {
  //         const matchingToken = response.refresh_token.find((token) => token.email === email);
  //         if (matchingToken && matchingToken.refresh_token) {
  //           const accessToken = await getAccessTokenFromRefreshToken(matchingToken.refresh_token, item.google_calendar_id);
  //           
  //         } else {
  //           
  //         }
  //       }
  //     });

  //     // Wait for all access token promises to resolve
  //     await Promise.all(accessTokensPromises);

  //     // After all access tokens are fetched, call add_google_calendars_ids_in_confer_appoint
  //     await add_google_calendars_ids_in_confer_appoint();
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  // Rest of your code remains the same



  // const addEventToCalendar = async (token, calendarId, event) => {
  //   // iteration++;

    
  //   const headers = {
  //     Authorization: `Bearer ${token}`,
  //     authorization: `Bearer ${token}`,
  //   };

  //   try {
  //     const config = {
  //       headers,
  //       data: event,
  //       method: 'POST',
  //       url: `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`,
  //     };
  //     const response = await axios(config);
    
  //     EventId.push({
  //       calendar_id: calendarId,
  //       calendar_event_id: response.data.id,
  //     });

    
  //     if (EventId.length === modelIdItem.length) {
  //       add_google_calendars_ids_in_confer_appoint();
  //     }
  //     // return response.data;
  //   } catch (error) {
  //     return error;
  //   }
  // };
  // const add_google_calendars_ids_in_confer_appoint = async () => {
  //   const payload = {
  //     type: props.type,
  //     type_id: props.typeId,
  //     items: EventId,
  //   };
    
    
  //   commonApi
  //     .add_google_calendars_ids_in_confer_appoint(payload)
  //     .then(response => {
  //       props.setShowModel('');
  //       successMessage('Add Successfully');
  //       props.onClose();
  //     });
  // };

  const handle = () => {
    getRefreshToken();
  };
  return (
    <>
      <CustomModal
        style={{height: '100%'}}
        onClose={() => {
          props.setShowModel('');
          props.onClose();
        }}>
        {/* {showLoader && <LoaderModal show={showLoader} />} */}

        <div className="serach-filter-wrapper">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="headings m-0">
              <h2 className="m-0">Select Calenders</h2>
            </div>
          </div>

          <div className="filter-wrapper">
            <div className="filter-category-list">
              <ul>
                {Object.entries(props.calendarData).length > 0 &&
                  props.calendarData.map((item, index) => (
                    <li
                      key={`sessionC-${index}`}
                      //    className={
                      //      props.isCheck.includes(item.id) ? 'active' : ''
                      //    }
                    >
                      <Form.Label>
                        {item.summary}
                        <input
                          type="checkbox"
                          id={item.id}
                          value={item.id}
                          onChange={() => {
                            hanldeCalenderlist(item);
                          }}
                          checked={modelId.includes(item.id)}
                        />
                        <span />
                      </Form.Label>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          {/* </Scrollbars> */}
          <div className="filter-btn-wrapper pt-3">
            <Button
              className="btn-dark"
              type="submit"
              onClick={() => {
                
                  if (modelIdItem.length > 0) {
                    handle();
                  } else {
                    errorMessage('One workspace should be selected.');
                  }
                
              }}>
              Select
            </Button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};
export default CalanderModel;
