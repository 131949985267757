import React from 'react';
import {
  Col,
  Form,
  Row,
  Dropdown,
} from 'react-bootstrap';

import { useState} from 'react';
import {listIconGreen} from '../../Constants/Images';

import Scrollbars from 'react-custom-scrollbars';

import {
  FireIcon,
  MachineIcon,
  WhitePenIcon,
  timeIcon,
  davisLogo,
  Logo,
} from '../../Constants/Images';


const CustomersList = props => {

  const [type, setType] = useState('1');
  
  const dropdownMenu = [
    {id: '1', name: 'General'},
    {
      id: '2',
      name: 'Scheduler',
    },
    {
      id: '3',
      name: 'Menus',
    },
    {
      id: '4',
      name: 'Roles',
    },
    {
      id: '5',
      name: 'Catalog',
    },
    {
      id: '6',
      name: 'Triggers',
    },
    {
      id: '7',
      name: 'Analystics',
    },
    {
      id: '8',
      name: 'LeaderBoard',
    },
    {
      id: '9',
      name: 'Goals',
    },
  ];


  return (
    <>
      {/* <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          showComponent={showComponent}
        /> */}
      {/* {showComponent === 'Event' && <SessionEvents eventButton={eventButton} />} */}
     
        {/* <div className="Reward_main">
          <div className="reward-top ">
            <span
              className="slider_heading mr-2"
              style={{color: props.analycticsSlider ? '' : '#999999'}}
              onClick={() => {
                props.setAnalycticsSlider(!props.analycticsSlider);
              }}>
              Analytics Row
            </span>
            {props.analycticsSlider && (
              <Slider {...rewardSlider} className="mt-2">
                {rewardSliderData.length > 0 &&
                  rewardSliderData.map((item, index) => (
                    <div className="reward-main">
                      <div className="reward-card">
                        <span style={{fontWeight: 700, fontSize: '13px'}}>
                          {item.name}
                        </span>
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <span style={{fontSize: '17px'}}>{item.money}</span>
                          {item.name === 'Ratings' && (
                            <img src={item.svgIcon} alt="" />
                          )}
                          {item.name === 'Conversion' ? (
                            <i
                              className="fa-solid fa-arrow-down"
                              style={{color: '#BD4141'}}></i>
                          ) : (
                            <i
                              class="fa-solid fa-arrow-up"
                              style={{color: '#52bd41'}}></i>
                          )}
                        </span>
                        <div class="message-date-info">
                          <span style={{color: '#333'}} class="message-date">
                            {item.time}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            )}
          </div>
        </div> */}
         <div style={{display: 'flex', flex: 1}}>
         <div
          className="cutsomers-page-listing  primary_panel_content"
          style={{padding: '25px 20px 15px', background: 'white'}}>
          <div className="company_logo">
            <img src={Logo} alt="" />
          </div>

          {/* <div className="mt-3">
                      <Form.Check
                        // disabled={props.id ? true : false}
                        className=""
                        type="switch"
                        id="custom-switch"
                        label={props.showFullScreen ? 'List View' : 'Full View'}
                        value={props.showFullScreen ? true : false}
                        checked={props.showFullScreen ? true : false}
                        onClick={() => {
                          props.setShowFullScreen(!props.showFullScreen);
                        }}
                      />
                    </div> */}

          <div className="mt-4" style={{display: 'flex'}}>
            <div className="customer_list_dropdown">
              <div className="input-group">
                <Form.Control
                  type="text"
                  value={''}
                  pattern="^\S.*$"
                  required
                  placeholder="Search"
                  onChange={e => {}}
                />
              </div>

              <div className="roi-template-dropdown">
                <div className="select-wrapper">
                  <Dropdown
                    onSelect={(eventKey, event) => {
                      setType(eventKey);
                    }}
                    drop="down">
                    <Dropdown.Toggle variant="primary">
                      {type
                        ? dropdownMenu.find(item => item.id === type).name
                        : 'Select Role'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {dropdownMenu.map((item, index) => (
                        <Dropdown.Item
                          key={`st-${index}`}
                          eventKey={item.id.toString()}
                          active={item.id === type}>
                          {item.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>

            <div className="search-tabs-icons">
              {props.showFullScreen && (
                <div
                  className="mt-1"
                  onClick={() => {
                    props.setShowFullScreen(!props.showFullScreen);
                  }}>
                  <img src={listIconGreen} alt=""   className='as-link'/>
                </div>
              )}
              <span
                className={
                  // secondComponent === 'group-component'
                  //   ? 'icon active'
                  //   :
                  'icon'
                }>
                <i
                  className="icon-plus"
                  // onClick={() =>
                  //   setSecondComponent('group-component')
                  // }
                />
              </span>

              <span
                //   onClick={() => {
                //     setThreeDots(!threeDots);
                //   }}
                className={
                  // threeDots
                  //   ? 'icon active threeDots'
                  //   :
                  'icon threeDots'
                }>
                <i className="fas fa-ellipsis-vertical" />
              </span>
            </div>
          </div>

          <div
            className="mt-4 customers-list refferal-list-wrapper"
          >
            <Scrollbars

            //   style={{
            //     position: 'absolute',
            //     width: '-webkit-fill-available',
            //     height: '-webkit-fill-available',
            //   }}
            >
            
                <Row className="mb-3 mt-2">
                  <Col md={1} className="role-head">
                    {' '}
                    Name
                  </Col>

                  <Col md={2} className="role-head icons-row-2 ">
                    Primary{' '}
                  </Col>
                  <Col md={4} className=" role-head">
                    {' '}
                    Quick Look
                  </Col>
                  <Col md={3} className="role-head ">
                    {' '}
                    Projects
                  </Col>
                  <Col md={2} className="role-head icons-row-2">
                    {' '}
                    Actions
                  </Col>
                </Row>

                {Array.from({length: 15}, (_, index) => (
                  <Row key={`r-item-${index}`}>
                    <Col md={1} className="role-row user-content-center ">
                      {index === 0 ? (
                        <div
                          style={{
                            width: '36px',
                            height: '33px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <img src={davisLogo} alt="" />
                        </div>
                      ) : index === 1 ? (
                        <div className="customer-img">
                          <img
                            src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                            alt=""
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </Col>
                    <Col md={2} className="role-row icons-row-2 ">
                      {' '}
                    </Col>
                    <Col md={4} className="role-row icons-row">
                      {index === 0 ? (
                        <div className="appointment_icon">
                          <Row>
                            <div className="appointment_icon1">
                              <img
                                className="appointment_icon1_image"
                                src={WhitePenIcon}
                                alt=""
                              />
                            </div>

                            <div className="appointment_icon2">
                              {' '}
                              <img
                                className="appointment_icon1_image_time"
                                src={timeIcon}
                                alt=""
                              />
                              <span>5D</span>
                            </div>
                            <div className="appointment_icon1">
                              <img
                                className="appointment_icon1_image"
                                src={FireIcon}
                                alt=""
                              />
                            </div>
                            <div className="appointment_icon1">
                              <img
                                className="appointment_icon1_image"
                                src={MachineIcon}
                                alt=""
                              />
                            </div>
                          </Row>
                        </div>
                      ) : (
                        ''
                      )}
                    </Col>
                    <Col md={3} className="role-row ">
                      {' '}
                    </Col>
                    <Col md={2} className="role-row icons-row-2">
                      {' '}
                    </Col>
                  </Row>
                ))}
            
            </Scrollbars>
          </div>
        </div>
        </div>
        
     
    </>
  );
};

export default CustomersList;
