const Logo = require('../Assets/images/logo.png');
const BlackLogo = 'a';
const Favicon = 'a';
const WebLogo = require('../Assets/images/WebLogo.png');

const DEFAULT_IMAGE_COMPANYLOGO = 'https://build-dev.s3.us-east-1.amazonaws.com/logo_1.jpg'
const DEFAULT_IMAGE_COMPANYBANNER = 'https://build-dev.s3.us-east-1.amazonaws.com/banner_img.jpeg'

// Landing Page Images
const BannerImg = 'a';
const AboutBg = 'a';
const MobileImg1 = 'a';
const MobileImg2 = 'a';
const MobileImg3 = 'a';
const MobileImg3Mobile ='a';

const MobileImg5 = 'a';
const AppsGroup = 'a';




const frankImg = 'a';
const avtarImg = 'a';
const vinceProfile = 'a';
const messageImg ='a';
const galleryImg1 = 'a';
const galleryImg2 = 'a';
const galleryImg3 = 'a';
const galleryImg4 = 'a';
const galleryImg5 = 'a'
const galleryImg6 = 'a'
const MoniterImg = 'a'
const dummyImg = 'a'
const sessionUser1 = 'a';
const sessionUser2 ='a';
const sessionUser3 ='a';
const sessionUser4 ='a';
const sessionUser5 ='a';
const sessionUser6 ='a';
const sessionUser7 ='a';
const sessionUser8 ='a';
const sessionUser9 ='a';
const sessionUser10 = 'a';
const sessionUser11 = 'a';
const sessionUser12 = 'a';
const sessionUser13 = 'a';
const sessionUser14 = 'a';
const sessionUser15 = 'a';
const sessionUser16 = 'a';

const eventImg = 'a';
const eventUser1 = 'a';
const eventUser2 = 's';
const eventUser3 = 'a';
const eventUser4 = 'a';
const eventUser5 = 'a';
const eventUser6 = 'a';
const eventUser7 = 'a';
const eventUser8 = 'a';
const coffeecup = 'a';

const howWorks1 = 'a';
const howWorks2 = 'a';
const howWorks3 = 'a';
const integrationLogo1 = require('../Assets/images/logo-1.png');
const integrationLogo2 = require('../Assets/images/logo-2.png');
const integrationLogo3 = require('../Assets/images/logo-3.png');
const integrationLogo4 = require('../Assets/images/logo-4.png');
const integrationLogo5 = require('../Assets/images/logo-5.png');
const integrationLogo6 = require('../Assets/images/logo-6.png');
const integrationLogo7 = require('../Assets/images/logo-7.png');
const Roi = require('../Assets/images/roi.png');
const defaultBuilder = require('../Assets/images/default-builder-img.png');
const screenshot1 = 's';
const screenshot2 = 'd';

const MessagesPng = require('../Assets/images/norecord/messages.png');
const competition_icon = require('../Assets/images/competitor_competition_icon.webp');
const Opportunity =
  require('../Assets/images/norecord/opportunity.png').default;
const QA = require('../Assets/images/norecord/Qa.png').default;
const Dollar = require('../Assets/images/dollar.jpeg');
const FromMe = require('../Assets/images/norecord/session.png').default;
const Refer = require('../Assets/images/norecord/refer.png').default;
const Session = require('../Assets/images/norecord/session.png').default;
const consults = require('../Assets/images/norecord/consults.png').default;
const Event = require('../Assets/images/norecord/event.png').default;
const Snapshot = require('../Assets/images/norecord/snapshot.png').default;


// ROI Images
const DoubleArrow = require('../Assets/images/double-arrow.png');
const VerticalThreeDots = require('../Assets/images/vertical-three-dots.png');
const registerCompany = require('../Assets/images/register-company.jpeg');
const profileImg = require('../Assets/images/profile_img.jpeg');
const profileLogo = require('../Assets/images/profile_logo.png');
const registerStarter = require('../Assets/images/register-company-starter.png');
const UrgentPlumber = require('../Assets/images/urgent_plumber.png');
const cruise = require('../Assets/images/cruise.png');
const driveThru = require('../Assets/images/drivethru.png');
const hurricane = require('../Assets/images/hurricane.png');
const drive = require('../Assets/images/drive.png')
const cookies = require('../Assets/images/cookies.png')
const strideretail = require('../Assets/images/strideretail.png')
const shoppingRetail = require('../Assets/images/shopping.png')
const RetailImg1 = require('../Assets/images/retail_img_1.jpeg')
const RetailImg2 = 'a'
const RetailImg3 = require('../Assets/images/retail-img-3.jpeg')
const JoeSanchez = require('../Assets/images/joe_sanchez.jpeg')

const UserProfileImg = require('../Assets/images/userProfile.png')
const RandomImg = require('../Assets/images/user_profile_img.jpeg')
const DavisFordProfileImg = require('../Assets/images/davisford_company_img.png')
const VitalImg = require('../Assets/images/vitals.png')
const SchedulPro = require('../Assets/images/schedulePro.png')
const CRMBlockImg = require('../Assets/images/crm.png')

const Cultivate = require('../Assets/images/Cultivate.jpg')
const FillVacancies = require('../Assets/images/FillVacancies.jpg')
const Accelerate = require('../Assets/images/Accelerate.jpg')
const VitalImage = require('../Assets/images/VitalImage.jpg')
const StayAvailable = require('../Assets/images/StayAvailable.jpg')


const NoAppointments =
  require('../Assets/images/norecord/no-appointments.png').default;
const NoCircles = require('../Assets/images/norecord/no-circles.png').default;
const NoSnapshots =
  require('../Assets/images/norecord/no-snapshot.png').default;
const SessionGroupLight =
  require('../Assets/images/session-img/sesison-group-light.svg').default;
const SessionVideoLight =
  require('../Assets/images/session-img/session-video-light.svg').default;
const ScreenShareDisable =
  require('../Assets/images/session-img/screen-share-new.svg').default;
const ScreenShareActive =
  require('../Assets/images/session-img/screenshare-yellow.svg').default;
const VideoMuteLine =
  require('../Assets/images/session-img/video-muteLine.svg').default;
const VideoCameraActive =
  require('../Assets/images/session-img/video-camera.svg').default;
const MakeVipGrey =
  require('../Assets/images/profile-icons/make-vip-grey.svg').default;
const MakeVipYellow =
  require('../Assets/images/profile-icons/make-vip-yellow.svg').default;
const InfoDotsActive =
  require('../Assets/images/profile-icons/infoDots-yellow.svg').default;
const InfoDotsDisable =
  require('../Assets/images/profile-icons/infoDots-grey.svg').default;
const StageMicDisable =
  require('../Assets/images/profile-icons/stage-mic-grey.svg').default;
const StageMicActive =
  require('../Assets/images/profile-icons/stage-mic-yellow.svg').default;
const MakeModeratorDisable =
  require('../Assets/images/profile-icons/make-moderator-grey.svg').default;
const MakeModeratorActive =
  require('../Assets/images/profile-icons/make-moderator-yellow.svg').default;
const MakeCreatorDisable =
  require('../Assets/images/profile-icons/make-creator-grey.svg').default;
const MakeCreatorActive =
  require('../Assets/images/profile-icons/make-creator-yellow.svg').default;
const BanDisable =
  require('../Assets/images/profile-icons/ban-grey.svg').default;
const BanActive =
  require('../Assets/images/profile-icons/ban-yellow.svg').default;
const StarDisable =
  require('../Assets/images/profile-icons/star-grey.svg').default;
const StarActive =
  require('../Assets/images/profile-icons/star-yellow.svg').default;
const RemoveBadgeActive =
  require('../Assets/images/profile-icons/remove-badge-yellow.svg').default;
const SheildDisable =
  require('../Assets/images/profile-icons/grey-shield.svg').default;
const UserGroupMini =
  require('../Assets/images/svg-icons/user-group-mini.svg').default;
const NoNotifications =
  require('../Assets/images/norecord/no-notifications.png').default;
const AddContact =
  require('../Assets/images/svg-icons/add-contact.svg').default;
const NoActions = require('../Assets/images/norecord/no-actions.png').default;
const VideoGreyIcon =
  require('../Assets/images/svg-icons/video-icon-grey.svg').default;
const CheckmarkFillGrey =
  require('../Assets/images/svg-icons/checkmark-filled-grey.svg').default;
const CheckmarkFillYellow =
  require('../Assets/images/svg-icons/checkmark-filled-yellow.svg').default;
const ShareablesGreyIcon =
  require('../Assets/images/svg-icons/shareables-grey.svg').default;
const ShareablesWhiteIcon =
  require('../Assets/images/svg-icons/shareables-white.svg').default;
const ShareablesYellowIcon =
  require('../Assets/images/svg-icons/shareables-yellow.svg').default;
  const ShareablesBlackIcon =
  require('../Assets/images/svg-icons/shareables-black.svg').default;
const ProfileNotes =
  require('../Assets/images/svg-icons/profile-notes.svg').default;
  const ProfileNotesIcon =
  require('../Assets/images/svg-icons/profile-notes.svg').default;
const NoMessages = require('../Assets/images/norecord/no-messages.png').default;
const SettingsGreyIcon =
  require('../Assets/images/svg-icons/settings-grey.svg').default;
const ScanYellowIcon =
  require('../Assets/images/svg-icons/scan-yellow.svg').default;
const WhitePenIcon =
  require('../Assets/images/svg-icons/pen.svg').default;
const changeRollsYellow =
  require('../Assets/images/svg-icons/changeRolls-arrows.-yellow.svg').default;
const ShareYellowIcon =
  require('../Assets/images/svg-icons/share-yellow.svg').default;
const NoForm = require('../Assets/images/svg-icons/form.svg').default;
const NoNetwork = require('../Assets/images/svg-icons/network.png').default;
const NoLink = require('../Assets/images/svg-icons/link.png').default;
const NoGroupChat = require('../Assets/images/svg-icons/noGroup.png').default;
const PollGrey =
  require('../Assets/images/svg-icons/poll-lightGrey.svg').default;
const EditPencilBlack =
  require('../Assets/images/svg-icons/edit-pencil-black.svg').default;
const EditPencilYellow =
  require('../Assets/images/svg-icons/edit-pencil-yellow.svg').default;
const AddGroupGray =
  require('../Assets/images/svg-icons/add-group.svg').default;
const PrivateReplyBlack =
  require('../Assets/images/svg-icons/private-reply-black.svg').default;
const NoConvos =
  require('../Assets/images/norecord/no-convos.webp').default;
const AddShareableIcon =
  require('../Assets/images/svg-icons/add-shareable-black.svg').default;
const GPTIcon =
  require('../Assets/images/norecord/build-gpt.svg').default;
const NoGoogleCalendar =
  require('../Assets/images/svg-icons/no-googleCalendar.svg').default;
const NoEventBrite =
  require('../Assets/images/svg-icons/no-eventbrite.svg').default;
const FireIcon =
  require('../Assets/images/svg-icons/fire.svg').default;
const MachineIcon =
  require('../Assets/images/svg-icons/machine.svg').default;
const ArrowIcon =
  require('../Assets/images/svg-icons/arrow.svg').default;
  const timeIcon =
  require('../Assets/images/svg-icons/time.svg').default;
  const heartIcon =
  require('../Assets/images/svg-icons/heart.svg').default;
  const bagIcon =
  require('../Assets/images/svg-icons/plus-bag.svg').default;

  const checkmarkTab =
  require('../Assets/images/svg-icons/checkmark-tab.svg').default;

 const download =
  require('../Assets/images/svg-icons/download.svg').default;
  const addUser =
  require('../Assets/images/svg-icons/user-plus.svg').default;
  const mobileScreen =
  require('../Assets/images/svg-icons/screen-touch.svg').default;
  const Car =
  require('../Assets/images/svg-icons/car.svg').default;
  const PickUp =
  require('../Assets/images/svg-icons/PickUp.svg').default;
  const HomeServices =
  require('../Assets/images/svg-icons/HomeServices.svg').default;
  const Donation =
  require('../Assets/images/svg-icons/Donation.svg').default;
  const Q_R =
  require('../Assets/images/svg-icons/Q_R.svg').default;
  const Chat_Gpt =
  require('../Assets/images/svg-icons/Chat_Gpt.svg').default;
  const scan_Qr =
  require('../Assets/images/svg-icons/scan_Qr.svg').default;
  const Fleet =
  require('../Assets/images/svg-icons/fleet.svg').default;
  const puzzlePiece =
  require('../Assets/images/svg-icons/puzzle-piece.svg').default;
  const fileUpload =
  require('../Assets/images/svg-icons/file-upload.svg').default;
  const Templates =
  require('../Assets/images/svg-icons/mini-squares.svg').default;
  const gripLines =
  require('../Assets/images/svg-icons/grip3-lines.svg').default;
  const dispatch =
  require('../Assets/images/svg-icons/dispatch.svg').default;
  const headset =
  require('../Assets/images/svg-icons/head_phone.svg').default;
  const gift =
  require('../Assets/images/svg-icons/gift.svg').default;
  const funnel =
  require('../Assets/images/svg-icons/funnel.svg').default;
  const analytics =
  require('../Assets/images/svg-icons/analytics_icon.svg').default;
  const customerIconWithHeart =
  require('../Assets/images/svg-icons/customer_icon.svg').default;
  const checkMarkTask =
  require('../Assets/images/svg-icons/check-tasks.svg').default;
  const Announcements =
  require('../Assets/images/svg-icons/Announcements.svg').default;
  const logo_Web =
  require('../Assets/images/svg-icons/logo.svg').default;
  const upload_file =
  require('../Assets/images/svg-icons/upload_file.svg').default;
  const rewardStar =
  require('../Assets/images/svg-icons/reward_star.svg').default;
  const Play =
  require('../Assets/images/svg-icons/play.svg').default;
  const davisLogo =
  require('../Assets/images/svg-icons/davisford_logo.svg').default;
  const waveform =
  require('../Assets/images/svg-icons/waveform.svg').default;
  const gridRound =
  require('../Assets/images/svg-icons/grid-round.svg').default;
  const layers =
  require('../Assets/images/svg-icons/layers-icon.svg').default;
  const cupcake =
  require('../Assets/images/cupcake.png')
  const customerQr =
  require('../Assets/images/svg-icons/customer-qr.svg').default;
  const RoiLogo =
  require('../Assets/images/svg-icons/roi_logo.svg').default;
  const DropdownIcon =
  require('../Assets/images/svg-icons/ADropdown.svg').default;
  const dropdownGreen =
  require('../Assets/images/svg-icons/dropdownGreen.svg').default;

  const RoiArrowLogo =
  require('../Assets/images/svg-icons/roi_arrow_logo.svg').default;

  const dollar =
  require('../Assets/images/svg-icons/dollar.svg').default;
  const vPayment =
  require('../Assets/images/svg-icons/v.svg').default;
  const zPayment =
  require('../Assets/images/svg-icons/z.svg').default;
  const paypal =
  require('../Assets/images/svg-icons/paypal.svg').default;

  const profileIcon =
  require('../Assets/images/svg-icons/profile-icon.svg').default;
  const arrow_up =
  require('../Assets/images/svg-icons/arrow-up.svg').default;
  const taskCheckMark =
  require('../Assets/images/svg-icons/check-mark.svg').default;
  const Bandage =
  require('../Assets/images/svg-icons/Bandage.svg').default;
  const Googlealphabet =
  require('../Assets/images/svg-icons/G-google.svg').default;
  const BadgeCheck =
  require('../Assets/images/svg-icons/badge-check.svg').default;
  const cameraicon =
  require('../Assets/images/svg-icons/camera-icon.svg').default;
  const Google =
  require('../Assets/images/svg-icons/Google.svg').default;
  const DollarSign =
  require('../Assets/images/svg-icons/dollarSign.svg').default;
  const TickSign =
  require('../Assets/images/svg-icons/TickSign.svg').default;
  const RewardImg =
  require('../Assets/images/svg-icons/RewardImg.svg').default;
  const Cart =
  require('../Assets/images/svg-icons/Cart.svg').default;

  const companyCheck =
  require('../Assets/images/svg-icons/company-check.svg').default;
  const listIcon =
  require('../Assets/images/svg-icons/list_icon.svg').default;
  const listIconGreen =
  require('../Assets/images/svg-icons/greenList.svg').default;
  const AddManagement =
  require('../Assets/images/svg-icons/AddManagement.svg').default;
  const RewardIcon =
  require('../Assets/images/svg-icons/RewardIcon.svg').default;


  const groupLandingPage =
  require('../Assets/images/svg-icons/groupLandingPage.svg').default;
  const youtubeLandingPage =
  require('../Assets/images/svg-icons/youtubeLandingPage.svg').default;
  const shopifyIconLandingPage =
  require('../Assets/images/svg-icons/shopifyIconLandingPage.svg').default;


  const vitals_logo =
  require('../Assets/images/vitals-logo-icon.webp');
  const property_sync =
  require('../Assets/images/property-sync-icon.webp');
  const Contactless_icon =
  require('../Assets/images/Contactless-icon.webp');
  const Layer_1 =
  require('../Assets/images/Layer_1.webp');


  const live_convert =
  require('../Assets/images/live-convert-icon.svg').default;

  const LevelUpSvg =
  require('../Assets/images/svg-icons/LevelUpSvg.svg').default;
  const GrowthToolsSvg =
  require('../Assets/images/svg-icons/GrowthToolsSvg.svg').default;
  const WebsiteBundlesSvg =
  require('../Assets/images/svg-icons/WebsiteBundlesSvg.svg').default;
  const DoubleArrowSvg =
  require('../Assets/images/svg-icons/doublearrows.svg').default;
  const logo_roi =
  require('../Assets/images/svg-icons/final-logo-roi.svg').default;
  const select_icon =
  require('../Assets/images/svg-icons/select-all-icon.svg').default;

  
export {
  LevelUpSvg,
  GrowthToolsSvg,
  WebsiteBundlesSvg,

  shopifyIconLandingPage,
  youtubeLandingPage,
  groupLandingPage,

  NoEventBrite,
  NoGoogleCalendar,
  GPTIcon,
  AddShareableIcon,
  NoConvos,
  PrivateReplyBlack,
  EditPencilYellow,
  EditPencilBlack,
  Logo,
  Roi,
  BlackLogo,
  Favicon,
  BannerImg,
  AboutBg,
  MobileImg1,
  MobileImg2,
  MobileImg3,
  MobileImg3Mobile,
  MobileImg5,
  AppsGroup,
  frankImg,
  avtarImg,
  vinceProfile,
  messageImg,
  galleryImg1,
  galleryImg2,
  galleryImg3,
  galleryImg4,
  galleryImg5,
  galleryImg6,
  sessionUser1,
  sessionUser2,
  sessionUser3,
  sessionUser4,
  sessionUser5,
  sessionUser6,
  sessionUser7,
  sessionUser8,
  sessionUser9,
  sessionUser10,
  sessionUser11,
  sessionUser12,
  sessionUser13,
  sessionUser14,
  sessionUser15,
  sessionUser16,
  eventImg,
  eventUser1,
  eventUser2,
  eventUser3,
  eventUser4,
  eventUser5,
  eventUser6,
  eventUser7,
  eventUser8,
  coffeecup,
  dummyImg,
  Dollar,
  FromMe,
  howWorks1,
  howWorks2,
  howWorks3,
  integrationLogo1,
  integrationLogo2,
  integrationLogo3,
  integrationLogo4,
  integrationLogo5,
  integrationLogo6,
  integrationLogo7,
  defaultBuilder,
  Opportunity,
  QA,
  Refer,
  Session,
  consults,
  Event,
  Snapshot,
  MessagesPng,
  NoAppointments,
  NoCircles,
  NoSnapshots,
  SessionGroupLight,
  SessionVideoLight,
  ScreenShareDisable,
  ScreenShareActive,
  VideoMuteLine,
  VideoCameraActive,
  MakeVipGrey,
  MakeVipYellow,
  InfoDotsActive,
  InfoDotsDisable,
  StageMicDisable,
  StageMicActive,
  MakeModeratorDisable,
  MakeModeratorActive,
  MakeCreatorDisable,
  MakeCreatorActive,
  BanDisable,
  BanActive,
  StarDisable,
  StarActive,
  RemoveBadgeActive,
  SheildDisable,
  UserGroupMini,
  screenshot1,
  registerCompany,
  screenshot2,
  NoNotifications,
  NoActions,
  VideoGreyIcon,
  CheckmarkFillGrey,
  ShareablesGreyIcon,
  ShareablesWhiteIcon,
  ShareablesYellowIcon,
  AddContact,
  NoMessages,
  ProfileNotes,
  ProfileNotesIcon,
  SettingsGreyIcon,
  ScanYellowIcon,
  changeRollsYellow,
  ShareYellowIcon,
  Car,
  NoForm,
  NoNetwork,
  NoLink,
  NoGroupChat,
  PollGrey,
  AddGroupGray,
  CheckmarkFillYellow,
  WhitePenIcon,
  FireIcon,
  MachineIcon,
  ArrowIcon,
  timeIcon,
  heartIcon,
  bagIcon,
  checkmarkTab,
  ShareablesBlackIcon,
  download,
  addUser,
  mobileScreen,
  WebLogo,
  PickUp,
  HomeServices,
  Donation,
  Q_R,
  Chat_Gpt,
  scan_Qr,
  Fleet,
  puzzlePiece,
  fileUpload,
  Templates,
  gripLines,
  dispatch,
  headset,
  gift,
  funnel,
  analytics,
  customerIconWithHeart,
  checkMarkTask,
  Announcements,
  logo_Web,
  upload_file,
  rewardStar,
  Play,
  davisLogo,
  waveform,
  gridRound,
  layers,
  cupcake,
  customerQr,

  DropdownIcon,
  dropdownGreen,
  RoiLogo,
  RoiArrowLogo,
  profileImg,
  profileLogo,
  dollar,
  vPayment,
  zPayment,
  paypal,
  profileIcon,
  arrow_up,
  taskCheckMark,
  registerStarter,
  Bandage,
  Googlealphabet,
  drive,
  hurricane,
  driveThru,
  cruise,
  UrgentPlumber,
  cookies,
  strideretail,
  shoppingRetail,
  RetailImg1,
  RetailImg2,
  RetailImg3,
  BadgeCheck,
  UserProfileImg,
  cameraicon,
  Google,
  DollarSign,
  TickSign,
  RewardImg,
  RandomImg,
  DavisFordProfileImg,
  Cart,
  companyCheck,
  JoeSanchez,
  listIcon,
  listIconGreen,
  MoniterImg,
  AddManagement,
  RewardIcon,
  DEFAULT_IMAGE_COMPANYBANNER,
  DEFAULT_IMAGE_COMPANYLOGO,
  competition_icon,
  vitals_logo,
  live_convert,
  Contactless_icon,
  property_sync,
  Layer_1,
  CRMBlockImg,
  SchedulPro,
  VitalImg,
  StayAvailable,
  VitalImage,
  Accelerate,
  FillVacancies,
  Cultivate,
  DoubleArrow,
  VerticalThreeDots,
  DoubleArrowSvg,
  logo_roi,
  select_icon,
};
